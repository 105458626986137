import React, {Component, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card, Rate, Input,Collapse,  } from "antd"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home1.css'
import CountUp from "react-countup";

import phone from "../../Images/pick_site/phone_newcommande.png"
import phone_white from "../../Images/phone_pick_white.png"
import carreaux from "../../Images/carreaux.png"
import ellipse from "../../Images/Ellipse_number_pick.png"
import phone_card from "../../Images/phone_card.png"
import left_corner_violet from "../../Images/left_corner_violet_pick.png"
import left_corner_orange from "../../Images/left_corner_orange_pick.png"
import left_corner_rose from "../../Images/left_corner_rose_pick.png"
import camion from "../../Images/camion_pick.png"
import euro from "../../Images/euro_pick.png"
import position from "../../Images/geo_pick.png"
import tracabilite from "../../Images/map_pick.png"
import fiabilite from "../../Images/protect_pick.png"
import phone_left from "../../Images/phone_left_pick.png"
import phone_right from "../../Images/pick_site/phone_enlev.png"
import expand_haut from "../../Images/expand_icon_active_pick.png"
import expand_bas from "../../Images/expand_icon_active_pick.png"
import phone_commande from "../../Images/pick_site/new_commande.png"
import {Link, NavLink, useHistory} from "react-router-dom";
import axios from "axios";
import {IsLoggedIn,nextSaturday, openNotificationError} from "../../Helpers";
import Button from "antd/es/button";
import DownCircleFilled from "@ant-design/icons/lib/icons/DownCircleFilled";
import PlacesAutocomplete from "react-places-autocomplete";
import {BACKEND_API_URL} from "../../env";
import {Spinner} from "react-bootstrap";

let options = {
    componentRestrictions: {country: "fr"}
}
const { Panel } = Collapse;

const deadline2 = nextSaturday()
deadline2.setHours(21,0,0,)
const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },

        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },

    ]
};
const Home1 = () =>{
    let[adresseEnleve, SetadresseEnleve] = useState("");
    let[adresseLivraison, SetadresseLivraison] = useState("");
    let[adresseEnleveErr, SetadresseEnleveErr] = useState(false);
    let[adresseLivraisonErr, SetadresseLivraisonErr] = useState(false);
    let[LoadingSubmit, SetLoadingSubmit] = useState(false);
    let[montant, SetMontant] = useState(0);
    let[requestEstimation, SetRequestEstimation] = useState(false);
    let history = useHistory();


    useEffect(() =>{
    }, [])

    const handleChange_addresse_enlev = (adresse) => {
        SetadresseEnleve(adresse)
    };
    const handleChange_addresse_livraison = (adresse) => {
        SetadresseLivraison(adresse)
    };
    function redirect(){
        const isLoggedIn = IsLoggedIn()
        console.log(isLoggedIn)
        if(isLoggedIn){
            history.push("/nouvelle-expedition?enlev="+adresseEnleve+"&livr="+adresseLivraison)
        }
        else
            history.push("/choix-inscription-expediteur")
        // window.location.replace("/nouvelle-expedition?enlev="+adresseEnleve+"&livr="+adresseLivraison)
    }

    function validateForm(){
        let error = false
        if(adresseEnleve === ''){
            console.log('Veuillez remplir ce champ')
            SetadresseEnleveErr(true)
            error = true
            return error
        }else{
            SetadresseEnleveErr(false)
            error = false
        }

        if(adresseLivraison === ''){
            SetadresseLivraisonErr(true)
            error = true
            return error
        }
        else{
            SetadresseLivraisonErr(false)
            error = false
        }

        return error
    }
    async function onSubmit() {
        let valid = await validateForm()
        console.log("etat : ", valid)

        if (valid === false) {
            SetLoadingSubmit(true)
            await axios.post(`${BACKEND_API_URL}commandes/tarificationAndAvailabelDelivers`, {
                adresseDepart: adresseEnleve,
                adresseArrivee: adresseLivraison,
                natureColisId: "SES",
                nombreChariot: 0,
                isHorsGabarit: "0",
                nombreColis: 1,
                plagePoidsId: "2",
            }).then(async response => {
                console.log("res demande exp : ",response)
                SetLoadingSubmit(false)
                if (response.data.success) {
                    SetMontant(response.data.montant)
                    SetRequestEstimation(true)
                }
            }).catch(err=>{
                SetLoadingSubmit(false)
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
            })
        }
    }


        return (
                <Container fluid className="main-home">
                    <Row className={"facon-livraison"}>
                        <Col>
                            <h4>Une nouvelle façon de livrer !</h4>
                        </Col>
                    </Row>
                    <Row className="vertical-align">
                        <Col lg={7} className={"title-left"}>
                            <h3>
                                PICK&DELI
                            </h3>
                            <h3>
                                L’application qui
                            </h3>
                            <h3>
                                vous simplifie la
                            </h3>
                            <h3>
                                livraison
                            </h3>

                            <p>
                                Livraison immédiate et intuitive de toutes vos
                                marchandises
                            </p>
                            <div className={'form-adre'}>
                                <div>
                                <label>Lieu d’enlèvement</label>
                                <PlacesAutocomplete
                                    value={adresseEnleve}
                                    onChange={handleChange_addresse_enlev}
                                    onSelect={handleChange_addresse_enlev}
                                    searchOptions={options}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: "Ex : 123 Rue du Faubourg Saint-Honoré, 75008 Paris",
                                                    className: 'location-search-input',
                                                })}
                                                className="input-register ant-input"
                                                id="adresseEnleve"
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Chargement...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {adresseEnleveErr === true &&
                                <span className='error'>Veuillez renseigner une adresse d'enlèvement</span>}
                                <label style={{marginTop:"40px"}}>Lieu de livraison</label>
                                <PlacesAutocomplete
                                    value={adresseLivraison}
                                    onChange={handleChange_addresse_livraison}
                                    onSelect={handleChange_addresse_livraison}
                                    searchOptions={options}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: "Ex : 5 Rue de Dunkerque, 75010 Paris",
                                                    className: 'location-search-input',
                                                })}
                                                className="input-register ant-input"
                                                id="adresseLivraison"
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Chargement...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {adresseLivraisonErr === true &&
                                <span className='error'>Veuillez renseigner une adresse de livraison</span>}
                                <div style={{textAlign : "center", marginTop: "40px"}}>
                                    <Button ghost onClick={()=>onSubmit()} disabled={LoadingSubmit}
                                            className={"estimation-btn"}>{LoadingSubmit ?
                                        <div className="spinner">
                                            <Spinner animation="border" role="status" className={'spinner-btn'}
                                                     variant="primary"/>
                                        </div>:
                                        <>Obtenir une estimation</>}
                                        </Button>
                                    {requestEstimation &&
                                        <div className={"estimation-div"}>
                                            <div>
                                            <p className={'estimation'}>{Number(montant).toFixed(2)
                                                .toString().replace(".", ",")} € TTC
                                                <span className={"asterique"}> *</span>
                                            </p>
                                            </div>
                                            <div>
                                                <Button className={"btn-oui valider"} onClick=
                                                    {()=>{redirect()}}>
                                                    <span>Valider</span>
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                </div>

                            </div>
                            {requestEstimation &&
                                <div>
                                    <p className={"explic-estim"}>*estimation sur la base d'un colis de 10 kg maximum</p>
                                </div>
                            }
                        </Col>
                        <Col lg={5} className={"phone-right"}>
                            <img src={phone} className={"phone-home"}/>
                        </Col>
                    </Row>
                    <Row className={"propos"}>
                        <h2 className={"title-hom"}>À Propos</h2>
                    </Row>
                    <Row style={{justifyContent: "center",marginTop: "30px"}}>
                        <p className={"apropos-txt"}>PICK&DELI est une application mobile et un site web
                            permettant la mise en relation intuitive entre des expéditeurs (entreprises ou particuliers)
                            et des transporteurs professionnels pour la réalisation et l’exécution instantanée
                            d’une livraison de marchandises.
                        </p>
                    </Row>

                    <Row className={"solut"}>
                        <Col xs={12} lg={6}>
                            <img className={"phone-white"} src={phone_white}/>
                        </Col>
                        <Col>
                            <div className={"solution-livraison"}>
                            <img src={carreaux}/>
                            <h3>
                                Une solution de livraisons
                                instantanées <label style={{color:"#B38053"}}>géolocalisées !</label>
                            </h3>
                            <Collapse ghost={true} bordered={false} accordion>
                                <Panel header="Vous êtes un Expéditeur (particulier ou entreprise)" key="1"
                                       className={'first'}>
                                    <p className={"collapse-txt"}>
                                        Particulier, vous souhaitez profiter immédiatement de vos achats volumineux et
                                        vous faire livrer instantanément n’importe quel colis encombrant sans planifier
                                        de livraisons, alors inscrivez-vous sur PICK&DELI et simplifiez-vous
                                        la livraison !</p>
                                    <p className={"collapse-txt"}>
                                        Entreprise, vous souhaitez améliorer votre qualité de service et faire livrer
                                        immédiatement et en urgence tous vos clients tout en réduisant votre budget
                                        transport, alors inscrivez-vous sur PICK&DELI et faites-en la solution de
                                        livraison immédiate de toutes vos marchandises !
                                    </p>
                                </Panel>
                                <Panel header="Vous êtes un Transporteur" key="2">
                                    <p className={"collapse-txt"}>Transporteur, recevez des demandes de livraison claires et détaillées, soyez informé du gain à percevoir et gardez la liberté d’accepter tout ordre de mission.
                                        En rejoignant PICK&DELI devenez notre partenaire de confiance et boostez votre chiffre d’affaires !
                                    </p>
                                </Panel>
                            </Collapse>
                                <p className={"collapse-txt"}>L’application permet de mettre en relation intuitivement
                                    et instantanément Expéditeurs et Transporteurs afin de livrer immédiatement votre
                                    marchandise et permettre son suivi en temps réel !
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"numbers"}>
                        <Col lg={4}/>
                        <Col style={{alignSelf:"center"}}>
                            <img className={"ellipse-number"} src={ellipse}/>
                            <CountUp
                                end={150}
                                duration={7}
                                className={"count-number"}
                            >
                            </CountUp>
                            <p className={"txt-number"}>Transporteurs inscrits</p>
                        </Col>
                        <Col style={{alignSelf:"center"}}>
                            <img className={"ellipse-number"} src={ellipse}/>
                            <CountUp
                                end={300}
                                duration={7}
                                className={"count-number"}
                            >
                            </CountUp>
                            <p className={"txt-number"}>Expéditeurs inscrits</p>
                        </Col>
                        <Col lg={2}/>
                    </Row>
                    <Row style={{justifyContent: "center",marginTop: "80px"}}>
                        <h2 className={"title-hom"}>Fonctionnalités</h2>
                    </Row>
                    <Row style={{justifyContent: "center",marginTop: "30px"}}>
                        <p className={"apropos-txt"}>PICK&DELI propose plusieurs fonctionnalités permettant
                            une livraison simplifiée, fiable et intuitive
                        </p>
                    </Row>
                    <Row className={"card-row"}>
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={phone_card} className={"card-img"}/>
                            <p className={"card-title"}>Instantané</p>
                            <p className={"card-txt"}>Commandez instantanément votre
                                service de livraison</p>
                            </div>
                            <img className={"left-corner"} src={left_corner_violet}/>
                        </Card>
                        </Col>
                        {/* ******* Camion Card *************/}
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={camion} className={"card-img cam"}/>
                            <p className={"card-title"}>Adapté</p>
                            <p className={"card-txt"}>Choisissez le type de véhicule en
                                fonction de la caractéristique de votre marchandise</p>
                            </div>
                            <img className={"left-corner"} src={left_corner_rose}/>
                        </Card>
                        </Col>

                        {/* ******* Euro Card *************/}
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={euro} className={"card-img euro"}/>
                            <p className={"card-title"}>Transparent</p>
                            <p className={"card-txt"}>Profitez d’un tarif juste et sans surcoût
                            </p>
                            </div>
                            <img className={"left-corner"} src={left_corner_orange}/>
                        </Card>
                        </Col>
                    </Row>

                    {/* ********** Second row cards */}
                    <Row className={"card-row sec"}>
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={position} className={"card-img euro"}/>
                            <p className={"card-title"}>Disponibilité</p>
                            <p className={"card-txt"}>Bénéficiez immédiatement d’un
                                transporteur professionnel géolocalisé exclusivement dédié à
                                la livraison de votre marchandise</p>
                            </div>
                            <img className={"left-corner"} src={left_corner_violet}/>
                        </Card>
                        </Col>
                        {/* ******* Camion Card *************/}
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={tracabilite} className={"card-img cam"}/>
                            <p className={"card-title"}>Traçabilité</p>
                            <p className={"card-txt"}>Suivez en temps réel votre
                                marchandise et communiquez avec votre transporteur si besoin</p>
                            </div>
                            <img className={"left-corner"} src={left_corner_rose}/>
                        </Card>
                        </Col>

                        {/* ******* Euro Card *************/}
                        <Col className={"card-fonct"} lg={4}>
                        <Card bordered={false} style={{ width: 300 }}>
                            <div style={{textAlign:"center"}}>
                            <img src={fiabilite} className={"card-img euro"} style={{width:"60px"}}/>
                            <p className={"card-title"}>Fiabilité</p>
                            <p className={"card-txt"}>Garantissez la bonne exécution de votre livraison
                            </p>
                            </div>
                            <img className={"left-corner"} src={left_corner_orange}/>
                        </Card>
                        </Col>
                    </Row>
                    <Row className={"phones"}>
                        <Col lg={2}/>
                        <Col lg={3} className={"phoneRight"}>
                            <img style={{width:"475px",marginLeft:"25px"}}
                                 src={require('../../Images/pick_site/phone_auth.png')} />
                        </Col>
                        <Col lg={7} className={"phoneRight"}>
                            <div className="slider-rate">
                                <Slider {...settings}>
                                <div>
                                        <img style={{width:"470px"}}
                                             src={require('../../Images/pick_site/phone_newcommande.png')} />
                                    </div>
                                    <div>
                                        <img style={{width:"470px"}} src={phone_commande} />
                                    </div>
                                    <div>
                                        <img style={{width:"470px"}} src={phone_right} />
                                    </div>
                                    
                                </Slider>
                            </div>
                        </Col>
                    </Row>

                    <Row className={"solut"}>
                        <Col xs={12} lg={6}>
                            <img className={"phone_white_collapse"} src={phone_white}/>
                        </Col>
                        <Col >
                            <div className={"collapse-shadow"}>
                                <Collapse ghost={true} bordered={false} expandIconPosition={"right"}
                                          expandIcon={({ isActive }) => <DownCircleFilled rotate={isActive ? -180 : 0} />}
                                >
                                    <Panel header="Simplifiez vous la commande" key="1">
                                        <p className={"collapse-txt"}>
                                            Localisez le transporteur le plus proche en quelques clics pour effectuer
                                            votre livraison.</p>
                                    </Panel>
                                    <Panel header="Simplifiez vous le suivi" key="2">
                                        <p className={"collapse-txt"}>Suivez en temps réel votre transporteur dédié
                                            exclusivement à votre livraison.
                                        </p>
                                    </Panel>
                                    <Panel header="Simplifiez vous la livraison" key="3">
                                        <p className={"collapse-txt"}>Bénéficiez d’une solution simple, rapide et
                                            fiable pour faire livrer immédiatement tout type de marchandise à tout
                                            moment de la journée.
                                        </p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                    <div className={"inscription-home"}>
                    <Row style={{placeContent : "center"}}>
                        <div>
                        <h2 className={"title-hom"}>Formulaire d’inscription</h2>
                        <p>Vous-êtes :</p>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={3}/>
                        <Col className={"center"}>
                            <Link to={"/choix-inscription-expediteur"}>
                            <Button>EXPÉDITEUR</Button>
                            </Link>
                            <p className={"explication"}>Localisez instantanément des transporteurs professionnels pour la
                                réalisation et l’exécution immédiate de votre livraison de marchandise.
                            </p>
                        </Col>
                        <Col lg={1}/>
                        <Col className={"center sec"}>
                            <Link to={"/choix-inscription-transpo"}>
                            <Button>TRANSPORTEUR</Button>
                            </Link>
                            <p className={"explication"}>Inscrivez vous et acceptez des demandes
                                immédiates de livraisons de marchandises et boostez votre chiffre d’affaires.
                            </p>
                        </Col>
                        <Col lg={3}/>

                    </Row>
                    </div>
                    <Row className={"newsletter"}>
                        <Col style={{textAlign:"center"}}>
                            <h3>Newsletter</h3>
                            <p>Recevez nos actualités</p>
                            <div style={{display:"inline-grid"}}>
                            <Input placeholder="Votre e-mail :" />
                            <Button>Envoyer</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

        );
    }



export default Home1;
