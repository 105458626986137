import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {Input, Modal, notification, Select} from "antd";
import axios from "axios";
import enlevement from '../Images/pick_site/enlevement.png'
import livraison from '../Images/pick_site/destination.png'
import {BACKEND_API_URL, BACKEND_ROOT_URL, GOOGLE_MAP_API_KEY} from "../env";
import jwt from "jsonwebtoken";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import {FormatDate} from "../Helpers";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import Geocode from "react-geocode";
import Button from "antd/es/button";
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import ModalDetails from "../components/ModalDetails";
import ModalEtatCommande from "./ModalEtatCommande";
import Badge from "antd/es/badge";

const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);
const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
const { TextArea } = Input;
Geocode.setApiKey(GOOGLE_MAP_API_KEY);
// set response language. Defaults to english.
Geocode.setLanguage("fr");
const distance = require('google-distance-matrix');
distance.key(GOOGLE_MAP_API_KEY);
distance.language('fr');
distance.units('imperial');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("fr");
const style = {
    width: '100%',
    height: '100%'
}
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}
let interval=''
let interval1=''
let interval2=''
let interval3=''
let {google} =""
let directionsService =""
let directionsRenderer =""
class MapExpedition extends Component {
    constructor() {
        super()
        this.state = {
            CommandesSansLivreur: [],
            LoadingMap: true, personneId:'',
            userId:'',ListeCommandes: [],
            CommandeId : '',Commande:'',
            Livreurs :'', SearchDeliver : false,
            DepartMarkers : {},
            V_LivreurPosition :{},
            ArriveMarkers : {},
            TempsApproche :0,
            region: {
                lat: 31.776685,
                lng: 35.234491,
            },
            maprops:'',Demande : '',
            mapref:'',livrState:'',directionsRenderer:'',
            detailvisibility : false,
            EtatCommandevisibility : false,
            Note:0,newMessage:0,messagesData:[],
            vehicule_url:"", LoadingSubmit : false,
            modal:false
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            CommandeId:this.props.commandeId
        })
        console.log('detail commande : ',this.props.commandeId)
        this.FirstGetDetailsCommande()
        interval = setInterval(() => this.GetDetailsCommande(), 10000)
    }
    componentWillUnmount() {
        if(interval)
            clearInterval(interval)
        if(interval1)
            clearInterval(interval1)
        if(interval2)
            clearInterval(interval2)
        if(interval3)
            clearInterval(interval3)
    }

    async GetDetailsCommande() {
        axios.post(`${BACKEND_API_URL}expediteur/getCommandeInfo`, {
            utilisateurId: this.state.userId,
            commandeId : this.state.CommandeId
        }).then(res => {
            console.log('reeeeees ', res.data);

            if (res.data.demande[0].isActive === 1 || res.data.demande[0].recherche){
                if(res.data.demande[0].livreurs.length !== this.state.Livreurs.length)
                this.setState({
                    Livreurs : res.data.demande[0].livreurs
                })
                this.setState(previousState => {
                    if(previousState.SearchDeliver){
                        return null
                    }else {
                        return {SearchDeliver : true}
                    }
                })
                // this.setState({SearchDeliver : true})
            }else {
                this.setState(previousState => {
                    if(!previousState.SearchDeliver){
                        return null
                    }else {
                        return {SearchDeliver : false}
                    }
                })
                // this.setState({SearchDeliver : false})
                if (interval !== ''){
                    clearInterval(interval)
                    console.log('red to GetDetailsRunnigCommande')
                    this.setState({Livreurs :[] })
                    this.GetDetailsRunnigCommande()
                }
            }
        }).catch(err => console.log(err));
    }

    async GetDetailsRunnigCommande() {
        console.log('GetDetailsRunnigCommande ')
        axios.post(`${BACKEND_API_URL}expediteur/getCommandeRunning`, {
            utilisateurId: this.state.userId,
            commandeId : this.state.CommandeId
        }).then(async res => {
            console.log('runing : ', res.data.demande[0]);
            await this.setState({Demande :res.data.demande[0]})
            this.VehiculeImg()
            interval1 = setInterval(() => this.getPosition(), 2000)
            this.getLivreurNote()
            this.getLivraisonStatus()
            this.OrderMessages()
            interval2 = setInterval(() => this.getLivraisonStatus(), 5000)
            interval3 = setInterval(() => this.OrderMessages(), 7000)
        }).catch(err => console.log(err));
    }

    async OrderMessages(){
        await axios.post(`${BACKEND_API_URL}transporteur/getMessage`, {
            EmetteurPersonneId: this.state.personneId,
            RecepteurPersonneId : this.state.Demande.transporteur.personneId,
            commandeId : this.state.Demande.commandeId
        }).then(async res => {
            console.log('dddddddddddddd',res.data)
            if(res.data.length !== this.state.messagesData.length)
            {
                let newmsgs = 0
                res.data.map((msg, index) => {
                    console.log('messages ',msg)
                    if(msg.EmetteurPersonneId === this.state.Demande.transporteur.personneId && msg.Seen === false){
                        newmsgs++
                        console.log('tttttt')
                    }
                })
                this.setState({newMessage : newmsgs})
                console.log('noooot read',newmsgs)
                this.setState({messagesData : res.data})
            }
            else {console.log('saame')}
            console.log(res.data)
        }).catch(err => console.log(err));
    }
    async FirstGetDetailsCommande() {
        this.setState({LoadingMap : true})
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        this.setState({userId : decodedToken.userId, personneId : decodedToken.personneId})
        axios.post(`${BACKEND_API_URL}expediteur/getCommandeInfo`, {
            utilisateurId: this.state.userId,
            commandeId : this.state.CommandeId
        }).then(res => {
            console.log('1 reeeeees ', res.data);
            this.setState({
                Commande : res.data.demande[0].commande,
                Livreurs : res.data.demande[0].livreurs
            })
            if (res.data.demande[0].isActive === 1 || res.data.demande[0].recherche){
                this.setState({SearchDeliver : true})
            }else {
                this.setState({SearchDeliver : false})
                if (interval !== ''){
                    clearInterval(interval)
                    this.GetDetailsRunnigCommande()
                }
            }
            this.setState({LoadingMap : false})
            this.InitMap()
        }).catch(err => {console.log(err)
            this.setState({LoadingMap : true})
        });
    }

    async getLivreurNote(){
        await axios.post(`${BACKEND_API_URL}transporteur/getLivreurNotes`,
            {
                transporteurId: this.state.Demande.transporteur.personne.personneId
            }).then(async res => {
            console.log('zzzzzzzzzzzzzzzzzz',res.data)
            // this.setState({V_RecepteursDemande : res.data.dispos})
            // console.log(this.state.V_RecepteursDemande)
            let temp = 0
            if(res.data.Livraison.nombrePersonnes !== 0){
                temp = Number(res.data.Livraison.note / res.data.Livraison.nombrePersonnes).toFixed(0)
            }
            console.log('noooooote : ',temp)
            this.setState({Note : Number(temp)})
        }).catch(err => console.log(err));
    }
    InitMap = async () => {
        console.log("commande : ",this.state.Commande)
        if(this.state.Commande)
        {
            await Geocode.fromAddress(this.state.Commande.adresseDepart).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log("DepartMarkers ",lat, lng);
                    this.setState({
                        region: {
                            lat: lat,
                            lng: lng,
                        },DepartMarkers :{
                            lat: lat,
                            lng: lng,
                        }
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
            await Geocode.fromAddress(this.state.Commande.adresseArrivee).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log(lat, lng);
                    this.setState({
                        ArriveMarkers :{
                            lat: lat,
                            lng: lng,
                        }
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
            this.F_Direction()
        }
        else{
            console.log("outside")
        }

    }

    F_OnDisplayNote(note) {
        switch (note) {
            case 0:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-0_5.png")}/>
                );
            case 1:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-1_5.png")}/>
                );
            case 2:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-2_5.png")}/>
                );
            case 3:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-3_5.png")}/>
                );
            case 4:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-4_5.png")}/>
                );
            case 5:
                return (
                    <img className={"note-img"} src={require("../Images/pick_site/note-5_5.png")}/>
                );

        }
    }
    F_OnPlaceRecepteursMarkers() {
        if(this
            .state
            .Livreurs.length > 0){
            let markers = this
                .state
                .Livreurs
                .map((item, index) => {
                    console.log('coooooooooooooooords : ',item.latitude,item.longitude)
                    let coord = {
                        lat: item.latitude,
                        lng: item.longitude
                    }
                    if ( item.transporteur.vehicule.vehiculeType.id === "CAM") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/CAM.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }
                    else if ( item.transporteur.vehicule.vehiculeType.id === "VEL") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/VEL.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }    else if ( item.transporteur.vehicule.vehiculeType.id === "MOT") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/MOT.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "SCO") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/SCO.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "SCO") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/SCO.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "PLFR") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/PLFR.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "FONE") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/FONE.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "CAFR") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/CAFR.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "VELCAR") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/VELCAR.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "POLO") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/POLO.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "VOI") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/VOI.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }else if ( item.transporteur.vehicule.vehiculeType.id === "FOFR") {
                        return (
                            <Marker position={coord}
                                    icon={{
                                        url: require('../Images/pick_site/FOFR.png'),
                                        scaledSize: new this.props.google.maps.Size(40,40)
                                    }}
                            />
                        )
                    }

                });
            return markers
        }
    }


    VehiculeImg() {
        if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "CAM") {
            this.setState({
                vehicule_url : '../Images/pick_site/CAM.png'
            })
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VEL") {
            this.setState({
                vehicule_url : '../Images/pick_site/VEL.png'
            })
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "MOT") {
            this.setState({vehicule_url : '../Images/pick_site/MOT.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "SCO") {
            this.setState({vehicule_url : '../Images/pick_site/SCO.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "PLFR") {
            this.setState({vehicule_url : '../Images/pick_site/PLFR.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "FONE") {
            this.setState({vehicule_url : '../Images/pick_site/FONE.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "CAFR") {
            this.setState({vehicule_url : '../Images/pick_site/CAFR.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VELCAR") {
            this.setState({vehicule_url : '../Images/pick_site/VELCAR.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "POLO") {
            this.setState({vehicule_url : '../Images/pick_site/POLO.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VOI") {
            this.setState({vehicule_url : '../Images/pick_site/VOI.png'})
        }
        else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "FOFR") {
            this.setState({vehicule_url : '../Images/pick_site/FOFR.png'})
        }
    }

    async getPosition(){
        console.log("check for changes")
        await axios.post(`${BACKEND_API_URL}transporteur/getPosition`, {
            personneId: this.state.Demande.transporteur.personne.personneId
        }).then(async res => {
            console.log("position : ",res.data)
            if(this.state.V_LivreurPosition.lat !== Number(res.data.lat) ||
                this.state.V_LivreurPosition.lng !== Number(res.data.long) ||
                this.state.V_LivreurPosition === {}){
                console.log('changes')
                this.UpdateLivPosition({lat : Number(res.data.lat),lng:Number(res.data.long)})
                /*this.animate(Number(res.data.lat),Number(res.data.long))
                */
                this.getTripTimeEstimation()
                this.F_Direction()
            }
            else{
                console.log("no change")
            }
        }).catch(err => console.log(err));
    }

    UpdateLivPosition(coord){
        this.setState({ V_LivreurPosition: coord,
            region : coord} )
    }
    F_OnPlaceLivreurMarker() {
        if (this.state.V_LivreurPosition.lat && this.state.V_LivreurPosition.lng) {
            console.log("disaply liv")
            if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "CAM") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/CAM.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VEL") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/VEL.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "MOT") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/MOT.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "SCO") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/SCO.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "PLFR") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/PLFR.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "FONE") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/FONE.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "CAFR") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/CAFR.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VELCAR") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/VELCAR.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "POLO") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/POLO.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "VOI") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/VOI.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }
            else if ( this.state.Demande.transporteur.vehicule.vehiculeType.id === "FOFR") {
                return (
                    <Marker position={this.state.V_LivreurPosition}
                            icon={{
                                url: require('../Images/pick_site/FOFR.png'),
                                scaledSize: new this.props.google.maps.Size(40,40)
                            }}/>
                );
            }

        } else {
            console.log("not displaying")
            return null;
        }
    }
    DisplayHeader(){
        if(this.state.SearchDeliver){
            return(
                <Row className={'gif-anim'}>
                    <Col>
                        <img src={require('../Images/pick_site/camions_animation.gif')} />
                        <h5>
                            Veuillez patienter, nous recherchons<br/>
                            un transporteur pour votre livraison…
                        </h5>
                        <Button onClick={()=>{this.setState({modal : true})}} disabled={this.state.LoadingSubmit}
                                className={"btn-inscr annuler"}> {this.state.LoadingSubmit ?
                            <div className="spinner">
                                <Spinner animation="border" role="status" className={'spinner-btn white'}
                                         variant="primary"/> </div>:
                            <>Annuler</>}
                        </Button>
                        <hr className={"hr-register hr-dash"}/>
                    </Col>
                </Row>
            )
        }else {
            if(this
                .state
                .Demande !=='')
            {
                return (
                    <Row className={'header-etat'}>
                        <Col className={'details delete-padding'} lg={3} xs={2}>
                            <p>Votre transporteur</p>
                            <img className={"transp-map-img"}
                                src = {(this.state.Demande.transporteur.personne.personne.photo != null)
                                    ? BACKEND_ROOT_URL + this.state.Demande.transporteur.personne.personne.photo
                                    : require('../Images/pick_site/user.png')}
                            />
                        </Col>
                        <Col className={'vl details'} lg={2} xs={2}>
                            <p>Nom</p>
                            <p className={"data"}>{this.state.Demande.transporteur.personne.nom +" " +
                            this.state.Demande.transporteur.personne.prenoms}</p>
                        </Col>
                        <Col className={'vl details'} lg={2} xs={2}>
                            <p>Est à</p>
                            <p className={"data"}>{this.state.TempsApproche}</p>
                        </Col>
                        <Col className={'vl details'} lg={2} xs={2}>
                            <p>{this.state.Note}/5</p>
                            {this.F_OnDisplayNote(this.state.Note)}
                        </Col>
                        <Col lg={3} className={"details delete-padding"} xs={4}>
                            <Button className={"etat nouvellexp"} onClick=
                                {()=>this.setState({EtatCommandevisibility : true})}>
                                <span>État de la commande</span>
                            </Button>
                        </Col>
                    </Row>
                )
            }

        }
    }

    DisplayButtonOnMap(){
        if(!this.state.SearchDeliver){
            return(
                <div className={'details-button'}>
                    <Button onClick={()=>{console.log('detaails clicked')
                        this.setState({detailvisibility : true})}} className={"details"}>Détails<DownOutlined />
                    </Button>
                </div>
            )
        }
    }
    ToSendMsg(){
        axios.post(`${BACKEND_API_URL}transporteur/setMessagesSeen`, {
            EmetteurPersonneId: this.state.Demande.transporteur.personneId,
            RecepteurPersonneId : this.state.personneId,
            commandeId : this.state.Demande.commandeId
        }).then((res)=>{
            console.log("zzzzzzzzzzzzzzzzzzzzzzzz ",res)
            this.setState({newMessage : 0})
            this.props.parentCallback(true)
        })
    }

    CancelCommande(){
        this.setState({LoadingSubmit : true, modal : false})
        axios.post(`${BACKEND_API_URL}demande/cancel`, {
            commandeId: this.props.commandeId,
        }).then((res)=>{
            this.setState({LoadingSubmit : false})
            console.log("zzzzzzzzzzzzzzzzzzzzzzzz ",res.data)
            notification['info']({
                key : 'annulée',
                message: 'La commande a été annulée avec succès',
                description:"",
                duration: 10,
            });
            if(interval)
                clearInterval(interval)
            if(interval1)
                clearInterval(interval1)
            if(interval2)
                clearInterval(interval2)
            this.props.history.replace('/mes-expeditions')
        }).catch(()=>{this.setState({LoadingSubmit : false})})
    }
    DisplayButtonMessages(){
        if(!this.state.SearchDeliver){
            return(
                <div className={'msg-button'}>
                    <Badge count={this.state.newMessage} style={{backgroundColor :"#B38053"}}>
                        <Button onClick={()=>{this.ToSendMsg()}} className={"envoyer-msg-map"}>
                            <img src={require('../Images/pick_site/phone.png')}/> Envoyer un message
                        </Button>
                    </Badge>
                </div>
            )
        }
    }

    hide(){
        this.setState({detailvisibility:false})
    }
    hideEtatCommande(){
        this.setState({EtatCommandevisibility:false})
    }
    DisplayModal(){
        if(this.state.Demande !== ''){
            return(
                <ModalDetails Visiblity={this.state.detailvisibility} callback={()=>this.hide()}
                typeVehicule={this.state.Demande.transporteur.vehicule.vehiculeType.libelle}
                Marque = {this.state.Demande.transporteur.vehicule.marque}
                Modele = {this.state.Demande.transporteur.vehicule.modele}
                Matricule = {this.state.Demande.transporteur.vehicule.matricule}/>
            )
        }
    }

    DisplayEtatCommande(){
        if(this.state.Demande !== ''){
            return(
                <ModalEtatCommande Visiblity={this.state.EtatCommandevisibility}
                                   demande = {this.state.Demande}
                                   callback={()=>this.hideEtatCommande()}/>
            )
        }
    }

    onready(mapProps, map){
        this.setState({
            maprops : mapProps,
            mapref : map
        })
    }

    F_Direction(){
        console.log('cooo,e')

        console.log('cooo,e after')
            let {google} = this.state.maprops
        if(directionsService ==="")
            {console.log("init .............")
                directionsService = new google.maps.DirectionsService
            }
        if(this.state.directionsRenderer ===''){
            directionsRenderer = new google.maps.DirectionsRenderer({
                preserveViewport: true,
                polylineOptions: { strokeColor: "#1D1F39" } })
            this.setState({directionsRenderer : directionsRenderer})
            directionsRenderer.setOptions({ suppressMarkers: true })
            directionsRenderer.setMap(this.state.mapref)
            this.state.mapref.setOptions({
                fullscreenControl : false,streetViewControl:false,mapTypeControl:false,
                keyboardShortcuts:false
            })
        }


        if (this.state.V_LivreurPosition.lat && this.state.V_LivreurPosition.lng) {
            let request = {
                origin: this.state.V_LivreurPosition,
                destination: this.state.DepartMarkers,
                travelMode: 'DRIVING'
            };
            if(this.state.livrState === 'ENEF'){
                request = {
                    origin: this.state.V_LivreurPosition,
                    destination: this.state.ArriveMarkers,
                    travelMode: 'DRIVING'
                };
            }

            directionsService.route(request, function(result, status) {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                }
            });
        }else {
            let request = {
                origin: this.state.DepartMarkers,
                destination: this.state.ArriveMarkers,
                travelMode: 'DRIVING'
            };
            directionsService.route(request, function(result, status) {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                }
            });
        }



    }

    getTripTimeEstimation = async () =>{
        let origins = [this.state.V_LivreurPosition.lat+","+this.state.V_LivreurPosition.lng];
        let destinations = [this.state.DepartMarkers.lat+","+this.state.DepartMarkers.lng];
        /*distance.matrix(origins, destinations, function (err, distances) {
            if (!err)
                console.log(distances);
            else
                console.log(err)
        })*/
        const {google} = this.state.maprops

        const service = new google.maps.DistanceMatrixService();
        const request = {
            origins: origins,
            destinations: destinations,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
        };
        console.log("request distance matrix ",request)

        service.getDistanceMatrix(request).then(response=>{
            console.log("res distance matrix ",response)
            if(response.rows[0].elements[0]){
                let temp = response.rows[0].elements[0].duration.text
                temp = temp.replace("minutes", "min")
                temp = temp.replace("minute", "min")
                temp = temp.replace("heures", "h")
                temp = temp.replace("heure", "h")
                this.setState({
                    TempsApproche : temp,
                });
            }

        }).catch(e=>{console.log("erreur matrix : ",e)})
    }

    async getLivraisonStatus(){
        console.log('id : ',this.state.Demande.livraison.livraisonId)
        await axios.post(`${BACKEND_API_URL}livraison/getlivraisonStats`, {
            livraisonId: this.state.Demande.livraison.livraisonId,
            DemandeId : this.state.Demande.demandeId, commandeId : this.state.Demande.commandeId
        }).then(res => {
            if(res.data.success){
                console.log('statut Livraison',res.data.livraison)
            let LivraisonStatus = res.data.livraison.find(elem => elem.statutLivraisonType === "LIEF");
            let EnlevementStatus = res.data.livraison.find(elem => elem.statutLivraisonType === "ENEF");
            if(EnlevementStatus){
                console.log('aaaaaaaaaaaa',EnlevementStatus)
                if(this.state.livrState !== "ENEF"){
                    console.log("change statut")
                    this.setState({livrState : "ENEF"})
                    // this.getTripTimeEstimation()
                }
            }
            if(LivraisonStatus){
                if (this.state.livrState !== "LIEF"){
                    window.location.replace("/mes-expeditions")
                }

            }
            }else
            {
                notification['info']({
                    key : 'annulée',
                    message: 'La commande a été annulée par le transporteur',
                    description:"",
                    duration: 10,
                });
                if(interval)
                    clearInterval(interval)
                if(interval1)
                    clearInterval(interval1)
                if(interval2)
                    clearInterval(interval2)
                this.props.history.replace('/mes-expeditions')
            }
        })
    }


    render() {
        return (
            <Col className={'right-dashboard map'}>
                {this.state.LoadingMap ?
                    <Spinner animation="border" role="status" className={'spinner-btn'}
                             variant="primary"/> :
                    <>
                        {this.DisplayHeader()}
                        {/*  ************ modal *********************  */}
                        <div>
                            <Modal closable={false}
                                   visible={this.state.modal}
                                   title={'Annulation de la course'}
                                   footer={null} className={'delete-modal'}
                            >
                                <Row>
                                    <Col>
                                            <span className={"confirm-txt"}>
                                                Êtes-vous sûr de vouloir annuler cette course ?
                                            </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className={"btn-oui"} onClick=
                                            {()=>this.CancelCommande()}>
                                            <span>Oui</span>
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button className={"btn-non"} onClick =
                                            {()=>{this.setState({modal: false})}}>
                                            <span>Non</span>
                                        </Button>
                                    </Col>

                                </Row>
                            </Modal>
                        </div>
                        <Row>
                            <Map google={this.props.google} zoom={15}
                                 center={this.state.region} className={'map-view'} id={"map-view"}
                                 onReady={(mapProps, map) => this.onready(mapProps, map)}>
                                {this.F_OnPlaceRecepteursMarkers()}
                                {this.F_OnPlaceLivreurMarker()}
                                {this.DisplayButtonOnMap()}
                                {this.DisplayButtonMessages()}
                                <Marker name={'Current location'}
                                        position = {this.state.DepartMarkers}
                                        icon={{
                                            url: require('../Images/pick_site/depart_marker.png'),
                                            scaledSize: new this.props.google.maps.Size(40,40)
                                        }}
                                />
                                <Marker name={'Current location'}
                                        position = {this.state.ArriveMarkers}
                                        icon={{
                                            url: require('../Images/pick_site/arrive_marker.png'),
                                            scaledSize: new this.props.google.maps.Size(40,40)
                                        }}
                                />
                            </Map>
                        </Row>
                        {this.DisplayModal()}
                        {this.state.EtatCommandevisibility && this.DisplayEtatCommande()}
                    </>
                }

            </Col>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP_API_KEY),
    language : 'fr'
})(MapExpedition)

