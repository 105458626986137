import React, {Component, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home1/home1.css'
import {Col} from "react-bootstrap";
import Button from "antd/es/button";
import {Link} from "react-router-dom";

//**************** import images ********************


const Mentionslegales = () =>{

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
        return (
                <Container fluid style={{minHeight : "75vh"}} className={'inscription'}>
                    <Row className="inscri-expediteur">
                    <Col>
                        <h2 className={'title-form'}>Politique de confidentialité</h2>
                        <hr/>
                    </Col>
                </Row>
                    <Row className={"mentions-legales cgu"}>
                        <Col>
                        <h4>Introduction</h4>
                        <p>
                        Conformément aux dispositions du Règlement 2016/679 du 27 avril 2017 relatif à la 
                        protection des personnes physiques à l’égard du traitement des données à caractère
                         personnel (ci-après « RGPD ») et à la libre circulation de ces données et aux 
                         dispositions légales et règlementaires nationales et européennes, « PICK&DELI » 
                         société par action simplifiée immatriculée au RCS de Nanterre sous le numéro 894 672 302, reconnait l’importance de traiter les données à caractère personnel.</p>
                        <h4>Définitions générales</h4>
                        
                        <p>« Société »: « PICK&DELI » ayant pour enseigne « PICK&DELI », Société par action simplifiée, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 894 672 302, dont le siège social est 8 rue du General Roguet, 92110 Clichy, et dont l'activité consiste en la mise en relation via une Application et un site web entre une personne physique ou une personne morale ayant besoin d'un service de livraison de marchandises (ci-après « Expéditeur ») et d’un Transporteur professionnel (ci-après « Transporteur ») suggéré par la plateforme en vue de la conclusion d’un accord relatif à l’exécution d’un service de transport ou d’une livraison de marchandises .</p>
                        <p>« Utilisateur(s) » : personne physique majeure et capable, ou personne morale représentée par une personne physique dûment habilitée, qui utilise la plateforme (application ou site web) pour effectuer une demande de livraison de marchandise ou qui réalise une livraison de marchandise.
</p>
                        <p>« Visiteur(s) » : désigne toute personne qui accède à la l’Application.</p>
                        <p>« Application » : désigne l'Application informatique consistant en une plateforme de mise en relation par voie électronique telle que visée par l'article 60 de la loi 2016-1088 du 8 août 2016 dont la conception, le développement, la maintenance et l'exploitation constituent l'activité et le savoir-faire du groupe auquel appartient la Société. Elle est accessible via une Application mobile, un site web et permet l'accès aux services.</p>
                        <p>« Smartphone » : désigne tout terminal mobile ou non (Smartphone, PC, tablette, ordinateur, etc.) disposant d'une connexion au réseau Internet et permettant ainsi de télécharger, d’installer et d’utiliser l'Application.</p>
                        <p>« Données personnelles » : désigne toute information qui se rapporte à une personne physique identifiée ou pouvant être identifiée directement ou indirectement grâce à des données. Un nom, une adresse mail, un numéro de téléphone ou des données de localisation, sont assimilés à des données personnelles pour exemple.</p>
                        <p>« Traitement » ou « traitement de données » : désigne toute opération ou ensemble d’opérations effectuées sur une ou plusieurs données personnelles. La collecte, l’enregistrement, la conservation, l’utilisation, la modification, l’extraction, la simple consultation, la suppression ou la destruction de données constituent un traitement.</p>
                        <p>« Responsable de traitement » : désigne toute personne physique ou morale, qui traite des données personnelles selon des moyens et finalités (objectifs) qu’il fixe et détermine lui-même.</p>
                        <h4>1. Responsable de Traitement</h4>
                        <p>PICK&DELI ci-après désignée la « Société PICK&DELI » ou « la Société », est une société par action simplifiée immatriculée au RCS de Nanterre sous le numéro 894 672 302, dont le siège social est situé 8 rue du General Roguet, 92110 Clichy et qui exploite une Application, agit en qualité de Responsable de traitement quant aux données personnelles qui sont collectées via son Application.</p>
                        <h4>2. Base Légale et Objectifs du traitement</h4>
                            <p>La Société, traite les données personnelles ayant pour objectifs d'assurer l'échange et la communication des informations avec les utilisateurs, ainsi que la bonne exécution des prestations de service (enlèvements, livraisons, etc.) commandées via l'Application par l'expéditeur.</p>
                            <p>Les traitements des données personnelles suivants reposent sur la conclusion d’un accord relatif à l’exécution d’un service de transport ou d’une livraison de marchandises :</p>
                            <ul>
                                <li>
                                Tout traitement de données personnelles nécessaires à la bonne exécution des prestations de livraison sollicitées par l’expéditeur                                </li>
                            </ul>
                            <p> Les traitements des données personnelles suivants reposent sur le respect d’une obligation légale imposée par la législation :</p>
                            <ul>
                                <li>
                                La conservation des données relative aux obligations légales de sécurité et fiscale
                               </li>
                            </ul>
                            <p> Les traitements des données personnelles suivants reposent sur l’intérêt légitime de la Société :</p>
                            <ul>
                                <li>
                                Gestion de la relation Utilisateur
                                 </li>
                                 <li>
                                 La Gestion des litiges et réclamations Utilisateurs                              
                                 </li>
                            </ul>
                            <p>Lors de la navigation sur l’Application, la Société est susceptible d’utiliser des cookies pour identifier les préférences des Visiteurs. Le terme de cookie désigne l’ensemble des traceurs déposés et/ou lus lors de la consultation d’un site internet. Un cookie est un fichier de petite taille, qui ne permet pas l’identification du Visiteur, mais qui enregistre des informations relatives à la navigation d’un ordinateur ou d’un périphérique.</p>
                            <p>La Société utilise le type de cookies suivants :</p>
                            <p><b>Cookies techniques nécessaires</b> qui permettent d’assurer le chargement complet et correct de l’Application lorsque le Visiteur y accède depuis son navigateur. Ces cookies permettent le bon fonctionnement du l’Application, il n’est pas possible de les désactiver.</p>
<p><b>Cookies analytiques ou de performance</b> qui recueillent des renseignements statistiques sur l’accès à l’Application et qui permettent de connaître l’utilisation et la performance de l’Application et d’en améliorer le fonctionnement.</p>
<p>La Société vous offre la possibilité de paramétrer à tout moment les cookies déposés et lus sur son Application en cliquant sur le lien « Cookies » de l’Application. Vous avez également la possibilité de vous opposer au dépôt de cookies par le biais de votre navigateur et/ou des paramétrage de votre Smartphone.</p>
                            <h4>3. Données personnelles collectées</h4>
                            <p>Les données personnelles sont collectées par la Société au moment de la commande d’une demande de prestation de transport de livraison de marchandise par l’Utilisateur soit :</p>
                            <ul>
                                <li>Via l’Application en cas de commande d’une demande de livraison par l’expéditeur.
</li><li>Via le formulaire de création d’un compte « Expéditeur » et « Transporteur » sur l’Application.</li>
                            </ul>
                            <p>La Société s’engage à respecter le principe de minimisation des données personnelles posé à l’article 5.1 du RGPD, qui consiste à ne collecter que les données exactes strictement nécessaires aux traitements.
</p><p>Les données personnelles collectées comprennent :</p>
                            <ul>
                                <li>
                                Données personnelles relatives à l’identité, aux coordonnées de l’Utilisateur, au moyen de paiement fournies par lui-même, aux véhicules utilisés aux fins de réalisation de la prestation de livraison de marchandise :
                                </li>
                                <ul>
                                    <li>
                                    Prénom
<li>Nom</li>
<li>Civilité</li>
<li>Adresse électronique</li>
<li>Numéro de téléphone</li>
<li>Adresse d’enlèvement de la marchandise</li>
<li>Adresse de destination de la marchandise</li>
<li>Moyen de paiement de la Prestation : références carte bancaire </li>
    <li>Sa dénomination sociale ou raison sociale</li>
<li>Statut de l’entreprise</li>
<li>Son numéro SIRET</li>
<li>Son numéro de TVA</li>
<li>L’adresse de son siège social,</li>
<li>Ses coordonnées de contact (téléphone, e-mail),</li>
<li>Une copie de la carte d’identité du représentant légal</li>
<li>Des informations relatives aux contacts au sein des Utilisateurs personnes morales (nom et prénom d’un interlocuteur personne physique, et leurs coordonnées de contact respectives, téléphone, email)</li>
<li>Des informations relatives aux commandes de livraison à effectuées via l’application (adresse d’enlèvement, adresse de livraison, nature de colis, poids du colis etc.)</li>
<li>Des coordonnées bancaires telles que RIB/IBAN. </li>
    <li>KBIS</li>
<li>Permis de conduire</li>
<li>Licence de transport</li>
<li>Attestation d’assurance du véhicule</li>
<li>Attestation d’assurance marchandise</li>
<li>Communication via l’Application (commentaires, avis, note, etc.)</li>
<li>Consultation des emails que la Société adresse aux Utilisateurs
(emails administratifs ou de prospection commerciale)</li>
<li>Les données collectées pour l’exécution des conditions générales d’utilisation</li>
<li>Toute autre information ou donnée supplémentaire (mentionnée par exemple lors d’un échange par appel téléphonique avec un Utilisateur ou d’un échange via nos messages en ligne.
                                    </li>
                                    </li>
                                </ul>
                                <li>
                                Données personnelles techniques de l’Application lors de la connexion des Utilisateurs :
                                </li>
                                <ul>
                                <li>Données de connexion de l’Utilisateur (adresse mail, mot de passe)</li>
<li>Adresse IP de l’Utilisateur</li>
<li>Géolocalisation du Smartphone des Utilisateurs</li>
                                </ul>
                            </ul>
                            <p>La Société se réserve le droit de mettre en place un système de vérification de certaines des informations fournies par les Utilisateurs, à des fins de prévention ou de détection des fraudes.</p>
                            <h4>4. Destinataires des données</h4>
                            <p>Les données personnelles des Utilisateurs via l’Application sont traitées par les salariés de la Société en charge de la relation commerciale et habilités à traiter les demandes des Utilisateurs. La Société garantit que ses salariés sont formés à traiter des données personnelles conformément au respect des obligations légales imposées par le RGPD.
</p><p>Aussi la Société communique les données strictement nécessaires, soit le nom et le numéro de téléphone des Utilisateurs qui effectue et accepte une demande de livraison de marchandise via l’Application. Chacun de ces Utilisateurs agit comme responsable de traitement distinct.
</p><p>Les données personnelles collectées et traitées par la Société sont susceptibles d’être communiquées aux destinataires ou catégories de destinataires suivants :</p>
<ul>
    <li>
    Le prestataire de services de paiement pour les opérations de paiement sécurisé en ligne : la société STRIPE
</li><li>L’hébergeur de la Plateforme et des données
</li><li>Le prestataire de maintenance de la Plateforme
</li><li>Le prestataire de mailing pour l’envoi des emails transactionnels relatifs aux commandes passées via la Plateforme
    </li><li>Le prestataire d’emailing pour l’envoi de newsletters.</li>
</ul>
<p>La Société communique les données de l’Utilisateurs lorsqu’il en a l’obligation légale, en vertu d’un texte ayant force de loi ou d’une décision judiciaire ou administrative, ainsi que pour répondre à toute réclamation à son encontre.
</p><p>La Société garantit aux Utilisateurs de l’Application, le respect par les Utilisateurs des obligations légales imposées par le RGPD en matière de données personnelles. Dès lors, la Société s’assure, préalablement et tout au long de l’exécution contractuelle, que les Utilisateurs respectent les mesures de sécurité techniques et organisationnelles indispensables aux traitements des données personnelles réalisés par la Société.</p>
<h4>5. Durée de conservation des données à caractère personnel</h4>
<p>Les données à caractère personnel des Utilisateurs sont effacées deux mois après la réalisation de la demande de livraison sollicitée par l’Expéditeur.
</p><p>Toutefois, en cas de réclamation d’un Utilisateur, la durée de conservation des données personnelles ne seront effacées qu’après règlement du litige.
</p><p>Certaines données personnelles peuvent faire l’objet d’une durée de conservation plus longue, en raison d’une obligation légale ou texte juridique précis.
</p><p>Ainsi, la Société conserve en base active les données personnelles relatifs aux bons de livraison, justificatifs de livraisons, factures, le temps de l’exercice comptable au cours duquel ces documents sont établis, puis en archivage intermédiaire pendant 10 ans à partir de la clôture de l’exercice comptable, conformément à l’article L123-22 du code de commerce.
</p><p>Les coordonnées bancaires ayant servi au paiement de la prestation de transport par un Utilisateur sont conservées pour une finalité de preuve en cas d’éventuelle contestation, en archivage intermédiaire avec accès restreint et au maximum 13 mois suivant la date de débit (15 mois en cas de carte à débit différé), conformément à l’article L133-24 du Code Monétaire et Financier. Toutefois, La Société informe les Utilisateurs que les données relatives au cryptogramme visuel de la carte de paiement utilisée pour le paiement de la prestation de livraison de marchandise sont effacées immédiatement dès l’exécution de la demande de livraison de marchandise.</p>
<p>Concernant les données financières (paiements et remboursements) nécessaires à l’Utilisateur pour qu’il remplisse ses obligations fiscales et comptables, celles-ci sont conservées jusqu’à la prescription du délai légal desdites obligations.
  </p><p>Les données personnelles relatives aux contrats conclus entre la Société et les Utilisateurs peuvent être conservées pendant 5 ans, conformément à l’article L100-4 du Code de Commerce.
  </p>
  <h4>6. Stockage – Transfert - Protection des données à caractère personnel</h4>
<p>Les données des Utilisateurs n’ont pas à faire l’objet d’un transfert en dehors de l’Union Européenne.
</p><p>Aucun transfert des données personnelles des Utilisateurs hors Union européenne n’a vocation à avoir lieu.
</p><p>La Société informe ses Utilisateurs que ses données personnelles sont hébergées sur un serveur situé dans l’Union Européenne et la Société fournit aux Utilisateurs sur simple demande, après vérification de l’identité de l’Utilisateur, les coordonnées de la société en charge de la conservation des données.
</p><p>La Société met en œuvre les mesures techniques et organisationnelles appropriées telles que mentionnées à l’article 24 et 32 du RGPD pour assurer et garantir un niveau de sécurité adapté au risque et préserver ainsi les données à caractère personnel contre toute atteinte. A titre d’information, la Société assure la protection de ses environnements informatiques en utilisant constamment des pares-feux actualisés suivant les normes industrielles et d’autres systèmes de sécurité.
</p>
<h4>7. Droits</h4>
<p>Conformément à la Loi nationale n°78-17 dite « Informatique et Libertés » du 6 janvier 1978 et au règlement Européen sur la Protection des Données Personnelles (dit « RGPD »), vous disposez en raison du traitement réalisé sur vos données personnelles, les droits mentionnés ci-dessous.
</p><p>La Société se réserve toutefois le droit de vérifier l’identité des Utilisateurs, avant de donner suite à la demande d’exercice de droits formulée par un Utilisateur.
</p><p>Vous pouvez exercer ces droits en nous contactant par écrit à l’adresse mail contact@pickdeli.fr ou à l’adresse suivante : A l’intention de PICK&DELI (Service Juridique), 8 rue du général Roguet, 92110 Clichy, France.</p>
<p>Les Utilisateurs disposent donc d’un droit d’accès, de rectification, d’effacement et de portabilité de leurs données personnelles, ainsi qu’un droit d’opposition et 
de limitation du traitement, dans les conditions prévues par la réglementation applicable. Les Utilisateurs peuvent, à tout moment, s’opposer au traitement de leurs données à des fins de prospection commerciale ou conformément à la règlementation applicable.
</p>
<p>En France, l’autorité de contrôle compétent est la Commission Nationale de l’Informatique et des Libertés (CNIL). Aussi, les Utilisateurs peuvent introduire une réclamation auprès de cette autorité de contrôle soit :
</p>
<ul>
<li>Le site (<a href={"https://www.cnil.fr/fr/plaintes"}>https://www.cnil.fr/fr/plaintes</a>)</li>
<li>Ou à l’adresse postale suivante : CNIL – Service des Plaintes – 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.</li>
</ul>
<h4>8. Modification de la Politique de Confidentialité</h4>
<p>La Société peut être amenée à modifier sa politique de confidentialité à tout moment. La date de mise à jour figurera sur l’Application à la fin des clauses relatives à la politique de confidentialité.
</p><p>La politique de confidentialité applicable est celle figurant sur l’Application au moment de la connexion de l’Utilisateur.</p>
<h4>9. Contact</h4>
<p>La confiance de ses Utilisateurs est fondamentale pour la Société. C’est pourquoi la Société veille à répondre à tout moment aux questions de ses Utilisateurs portant sur les traitements des données personnelles réalisés.
</p><p>Si vous avez des questions qui n’ont pas trouvé réponse dans la présente politique de confidentialité ou si vous désirez des informations complémentaires sur un point précis, veuillez adresser votre requête à l’adresse suivante :</p>
<ul>
    <li>
    PICK&DELI 8 rue du général Roguet, 92110 Clichy, France
</li><li>Ou par e-mail : <a href={"mailto: contact@pickdeli.fr"}>contact@pickdeli.fr</a></li>
</ul>
<p style={{fontSize:"12px"}}>Dernière mise à jour : 11 février 2022</p>

                            </Col>
                    </Row>
                </Container>
        );
    }



export default Mentionslegales;
