import React, {Component, useMemo} from 'react'
import {checkFileSize, checkMimeType, checkPDFType, maxSelectFile} from "../Controls/FilesErrors";
import axios from "axios";
import {Col, Container, Spinner} from "react-bootstrap";
import {Avatar, Tooltip, Dropdown, Menu, notification, message, DatePicker, Checkbox} from "antd";
import Popover from "antd/es/popover";
import {FaAngleRight, AiOutlinePlus,IoIosCloseCircleOutline} from "react-icons/all";

import { Select } from 'antd';
import {withTranslation} from "react-i18next";
import 'react-phone-number-input/style.css';
import Row from "react-bootstrap/Row";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import Button from "antd/es/button";
import {BACKEND_API_URL} from "../env";
import {openNotificationError} from "../Helpers";
import {Link} from "react-router-dom";

let options = {
    componentRestrictions: {country: "fr"}
}
const { Option } = Select;


class Register_exped_partic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorPays : false,
            errorPhone : false,
            ErrorAge : false,
            denominationErr : false,
            adresseErr : false,
            adresseFacturErr : false,
            statutErr : false,
            telephoneErr : false,
            emailErr : false,
            passwordErr : false,
            siretErr : false,
            tvaErr : false,
            Date_NaissanceErr : false,
            checkedErr : false,
            LoadingSubmit: false,
            password_confirmErrMsg: '',
            errors: {
                piece: '',
            },
            kbis : "",
            rib : "",
            adresse : "",
            adresseFactur: "",
            denomination:"",
            statut:"",
            telephone:"",
            email :"",
            password: "",
            siret:"",
            tva:"",Date_Naissance:'',Nom:'',prenom:'',
            permis:'',cin:'',password_confirm:'',
            checked:false,
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeDateChange = this.onChangeDateChange.bind(this);
    }

    validateForm(){
        let error = false
        if(this.state.Nom === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({NomErr: true})
            error = true
            document.getElementById('Nom').scrollIntoView()
            return error
        }
        else{
            this.setState({NomErr: false})
            error = false
        }

        if(this.state.prenom === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({prenomErr: true})
            error = true
            document.getElementById('prenom').scrollIntoView()
            return error
        }
        else{
            this.setState({prenomErr: false})
            error = false
        }

        if(this.state.Date_Naissance === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({Date_NaissanceErr: true})
            error = true
            document.getElementById('Date_Naissance').scrollIntoView()
            return error
        }
        else{
            this.setState({Date_NaissanceErr: false})
            error = false
        }

        if(this.state.adresse === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseErr: true})
            error = true
            document.getElementById('adresse').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseErr: false})
            error = false
        }


        if(!validPhoneRegex.test(this.state.telephone.trim()) ){
            console.log('Veuillez remplir ce champ')
            this.setState({telephoneErr: true})
            error = true
            document.getElementById('telephone').scrollIntoView()
            return error
        }
        else{
            this.setState({telephoneErr: false})
            error = false
        }
        if(!validEmailRegex.test(this.state.email.trim())){
            console.log(validEmailRegex.test(this.state.email.trim()))
            this.setState({emailErr: true})
            error = true
            document.getElementById('email').scrollIntoView()
            return error
        }
        else{
            this.setState({emailErr: false})
            error = false
        }
        if(this.state.password === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({passwordErr: true})
            error = true
            document.getElementById('password').scrollIntoView()
            return error
        }
        else{
            this.setState({passwordErr: false})
            error = false
        }
        if(this.state.password_confirm === ''){
            this.setState({password_confirmErr: true,
                password_confirmErrMsg : 'Veuillez remplir ce champ'})
            error = true
            document.getElementById('password_confirm').scrollIntoView()
            return error
        }
        else{
            if(this.state.password_confirm === this.state.password){
                this.setState({password_confirmErr: false})
                error = false
            }
            else {
                this.setState({password_confirmErr: true,
                    password_confirmErrMsg : 'Les mots de passe ne correspondent pas'})
                error = true
                document.getElementById('password_confirm').scrollIntoView()
                return error
            }
        }
        if(!this.state.checked){
            this.setState({checkedErr: true})
            error = true
            document.getElementById('checked').scrollIntoView()
            return error
        }
        else{
            this.setState({checkedErr: false})
            error = false
        }
        return error
    }

    onChange(e) {
        //this.setState({ [e.target.name]: e.target.value })

        const { name, value } = e.target;
        let errors = this.state.errors;


        this.setState({errors, [name]: value})
    }


    onChangeDateChange(date, dateString) {
        this.setState({Date_Naissance : dateString})
    }


    handleChange = adresse => {
        console.log('onchange',adresse)
        this.setState({ adresse : adresse });

    };

    handleSelect = address => {
        console.log('on select : ',address)

        this.setState({ adresse : address });
    };
    onChangecheckbox(e){
        this.setState({checked : e.target.checked})
    }


    async onSubmit() {

        let valid = await this.validateForm()
            console.log("etat : ",valid)
        if(valid === false){
            this.setState({LoadingSubmit : true})
            await axios.post(`${BACKEND_API_URL}expediteur/client/registration`, {
                nom: this.state.Nom,
                prenoms: this.state.prenom,
                adresse: this.state.adresse,
                telephone: this.state.telephone,
                dateNaissance: this.state.Date_Naissance,
                email: this.state.email.trim(),
                password: this.state.password,
        }).then(async response => {
                console.log(response)
                this.setState({LoadingSubmit : false})
                if(!response.data.success){
                    if(response.data.message === 'Email déjà utilisé'){
                        openNotificationError('Adresse e-mail est déjà utilisée','')
                    }else
                        openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
                }else {
                    window.location.replace("/success_register");
                }

        }).catch(err=>{
                this.setState({LoadingSubmit : false})
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
        })
        }
    }

    render() {
        const {errors} = this.state;
        let width = window.innerWidth;

        // ********************************** DESKTOP VERS ***************************************

            return (
                <Container style={{maxWidth: "90vw", marginBottom : "70px"}}>
                    <Row className="inscri-expediteur">
                        <Col>
                            <h2 className={'title-form'}>Particulier</h2>
                            <hr className={"hr-register"}/>
                        </Col>
                    </Row>
                    <Row className={"create-account"}>
                        <Col>
                        <h3>Création du compte</h3>
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="Nom"
                                id="Nom"
                                placeholder={"Nom"}
                                value={this.state.Nom}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.NomErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col xs={12} lg={true}>
                            <input
                                type="text"
                                name="prenom"
                                id="prenom"
                                placeholder={"Prénom"}
                                value={this.state.prenom}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.prenomErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} id="Date_Naissance" xs={12} lg={true}>
                            <DatePicker format={"DD/MM/YYYY"}
                                        placeholder={'Date de naissance'}
                                        className={"date-input"}
                                        onChange={this.onChangeDateChange}/>
                            {this.state.Date_NaissanceErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <PlacesAutocomplete
                                value={this.state.adresse}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"/>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresse"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                placeholder={"N° de téléphone"}
                                value={this.state.telephone}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.telephoneErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                        <Col xs={12} lg={true}>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"Adresse e-mail"}
                                value={this.state.email}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>

                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder={"Mot de passe"}
                                value={this.state.password}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.passwordErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="password"
                                name="password_confirm"
                                id="password_confirm"
                                placeholder={"Confirmer le mot de passe"}
                                value={this.state.password_confirm}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.password_confirmErr === true &&
                            <span className='error'>{this.state.password_confirmErrMsg}</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"}>
                            <Checkbox onChange={(e)=>this.onChangecheckbox(e)}
                            className={"check-cgu"} id={"checked"}>Je reconnais avoir
                            pris connaissance des <Link to={"/cgu"} target={"_blank"}>
                                    <label style={{color:"#1D1F39", fontWeight:"700", cursor:"pointer"}}>
                                        Conditions générales d'utilisation</label>
                            </Link> et les accepte.</Checkbox>
                            {this.state.checkedErr === true &&
                            <span className='error'>Veuillez cocher cette case</span>}
                        </Col>
                    </Row>

                    <Row className="inscri-expediteur">
                        <Col>
                            <Button className={"btn-continue"} onClick={()=>this.onSubmit()}
                            disabled={this.state.LoadingSubmit}>
                                {this.state.LoadingSubmit ? <div className="spinner">
                                    <Spinner animation="border" role="status" className={'spinner-btn'}
                                             variant="primary"/> </div>:
                                <span>Continuer</span>}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )

        //************************************** MOBILE VERS ***************************************

    }
}

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);


// const validateForm = (errors) => {
//     let valid = true;
//     Object.values(errors).forEach(
//         // if we have an error string set valid to false
//         (val) => val.length > 0 && (valid = false)
//     );
//     return valid;
// }

const underAgeValidate = (birthday) => {

    // convert from DD/MM/YYYY to yyyy/mm/dd
    var datearray = birthday.split("/");
    var newdate =  datearray[2] + '/' + datearray[1] + '/' + datearray[0] ;

    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    var optimizedBirthday = newdate.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

    if(myAge < 18) {
        return true;
    }else{
        return false;
    }

}


export default Register_exped_partic
