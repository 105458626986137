const TirageNumberReducer = (state = '', action) => {
    switch (action.type) {
        case 'SET_NUMBER' :
            return state= action.number;
        case 'RESET_Number' :
            return state='';
        default :
            return state
    }
}
export default TirageNumberReducer;
