import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {IsLoggedIn, openNotificationError} from "../Helpers";
import {Modal, Dropdown, Menu, notification, Tag, Select, Avatar, Input} from "antd";
import Button from "antd/es/button";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import PlacesAutocomplete from "react-places-autocomplete";
import {BACKEND_API_URL} from "../env";

const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);
const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
const { TextArea } = Input;


class NouvelleExpedForm extends Component {
    constructor() {
        super()
        this.state = {
            adresseEnleve:'',
            contact_enlev: '',
            tel_enlev:'',
            adresseLivraison:'',
            contact_livraison:'',
            tel_livraison:'',
            natureColis:'',
            nombreColis:'',
            poids:'',
            horsGabarit:'',
            commentaire :'',
            nombreChariot : 0,
            adresseEnleveErr : false,
            contact_enlevErr : false,
            tel_enlevErr : false,
            adresseLivraisonErr : false,
            contact_livraisonErr : false,
            tel_livraisonErr : false,
            nombreChariotErr : false,
            natureColisErr : false,
            nombreColisErr : false,
            poidsErr : false,
            horsGabaritErr : false,
            LoadingSubmit: false,
        }
        this.onChange = this.onChange.bind(this)


    }

    componentDidMount() {
        window.scrollTo(0, 0)
        console.log("data : ",this.props.ExpeditionData)
        const query = new URLSearchParams(window.location.search);
        const token = query.get('enlev')
        console.log("token ",token)//123
        this.setState({
            adresseEnleve : query.get('enlev'),
            adresseLivraison : query.get('livr')
        })
        if(Object.keys(this.props.ExpeditionData).length>0){
            this.setState({
                contact_enlev:this.props.ExpeditionData.nomExpediteur,
                tel_enlev:this.props.ExpeditionData.telephoneExpediteur,
                adresseEnleve:this.props.ExpeditionData.adresseDepart,
                contact_livraison:this.props.ExpeditionData.nomDestinataire,
                tel_livraison:this.props.ExpeditionData.telephoneDestinataire,
                adresseLivraison:this.props.ExpeditionData.adresseArrivee,
                nombreColis:this.props.ExpeditionData.nombreColis,
                horsGabarit:this.props.ExpeditionData.isHorsGabarit,
                nombreChariot:this.props.ExpeditionData.nombreChariot,
                natureColis:this.props.ExpeditionData.natureColisId,
                poids:this.props.ExpeditionData.plagePoidsId,
                commentaire :this.props.ExpeditionData.commentaire,
            })
        }
    }

    validateForm(){
        let error = false
        if(this.state.adresseEnleve === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseEnleveErr: true})
            error = true
            document.getElementById('adresseEnleve').scrollIntoView()
            return error
        }else{
            this.setState({adresseEnleveErr: false})
            error = false
        }
        if(this.state.contact_enlev === ''){
            this.setState({contact_enlevErr: true})
            error = true
            document.getElementById('contact_enlev').scrollIntoView()
            return error
        }
        else{
            this.setState({contact_enlevErr: false})
            error = false
        }
        if(!validPhoneRegex.test(this.state.tel_enlev.trim()) ){
            this.setState({tel_enlevErr: true})
            error = true
            document.getElementById('tel_enlev').scrollIntoView()
            return error
        }
        else{
            this.setState({tel_enlevErr: false})
            error = false
        }
        if(this.state.adresseLivraison === ''){
            this.setState({adresseLivraisonErr: true})
            error = true
            document.getElementById('adresseLivraison').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseLivraisonErr: false})
            error = false
        }
        if(this.state.contact_livraison === ''){
            this.setState({contact_livraisonErr: true})
            error = true
            document.getElementById('adresseLivraison').scrollIntoView()
            return error
        }
        else{
            this.setState({contact_livraisonErr: false})
            error = false
        }
        if(!validPhoneRegex.test(this.state.tel_livraison.trim()) ){
            this.setState({tel_livraisonErr: true})
            error = true
            document.getElementById('tel_livraison').scrollIntoView()
            return error
        }
        else{
            this.setState({tel_livraisonErr: false})
            error = false
        }
        if(this.state.natureColis === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({natureColisErr: true})
            error = true
            document.getElementById('natureColis').scrollIntoView()
            return error
        }
        else{
            this.setState({natureColisErr: false})
            error = false
        }
        if(this.state.nombreColis === ''){
            this.setState({nombreColisErr: true})
            error = true
            document.getElementById('nombreColis').scrollIntoView()
            return error
        }
        else{
            this.setState({nombreColisErr: false})
            error = false
        }
        if(this.state.poids === ''){
            this.setState({poidsErr: true})
            error = true
            document.getElementById('poids').scrollIntoView()
            return error
        }
        else{
            this.setState({poidsErr: false})
            error = false
        }
        if(this.state.horsGabarit === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({horsGabaritErr: true})
            error = true
            document.getElementById('horsGabarit').scrollIntoView()
            return error
        }
        else{
            this.setState({horsGabaritErr: false})
            error = false
        }
        if(this.state.nombreChariot === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({nombreChariotErr: true})
            error = true
            document.getElementById('nombreChariot').scrollIntoView()
            return error
        }
        else{
            this.setState({nombreChariotErr: false})
            error = false
        }

        return error
    }


    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})
    }


    handleChange_addresse_enlev = adresse => {
        this.setState({ adresseEnleve : adresse });
    };
    handleChange_addresse_livraison = adresse => {
        this.setState({ adresseLivraison : adresse });
    };
    handleNatureColis = value => {
        this.setState({ natureColis : value });
    }
    handlePoids = value => {
        this.setState({ poids : value });
    }
    handleHorsGabarit = value => {
        this.setState({ horsGabarit : value });
    }

    async onSubmit() {
        let valid = await this.validateForm()
        console.log("etat : ",valid)

        if(valid === false){

            this.setState({LoadingSubmit : true})
            await axios.post(`${BACKEND_API_URL}commandes/tarificationAndAvailabelDelivers`, {
                    adresseDepart: this.state.adresseEnleve,
                    adresseArrivee: this.state.adresseLivraison,
                    natureColisId: this.state.natureColis,
                    plagePoidsId: this.state.poids,
                    nombreChariot: this.state.nombreChariot,
                    nombreColis: this.state.nombreColis,
                    isHorsGabarit: this.state.horsGabarit
                }).then(async response => {
                console.log("res demande exp : ",response)
                this.setState({LoadingSubmit : false})
                if (response.data.success) {
                    let FormData = {
                        adresseDepart: this.state.adresseEnleve,
                        adresseArrivee: this.state.adresseLivraison,
                        natureColisId: this.state.natureColis,
                        nombreChariot: this.state.nombreChariot,
                        isHorsGabarit: this.state.horsGabarit,
                        commentaire: this.state.commentaire,
                        nombreColis: this.state.nombreColis,
                        plagePoidsId: this.state.poids,
                        nomDestinataire: this.state.contact_livraison,
                        telephoneDestinataire: this.state.tel_livraison,
                        nomExpediteur: this.state.contact_enlev,
                        telephoneExpediteur: this.state.tel_enlev,
                        montant: response.data.montant,
                        livreurs: response.data.livreurs,
                        commissionLivraison: response.data.commission,
                    }
                    this.props.parentCallback(FormData,true,false);
                }
            }).catch(err=>{
                this.setState({LoadingSubmit : false})
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
            })
        }
    }


    render() {
        return (
                    <Col className={'right-dashboard'}>
                        <Row className="inscri-expediteur">
                            <Col>
                                <h2>Nouvelle Expédition</h2>
                                <hr className={"hr-register hr-dash"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"input-col nouvelle-exp"} xs={12} lg={true}>
                                <h4>Enlèvement</h4>
                                <div className={'div-exped list'} id={'adresseEnleve'}>
                                    <PlacesAutocomplete
                                        value={this.state.adresseEnleve}
                                        onChange={this.handleChange_addresse_enlev}
                                        onSelect={this.handleChange_addresse_enlev}
                                        searchOptions={options}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <i className="fa fa-map-marker-alt icon input-icon expedition"/>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: "Adresse",
                                                        className: 'location-search-input',
                                                    })}
                                                    className="input-register"
                                                    id="adresseEnleve"
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Chargement...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {this.state.adresseEnleveErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                                <div className={'div-exped list'}>
                                    <input
                                        type="text"
                                        name="contact_enlev"
                                        id="contact_enlev"
                                        placeholder={"Nom du contact pour l'enlèvement"}
                                        value={this.state.contact_enlev}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.contact_enlevErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                                <div className={'div-exped list'}>
                                    <input
                                        type="text"
                                        name="tel_enlev"
                                        id="tel_enlev"
                                        placeholder={"N° de téléphone pour l'enlèvement"}
                                        value={this.state.tel_enlev}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.tel_enlevErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                            <Col className={"nouvelle-exp"} xs={12} lg={true}>
                                <h4>Livraison</h4>
                                <div className={'div-exped list'} id={'adresseLivraison'}>
                                    <PlacesAutocomplete
                                        value={this.state.adresseLivraison}
                                        onChange={this.handleChange_addresse_livraison}
                                        onSelect={this.handleChange_addresse_livraison}
                                        searchOptions={options}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <i className="fa fa-map-marker-alt icon input-icon expedition"/>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: "Adresse",
                                                        className: 'location-search-input',
                                                    })}
                                                    className="input-register"
                                                    id="adresseEnleve"
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Chargement...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {this.state.adresseLivraisonErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                                <div className={'div-exped list'}>
                                    <input
                                        type="text"
                                        name="contact_livraison"
                                        id="contact_livraison"
                                        placeholder={"Nom du contact à la livraison"}
                                        value={this.state.contact_livraison}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.contact_livraisonErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                                <div className={'div-exped list'}>
                                    <input
                                        type="text"
                                        name="tel_livraison"
                                        id="tel_livraison"
                                        placeholder={"N° de téléphone à la livraison"}
                                        value={this.state.tel_livraison}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.tel_livraisonErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                        </Row>
                        <hr className={"hr-register hr-dash mobile"}/>
                        <Row>
                            <Col  className={"input-col nouvelle-exp"} xs={12} lg={true}>
                                <h4>Palettes ou chariots</h4>
                                <div className={'div-exped list'}>
                                    <input
                                        type="number"
                                        min="0"
                                        name="nombreChariot"
                                        id="nombreChariot"
                                        // placeholder={"Nom du contact pour l'enlèvement"}
                                        value={this.state.nombreChariot}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.nombreChariotErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                            <Col className={'nouvelle-exp'} xs={12} lg={true}>
                                <h4>Nature du colis</h4>
                                <div className={'div-exped list'}>
                                    <Select value={this.state.natureColis} className={"btn-piece"} id="natureColis"
                                            onChange={this.handleNatureColis}>
                                        <Option value="">Nature du colis</Option>
                                        <Option value="BIT">Bi-température</Option>
                                        <Option value="FRA">Frais</Option>
                                        <Option value="SES">Sec / Standard</Option>
                                        <Option value="SUR">Surgelé</Option>
                                    </Select>
                                    {this.state.natureColisErr === true &&
                                    <span className='error'>Merci de choisir la nature du colis</span>}
                                </div>
                            </Col>
                        </Row>
                        <Row className={'div-select-exp'}>
                            <Col className={"input-col nouvelle-exp"} xs={12} lg={true}>
                                <h4>Nombre de colis</h4>
                                <div className={'div-exped list'}>
                                    <input
                                        type="number"
                                        min="0"
                                        name="nombreColis"
                                        id="nombreColis"
                                        placeholder={"Nombre de colis"}
                                        value={this.state.nombreColis}
                                        onChange={this.onChange}
                                        className="input-register"
                                        required={true}
                                    />
                                    {this.state.nombreColisErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                            <Col className={'nouvelle-exp'} xs={12} lg={true}>
                                <h4>Poids total des colis</h4>
                                <div className={'div-exped list'}>
                                    <Select value={this.state.poids} className={"btn-piece"} id="poids"
                                            onChange={this.handlePoids}>
                                        <Option value="">Poids des colis</Option>
                                        <Option value="1">Entre 0 et 5 kg</Option>
                                        <Option value="2">Entre 6 et 10 kg</Option>
                                        <Option value="3">Entre 11 et 20 kg</Option>
                                        <Option value="4">Entre 21 et 50 kg</Option>
                                        <Option value="5">Entre 51 et 100 kg</Option>
                                        <Option value="6">Entre 101 et 150 kg</Option>
                                        <Option value="7">Entre 151 et 300 kg</Option>
                                        <Option value="8">Entre 301 et 600 kg</Option>
                                        <Option value="9">Entre 601 et 1100 kg</Option>
                                        <Option value="10">Entre 1101 et 2200 kg</Option>
                                    </Select>
                                    {this.state.poidsErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                        </Row>
                        <hr className={"hr-register hr-dash mobile"}/>
                        <Row className={'div-select-exp'}>
                            <Col className={'nouvelle-exp'} xs={12} lg={true}>
                                <h4>Colis hors gabarit</h4>
                                <div className={'div-exped list'}>
                                    <Select value={this.state.horsGabarit} className={"btn-piece"} id="horsGabarit"
                                            onChange={this.handleHorsGabarit}>
                                        <Option value="">Colis hors gabarit</Option>
                                        <Option value="1">Oui (Préciser en commentaire)</Option>
                                        <Option value="0">Non</Option>
                                    </Select>
                                    {this.state.horsGabaritErr === true &&
                                    <span className='error'>Veuillez renseigner ce champ</span>}
                                </div>
                            </Col>
                        </Row>
                        <Row className={'div-select-exp'}>
                            <Col className={'nouvelle-exp'} xs={12} lg={true}>
                                <h4>Commentaire</h4>
                                <div className={'div-exped list'}>
                                    <TextArea name={"commentaire"} id={'commentaire'}
                                              value={this.state.commentaire}
                                              placeholder={"Colis fragile, lourd, besoin " +
                                              "d'un diable, Colis non emballé, etc."}
                                              allowClear className="input-register"
                                              onChange={this.onChange}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="inscri-expediteur">
                            <Col>
                                <Button className={"btn-continue nouvellexp"} onClick={()=>this.onSubmit()}
                                        disabled={this.state.LoadingSubmit}>
                                    {this.state.LoadingSubmit ? <div className="spinner">
                                            <Spinner animation="border" role="status" className={'spinner-btn'}
                                                     variant="primary"/> </div>:
                                        <span>Trouvez maintenant<br/>votre transporteur</span>}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
        );


    }
}

export default NouvelleExpedForm
