import React, {Component} from 'react';
import axios from "axios";
import {Col, Container, Row} from "react-bootstrap";
import '../components/login.css';
import i18n from '../i18n/i18n'
import {BACKEND_API_URL} from "../env";
import ReactCodeInput from 'react-code-input'
import { Input, notification } from 'antd';
const props = {
    borderRadius: "6px",
    border: "1px solid lightgrey",
    boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
    margin: "4px",
    textAlign : "center",
    width: "40px",
    height: "70px",
    fontSize: "32px",
    boxSizing: "border-box",
    color: "black",
    backgroundColor: "white"
  }
class ForgotPassword extends Component {
    constructor(){
        super()
        this.state={
            email: '',
            showError: false,
            showNull: false,
            showErrorCode: false,
            messageFromServer:'',
            loadingLogin : false,
            mailSended :false,
            enterNewPassword: false,
            CodeValidation:"",
            codeDisable : false,
            repeat_password : "",
            password : "", passwordErr:false, passwordRepeatErr : false
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onCodeValide(e) {
        if(e.length === 6){
            this.setState({CodeValidation : e})
            axios.post(`${BACKEND_API_URL}user/verifyCode`, {
                code: e,
              })
                .then(async response => {
                  this.setState({
                    enterNewPassword: true,codeDisable : false
                  });
                })
                .catch(res => {
                  console.log(res.response);
                  this.setState({showErrorCode: true,codeDisable : false});
                });
        }
        }
        openNotification = () => {
            notification['success']({
                message: "Succès",
                description:"Votre mot de passe a été modifié avec succès",
            });
        };
        openNotificationError = () => {
            notification['error']({
                message: 'Erreur',
                description:"Une erreur s'est produite veuillez réessayer",
            });
        };

    SendEmail = e =>{
        if (this.state.email===''){
            this.setState({
                showNull : true
            })
        } else {
            this.setState({showNull : false})
            this.setState({loadingLogin : true})
            axios
                .post(`${BACKEND_API_URL}user/forgetPass`,{
                    email : this.state.email
                    })
                .then(res => {
                    console.log(res.data)
                    this.setState({loadingLogin : false,mailSended : true})
                })
                .catch(err=>{
                    console.log(err.data);
                    this.setState({loadingLogin : false})
                })
        }

    }

    async submit() {
        if (this.state.password.length === 0) {
          this.setState({passwordErr: true});
        } else {
          this.setState({passwordErr: false});
          if (this.state.password === this.state.repeat_password) {
            this.setState({passwordRepeatErr: false, loadingLogin : true});
            axios.post(`${BACKEND_API_URL}user/changePassword`, {
              password: this.state.password,
              code: this.state.CodeValidation,
            })
              .then(async response => {
                this.setState({loadingLogin : false});
                this.openNotification()
                this.props.history.replace('/login');
              })
              .catch(res => {
                this.setState({loadingLogin : false});
                console.log(res.response);
                this.openNotificationError()
              });
          } else {
            this.setState({
                passwordRepeatErr: true,passwordErr: false
            });
          }
        }
      }

    render() {
        const {email,messageFromServer,showNull,showError,showErrorCode} = this.state;
        let width = window.innerWidth;
        if (width > 768){
            return (
                <div style={{
                    padding: "150px",
                    marginRight: "auto",
                    marginLeft: "auto"
                }}>
                    <Container style={{
                        backgroundColor:"white",paddingLeft: "0",
                        paddingRight: "0",
                        borderRadius: "14px" ,boxShadow: "0px 6px 10px #0000001C"
                    }}>
                        <Row noGutters style={{minHeight: "350px"}}>
                            {!this.state.mailSended ? 
                            <Col style={{padding: "30px 50px", margin : "auto"}}>
                            <form noValidate onSubmit={this.SendEmail} style={{width:"400px",textAlign:"center"}}>
                                <h4 className="mb-4 font-weight-normal" style={{color:"#1D1F39"}}>Mot de passe oublié ?</h4>
                                <div className="form-group forget">
                                <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={"Entrez votre adresse e-mail"}
                            value={this.state.Nom}
                            onChange={this.onChange}
                            className="input-register"
                            required={true}
                        />
                                    
                                </div>
                                {
                                    showNull && (
                                        <div className="error">
                                            <span className='error'>Entrez une adresse e-mail valide</span>
                                        </div>
                                    )
                                }

                                {
                                    showError && (
                                        <div>
                                            <p className="error">
                                            L'adresse e-mail n'existe pas, veuillez réessayer encore.
                                            </p>
                                        </div>

                                    )
                                }
                                <div style={{marginTop:"50px"}}>
                                    <button
                                        type="submit"
                                        className="btn-valider"
                                        style={{padding:"7px 50px"}}
                                        disabled={this.state.loadingLogin}>
                                        {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Envoyer"}
                                    </button>
                                </div>
                            </form>
                        </Col> : ( !this.state.enterNewPassword ? 
                        <Col style={{padding: "30px 50px", margin : "auto"}}>
                            <form noValidate onSubmit={this.SendEmail} style={{width:"400px",textAlign:"center"}}>
                                <h4 className="mb-4 font-weight-normal" style={{color:"#1D1F39"}}>Veuillez entrer le code que vous avez reçu par e-mail</h4>
                                <div className="form-group forget">
                        <ReactCodeInput type='number' fields={6} inputStyle = {props} 
                        onChange={e=>this.onCodeValide(e)}/>
                                </div>
                                {
                                    showErrorCode && (
                                        <div className="error">
                                            <span className='error'>Le code n'est pas valide, veuillez redemander un nouveau.</span>
                                        </div>
                                    )
                                }
                                
                            </form>
                        </Col> : 
                        <Col style={{padding: "30px 50px", margin : "auto"}}>
                        <form style={{width:"400px",textAlign:"center"}}>
                            <h4 className="mb-4 font-weight-normal" style={{color:"#1D1F39"}}>Veuillez entrer votre nouveau mot de passe</h4>
                            <div className="form-group forget" style={{marginBottom:"20px"}}>
                            <Input.Password name="password" placeholder="Nouveau mot de passe"
                                            value={this.state.password} className={"input-pass"}
                                            onChange={this.onChange} required={true} />
                                        {this.state.passwordErr === true &&
                                        <span className='error'>Veuillez renseigner ce champ</span>}
                                        </div>
                            <div className="form-group forget" style={{marginBottom:"20px"}}>
                            <Input.Password name="repeat_password" placeholder="Confirmation du mot de passe"
                                            value={this.state.repeat_password} className={"input-pass"}
                                            onChange={this.onChange} required={true} />
                            {this.state.passwordRepeatErr === true &&
                            <span className='error'>Les mots de passe ne correspondent pas. Veuillez réessayer.</span>}
                            </div>
                            <div style={{marginTop:"50px"}}>
                                <button onClick={()=>this.submit()}
                                    type="submit"
                                    className="btn-valider"
                                    style={{padding:"7px 50px"}}
                                    disabled={this.state.loadingLogin}>
                                    {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : "Enregistrer"}
                                </button>
                            </div>

                        </form>

                    </Col>
                        )}
                        </Row>
                    </Container>

                </div>
            )
        }
        else {
            return (
                <div style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlign: "center",minHeight:"66vh"
                }}>
                    <Container style={{padding : "10%"}}>
                        {!this.state.mailSended ? <>
                        <Row noGutters>
                            <Col>
                                <h4 className="mb-3 font-weight-bold" style={{color:"#1D1F39"}}>
                                Mot de passe oublié ?
                                </h4>

                                <div className="form-group forget">
                                <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={"Entrez votre adresse e-mail"}
                            value={this.state.Nom}
                            onChange={this.onChange}
                            className="input-register"
                            required={true}
                        />
                                </div>

                            </Col>
                        </Row>
                        {
                            showNull && (
                                <div className="error">
                                    <span className='error'>Entrez une adresse e-mail valide</span>
                                </div>
                            )
                        }

                        {
                            showError && (
                                <div>
                                    <p className="error">
                                        {i18n.t('131')}
                                    </p>
                                </div>

                            )
                        }
                        <Row style={{justifyContent: "center", marginTop: "30px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{float:"right",padding:"10px 70px"}}
                                onClick={()=>this.SendEmail()}
                                disabled={this.state.loadingLogin}>
                                {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : "Envoyer"}
                            </button>
                        </Row> </> : (!this.state.enterNewPassword ?
                        <>
                        <Row noGutters>
                            <Col>
                                <h4 className="mb-3 font-weight-bold" style={{color:"#1D1F39"}}>
                                Veuillez entrer le code que vous avez reçu par e-mail
                                </h4>

                                <div className="form-group forget">
                                <ReactCodeInput type='number' fields={6} inputStyle = {props} 
                        onChange={e=>this.onCodeValide(e)}/>
                                </div>

                            </Col>
                        </Row>
                        {
                            showErrorCode && (
                            <div className="error">
                                <span className='error'>Le code n'est pas valide, veuillez redemander un nouveau.</span>
                            </div>
                        )}
                        </> : 
                        <>
                        <Row noGutters>
                            <Col>
                                <h4 className="mb-3 font-weight-bold" style={{color:"#1D1F39"}}>
                                Veuillez entrer votre nouveau mot de passe
                                </h4>

                                <div className="form-group forget" style={{marginBottom:"20px"}}>
                            <Input.Password name="password" placeholder="Nouveau mot de passe"
                                            value={this.state.password} className={"input-pass"}
                                            onChange={this.onChange} required={true} />
                                        {this.state.passwordErr === true &&
                                        <span className='error'>Veuillez renseigner ce champ</span>}
                                        </div>
                            <div className="form-group forget" style={{marginBottom:"20px"}}>
                            <Input.Password name="repeat_password" placeholder="Confirmation du mot de passe"
                                            value={this.state.repeat_password} className={"input-pass"}
                                            onChange={this.onChange} required={true} />
                            {this.state.passwordRepeatErr === true &&
                            <span className='error'>Les mots de passe ne correspondent pas. Veuillez réessayer.</span>}
                            </div>
                            </Col>
                        </Row>
                        
                        <Row style={{justifyContent: "center", marginTop: "30px"}}>
                            <button
                                type="submit"
                                className="btn-valider"
                                style={{float:"right",padding:"10px 70px"}}
                                onClick={()=>this.submit()}
                                disabled={this.state.loadingLogin}>
                                {this.state.loadingLogin ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : "Enregistrer"}
                            </button>
                        </Row> </>
                        )
                        
                        }



                    </Container>

                </div>
            )
        }

    }
}

export default ForgotPassword;
