import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './VerticalNavbar2.css'
import {NavLink} from "react-router-dom";
import axios from "axios";


class VerticalNavbar2 extends Component {
    constructor(){
        super()
        this.state = {
            notifCount : 0,
            DisplayNotif : false
        }
    }
    componentDidMount() {
        axios.get('/api/notifications/count')
            .then((res) => {
                console.log(res.data)
                this.setState({notifCount : res.data})

            }).catch((res)=>{
            console.log(res.data)
        })
    }

    render() {

        return (
            <Container className="vericalNav">
                <Row>
                       <NavLink className="columnBack" style={{
                           borderBottom:"1px solid rgba(0,0,0,.1)", width:"100%"}}
                                to="/mon-compte" exact activeClassName="selected selected1">
                           <h5>Mon compte</h5>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"1px solid rgba(0,0,0,.1)", width:"100%"}}
                                to="/mes-expeditions" activeClassName="selected">
                           <h5>Mes expéditions</h5>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"1px solid rgba(0,0,0,.1)", width:"100%"}}
                                to="/nouvelle-expedition" activeClassName="selected">
                           <h5>Nouvelle expédition</h5>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack"
                                to="/mes-paiements" activeClassName="selected selected3">
                           <h5>Mes paiements</h5>
                       </NavLink>
                </Row>
            </Container>
        );
    }
}

export default VerticalNavbar2;
