import axios from "axios";
import {BACKEND_API_URL} from "../env";

export function GetPlagePoids() {
    axios.get(`${BACKEND_API_URL}params/plagePoids`).then(res => {
        return(res.data);
    }).catch(err => {
        console.log(err)
    return []});
}

export function GetNatureColis() {
    axios.get(`${BACKEND_API_URL}params/natureColis`).then(res => {
        console.log(res)
        return(res.data);
    }).catch(err => console.log(err));
}
