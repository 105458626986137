import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '../src/i18n/i18n';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import 'moment/locale/fr';
//*************************** Redux  ************************************
import {createStore} from "redux";
import allReducers from "./redux/Reducers";
import {Provider} from "react-redux";
import axios from "axios";


const store = createStore(
    allReducers, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

axios.get('/api/notifications/count')
    .then((res) => {
        console.log(res.data)
        window.NotifNumber = res.data

    }).catch((res)=>{
    console.log(res.data)
})

axios.get('/api/users/profile/validation-piece')
    .then((res) => {
        console.log("piece state " + res.data.validation_piece);

        window.pieceValidation = !(res.data.validation_piece === 'true');
        //  pieceValidation ==== false c-a-d que la piece n'est pas validée
        console.log("window data " + window.pieceValidation);

    }).catch((res)=>{
    console.log(res.data)
})



ReactDOM.render(
    <ConfigProvider locale={frFR}>
        <App />
    </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
