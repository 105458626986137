const VoteReducer = (state = false , action) => {
    switch (action.type) {
        case 'ENABLE_VOTE' :
            return state= true;
        case 'DISABLE_VOTE' :
            return state= false;
        default :
            return state
    }
}
export default VoteReducer;
