import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {Input, notification, Rate, Select} from "antd";
import axios from "axios";
import enlevement from '../Images/pick_site/enlevement.png'
import livraison from '../Images/pick_site/destination.png'
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";
import jwt from "jsonwebtoken";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import {FormatDate, FormatDateNoTime} from "../Helpers";
import {Link} from "react-router-dom";
import {saveAs} from "file-saver";
import {GenerateFactureNumber} from "../Helpers/Facture";
import {AiOutlineCheckCircle} from "react-icons/all";
import {StarRating} from "../components/StarRating";
import Button from "antd/es/button";

const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);
const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
const { TextArea } = Input;


class ColisArrive extends Component {
    constructor() {
        super()
        this.state = {
            Demande : "",
            LoadingSubmit: false,avis : "",
            userId:'', errorAvis:false,
            rating:3
        }
        this.onChange = this.onChange.bind(this)


    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({Demande : this.props.demande})
        this.GetInfoUser()
    }

    async GetInfoUser() {
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        this.setState({userId : decodedToken.userId})
    }


    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})
    }

    async onSubmit() {
        console.log(this.state.rating)
        if(this.state.avis === ""){
            this.setState({errorAvis : true})
            document.getElementById('avis').scrollIntoView()
        }else {
            this.setState({LoadingSubmit : true})
            axios
                .post(`${BACKEND_API_URL}expediteur/validationClient`, {
                    livraisonId: this.state.Demande.livraison.livraisonId,
                    note: this.state.rating,
                    avis: this.state.avis
                })
                .then(response => {
                    this.setState({LoadingSubmit : false})
                    if (response.data.success) {
                        notification['success']({
                            message: 'Note enregistrée avec succès, merci et à bientôt !',
                            description:"",
                            duration: 6,
                        });
                        this.props.parentCallback()
                    }
                })
                .catch((err)=>{
                    console.log(err.response.data)
                    this.setState({LoadingSubmit : false})
                })

        }
    }

    render() {
        return (
            <>
                <Row className="inscri-expediteur">
                    <Col>
                        <h2><AiOutlineCheckCircle className={"valide-colis"}/>
                        Votre marchandise a été livrée</h2>
                        <hr className={"hr-register hr-dash"}/>
                    </Col>
                </Row>
                {this.state.Demande ?
                    <>
                        <Row className={"photo-livraison"}>
                            <Col style={{textAlign : "center"}}>
                                <div className={"title-pic"}>
                                    <p>Photo à l’enlèvement</p>
                                </div>
                                <div className={"colis"}>
                                    <img src={BACKEND_ROOT_URL + this.state.Demande.commande.enlevement.photo}/>
                                </div>
                                <div className={"title-pic"}>
                                    <p>Signature expéditeur</p>
                                </div>
                                <div className={"colis-signature"}>
                                    <img src={BACKEND_ROOT_URL + this.state.Demande.commande.enlevement.signature}/>
                                </div>
                            </Col>
                            <Col style={{textAlign : "center"}}>
                                <div className={"title-pic"}>
                                    <p>Photo à la livraison</p>
                                </div>
                                <div className={"colis"}>
                                    <img src={BACKEND_ROOT_URL + this.state.Demande.commande.destination.photo}/>
                                </div>
                                <div className={"title-pic"}>
                                    <p>Signature destinataire</p>
                                </div>
                                <div className={"colis-signature"}>
                                    <img src={BACKEND_ROOT_URL + this.state.Demande.commande.destination.signature}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"comment"}>
                            <Col>
                                <span className={"title-comment"}>Commentaire expéditeur : </span>
                                <span className={"comment-content"}>{this.state.Demande.commande.commentaire}</span>
                            </Col>
                        </Row>
                        <Row className={"comment"}>
                            <Col>
                                <span className={"title-comment"}>Commentaire enlèvement : </span>
                                <span className={"comment-content"}>{this.state.Demande.commande.enlevement.commentaire}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className={"title-comment"}>Commentaire livraison : </span>
                                <span className={"comment-content"}>{this.state.Demande.commande.destination
                                    .commentaire}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign : "center"}}>
                                <hr className={"hr-register hr-dash top"}/>
                                <h2 style={{color: "#1D1F39"}}>Notez la prestation</h2>
                                <div className="star-rating">
                                    {[...Array(5)].map((star, index) => {
                                        index += 1;
                                        return (
                                            <button
                                                type="button"
                                                key={index}
                                                onClick={() => this.setState({rating : index})}
                                            >{index <= this.state.rating ?
                                                <img className={"rate"}
                                                     src={require('../Images/pick_site/volant-marron.png')}/> :
                                                <img className={"rate"} src={require('../Images/pick_site/volant-gris.png')}/>
                                            }
                                            </button>
                                        );
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"title-pic"}>
                                <p>Votre avis</p>
                                <TextArea rows={4} onChange={this.onChange} name={"avis"} id={"avis"}/>
                                {this.state.errorAvis === true &&
                                <span className='error'>Vous devez ajouter un avis !</span>}
                                <div style={{textAlign : "center"}}>
                                    <Button onClick={()=>{this.onSubmit()}} disabled={this.state.LoadingSubmit}
                                            className={"btn-inscr"}> {this.state.LoadingSubmit ?
                                        <div className="spinner">
                                            <Spinner animation="border" role="status" className={'spinner-btn white'}
                                                     variant="primary"/> </div>:
                                        <>Validez la note</>}
                                    </Button>
                                    <hr className={"hr-register hr-dash top"}/>
                                    <Button className={"btn-continue nouvellexp"} onClick={()=>{
                                        window.location.replace("/nouvelle-expedition")
                                    }}
                                    >Nouvelle expédition</Button>
                                </div>
                            </Col>
                        </Row>
                    </> :
                    <div className="spinner-center" style={{left: "50vw"}}><Spinner animation="border" role="status"
                                                                                    variant="primary"/>
                    </div>
                }


            </>

        );


    }
}

export default ColisArrive
