import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {IsLoggedIn, openNotificationError} from "../Helpers";
import {Modal, Dropdown, Menu, notification, Tag, Select, Avatar, Input} from "antd";
import Button from "antd/es/button";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import PlacesAutocomplete from "react-places-autocomplete";
import {BACKEND_API_URL} from "../env";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {GetNatureColis, GetPlagePoids} from "../Services";

const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);
const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
const { TextArea } = Input;


class RecapCommande extends Component {
    constructor() {
        super()
        this.state = {
            nomExpediteur:'',
            telephoneExpediteur:'',
            adresseDepart:'',
            nomDestinataire:'',
            telephoneDestinataire:'',
            adresseArrivee:'',
            nombreColis:'',
            isHorsGabarit:'',
            nombreChariot:'',
            natureColisId:'',
            montant:'',
            plagePoidsId:'',
            commentaire :'',
            PlagePoids:{},
            poidsText:'',
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            nomExpediteur:this.props.ExpeditionData.nomExpediteur,
            telephoneExpediteur:this.props.ExpeditionData.telephoneExpediteur,
            adresseDepart:this.props.ExpeditionData.adresseDepart,
            nomDestinataire:this.props.ExpeditionData.nomDestinataire,
            telephoneDestinataire:this.props.ExpeditionData.telephoneDestinataire,
            adresseArrivee:this.props.ExpeditionData.adresseArrivee,
            nombreColis:this.props.ExpeditionData.nombreColis,
            isHorsGabarit:this.props.ExpeditionData.isHorsGabarit,
            nombreChariot:this.props.ExpeditionData.nombreChariot,
            natureColisId:this.props.ExpeditionData.natureColisId,
            montant:this.props.ExpeditionData.montant,
            plagePoidsId:this.props.ExpeditionData.plagePoidsId,
            commentaire :this.props.ExpeditionData.commentaire,
        })
        this.F_DisplayPlagePoids()
        this.F_DisplayNatureColis()
    }

    async F_DisplayPlagePoids() {
        await axios.get(`${BACKEND_API_URL}params/plagePoids`).then(res => {
            if(res.data.length > 0){
                console.log(this.state.plagePoidsId,' rrrrrr ', res.data)
                let temp = 'De ' + res.data.find(elem => elem.id === Number(this.state.plagePoidsId)).poidsMin
                    + ' à ' + res.data.find(elem => elem.id === Number(this.state.plagePoidsId)).poidsMax + ' kg'
                this.setState({poidsText : temp})
            } else {
                this.setState({poidsText : ''})
            }
        }).catch(err => {
            console.log(err)
            return []});

    }
    async F_DisplayNatureColis() {
        await axios.get(`${BACKEND_API_URL}params/natureColis`).then(res => {
            if (res.data.length > 0) {
                let temp = res.data.find(elem => elem.id === this.state.natureColisId)
                    .libelle
                this.setState({NatureText : temp})
            } else {
                this.setState({NatureText : ''})
            }
        }).catch(err => console.log(err));
    }

    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})

    }


    async onSubmit() {
        let FormData = {
            adresseDepart: this.state.adresseDepart,
            adresseArrivee: this.state.adresseArrivee,
            natureColisId: this.state.natureColisId,
            nombreChariot: this.state.nombreChariot,
            isHorsGabarit: this.state.isHorsGabarit,
            commentaire: this.state.commentaire,
            nombreColis: this.state.nombreColis,
            plagePoidsId: this.state.plagePoidsId,
            nomDestinataire: this.state.nomDestinataire,
            telephoneDestinataire: this.state.telephoneDestinataire,
            nomExpediteur: this.state.nomExpediteur,
            telephoneExpediteur: this.state.telephoneExpediteur,
            montant: this.state.montant,
            livreurs: this.props.ExpeditionData.livreurs,
            commissionLivraison: this.props.ExpeditionData.commissionLivraison,
            // userId: this.state.user.userId
        }
        this.props.parentCallback(FormData,true,false);
    }
    async ReturnToForm() {
        let FormData = {
            adresseDepart: this.state.adresseDepart,
            adresseArrivee: this.state.adresseArrivee,
            natureColisId: this.state.natureColisId,
            nombreChariot: this.state.nombreChariot,
            isHorsGabarit: this.state.isHorsGabarit,
            commentaire: this.state.commentaire,
            nombreColis: this.state.nombreColis,
            plagePoidsId: this.state.plagePoidsId,
            nomDestinataire: this.state.nomDestinataire,
            telephoneDestinataire: this.state.telephoneDestinataire,
            nomExpediteur: this.state.nomExpediteur,
            telephoneExpediteur: this.state.telephoneExpediteur,
            montant: this.state.montant,
            // userId: this.state.user.userId
        }
        this.props.parentCallback(FormData,false,true);
    }


    render() {
        return (
                    <Col className={'right-dashboard'}>
                        <Row className="inscri-expediteur">
                            <Col>
                                {/*<h2>Nouvelle Expédition</h2>*/}
                                {/*<hr className={"hr-register hr-dash"}/>*/}
                            </Col>
                        </Row>
                        <Row className="inscri-expediteur">
                            <Col>
                                <div className={'recap'}>
                                    <h4>Adresse d'enlèvement</h4>
                                    <p className={'recap-p'}>{this.state.nomExpediteur}</p>
                                    <p className={'recap-p'}>{this.state.telephoneExpediteur}</p>
                                    <p className={'recap-p'}>{this.state.adresseDepart}</p>
                                </div>
                                <DownOutlined className={'downIcon'} />
                                <div className={'recap'}>
                                    <h4>Adresse de livraison</h4>
                                    <p className={'recap-p'}>{this.state.nomDestinataire}</p>
                                    <p className={'recap-p'}>{this.state.telephoneDestinataire}</p>
                                    <p className={'recap-p'}>{this.state.adresseArrivee}</p>
                                </div>
                                <hr className={"hr-register hr-dash"}/>
                                <div className={'recap after'}>
                                    <h4>Palettes ou chariots</h4>
                                    <p className={'recap-p'}>{this.state.nombreChariot}</p>
                                </div>
                                <div className={'recap after'}>
                                    <h4>Nature du colis</h4>
                                    <p className={'recap-p'}>{this.state.NatureText}</p>
                                </div>
                                <div className={'recap after'}>
                                    <h4>Total de colis</h4>
                                    <p className={'recap-p'}>{this.state.nombreColis}</p>
                                </div>
                                <div className={'recap after'}>
                                    <h4>Poids total des colis</h4>
                                    <p className={'recap-p'}>{this.state.poidsText}</p>
                                </div>
                                <div className={'recap after'}>
                                    <h4>Colis hors gabarit</h4>
                                    <p className={'recap-p'}>{this.state.isHorsGabarit}</p>
                                </div>
                                <div className={'recap after'}>
                                    <h4>Commentaires</h4>
                                    <p className={'recap-p'}>{this.state.commentaire}</p>
                                </div>
                                <hr className={"hr-register hr-dash"}/>
                                <div className={'recap after tarif'}>
                                    <h4>Tarif</h4>
                                    <p className={'recap-p'}>{Number(this.state.montant).toFixed(2)
                                        .toString().replace(".", ",")} € TTC</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="inscri-expediteur">
                            <Col>
                                <div>
                                    <Button type={'link'} className={'recap'} onClick={()=>this.ReturnToForm()}>
                                        <span>Modifier la demande</span>
                                    </Button>
                                </div>
                                <Button className={"btn-continue nouvellexp recap"} onClick={()=>this.onSubmit()}>
                                        <span>Payez</span>
                                </Button>

                            </Col>
                        </Row>
                    </Col>
        );


    }
}

export default RecapCommande
