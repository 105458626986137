import React, {Component} from 'react'
import {Col, Row} from "react-bootstrap";
import '../components/profile.css'
import axios from "axios";
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";
import jwt from "jsonwebtoken";
import {FormatDateTime} from "../Helpers";
import {Button} from "antd";


let interval = null

class Messages extends Component {
    constructor() {
        super()
        this.state = {
            CommandesSansLivreur: [],
            LoadingSubmit: false,
            userId:'',ListeCommandes: [],
            CommandeId : '',Commande:'',
            Livreurs :'', SearchDeliver : false,
            DepartMarkers : {},
            V_LivreurPosition :{},
            ArriveMarkers : {},
            TempsApproche :0,
            region: {
                lat: 31.776685,
                lng: 35.234491,
            },
            maprops:'',Demande : '',
            mapref:'',livrState:'',directionsRenderer:'',
            detailvisibility : false,
            CurrentMessage: "",allMessages:[],personneId:'',
            user : null,
            Nomclient:""
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            CommandeId:this.props.commandeId
        })
        console.log('detail commande msg: ',this.props.commandeId)
        this.GetUserInfo()
    }

    async GetUserInfo(){
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        console.log(decodedToken)
        this.setState({userId : decodedToken.userId,
            personneId : decodedToken.personneId})
        axios.post(`${BACKEND_API_URL}expediteur/getInfoUser`, {
            utilisateurId: this.state.userId,
        }).then(res => {
            console.log(res)
            if(res.data.success){
                if(res.data.user.personne.personnephysique){
                    this.setState({Nomclient : res.data.user.personne.personnephysique.prenoms + " "+
                            res.data.user.personne.personnephysique.nom})
                }
                console.log('user Info : ', res.data);
                this.setState({user :res.data.user})
                this.GetDetailsRunnigCommande()
            }


        }).catch(err => console.log(err));
    }
    componentWillUnmount() {
        if(interval)
            clearInterval(interval)
    }

    async GetDetailsRunnigCommande() {

        axios.post(`${BACKEND_API_URL}expediteur/getCommandeRunning`, {
            utilisateurId: this.state.userId,
            commandeId : this.state.CommandeId
        }).then(res => {
            console.log('runing : ', res.data.demande[0]);
            this.setState({Demande :res.data.demande[0]})
            this.OrderMessages()
            interval = setInterval(() => this.OrderMessages(), 10000)
        }).catch(err => console.log(err));
    }

    SendMessage = async (message) => {
        console.log('message a envoyé : ',message)
        if (message !== "") {
            await axios.post(`${BACKEND_API_URL}transporteur/setMessage`, {
                EmetteurPersonneId: this.state.personneId,
                RecepteurPersonneId : this.state.Demande.transporteur.personneId,
                message : message,
                commandeId : this.state.Demande.commandeId
            }).then(async res => {
                this.OrderMessages()
            }).catch(err => console.log(err));
            this.setState({CurrentMessage: ""});
        }
    }

    async OrderMessages(){
        console.log(this.state.Demande.commandeId)
        await axios.post(`${BACKEND_API_URL}transporteur/getMessage`, {
            EmetteurPersonneId: this.state.personneId,
            RecepteurPersonneId : this.state.Demande.transporteur.personneId,
            commandeId : this.state.Demande.commandeId
        }).then(async res => {
            console.log(res.data.length)
            if(res.data.length !== this.state.allMessages.length)
            {
                console.log('different ',res.data)
                this.setState({allMessages : res.data})
                let element = document.getElementById("history-msg");
                element.scrollTop = element.scrollHeight;
            }
            console.log(res.data)
        }).catch(err => console.log(err));
    }
    F_OnShowMessages = () => {
        console.log("before display")
        if(this.state.allMessages.length>0){
            return this.state.allMessages.map((msg, index) => {
                if (msg.EmetteurPersonneId === this.state.personneId) {
                    console.log("mess", msg)
                    return (
                        <>
                        <Row className={"name"}>
                            <Col lg={8} xs={4}/>
                            <Col lg={3} xs={5}>
                                <p className={"msg-name transp"}>
                                {this.state.Nomclient}
                                </p>
                            </Col>
                            <Col lg={1} xs={3}>
                                <img style={{width: 50, height: 50, borderRadius: 50}}
                                    src={(this.state.Demande.transporteur.personne.personne.photo != null)
                                        ? BACKEND_ROOT_URL + this.state.Demande.transporteur.personne.personne.photo
                                        : require('../Images/pick_site/user.png')}
                                />
                            </Col>
                        </Row>
                        <Row className={"msgRow"}>
                            <Col lg={6} xs={2}/>
                            <Col lg={6} xs={10} className={"msg-sended"}>
                                <p className={"txt-msg"}>{msg.Message}</p>
                                <p className={"date-msg"}>{FormatDateTime(msg.createdAt)}</p>
                            </Col>
                        </Row>
                            </>
                        
                    );
                } else {
                    return (
                        <>
                            <Row className={"name"}>
                                <Col lg={1} xs={3}>
                                    <img
                                        style={{
                                            width: 50,
                                            height: 50,borderRadius: 50
                                        }}
                                        src = {( this.state.user.personne.photo != null)
                                            ?  BACKEND_ROOT_URL +this.state.user.personne.photo
                                            : require('../Images/pick_site/user.png')}
                                    />
                                </Col>
                                <Col lg={3} xs={"auto"}>
                                    <p className={"msg-name"}>{this.state.Demande.transporteur.personne.prenoms + " "
                                    + this.state.Demande.transporteur.personne.nom}
                                    </p>
                                </Col>
                            </Row>
                            <Row className={"msgRow"}>
                                <Col lg={6} xs={10} className={"msg-sended"}>
                                    <p className={"txt-msg"}>{msg.Message}</p>
                                    <p className={"date-msg"}>{FormatDateTime(msg.createdAt)}</p>
                                </Col>
                            </Row>
                            </>
                    );
                }
            });
        }
    }

    render() {

        return (
            <Col className={'right-dashboard map'}>
                <Row className={this.state.allMessages.length>0 ? "history-msg" : "empty-history"} id={"history-msg"}>
                    <Col>
                    {this.F_OnShowMessages()}
                    </Col>
                </Row>
                <Row className={"send-msg-btn"}>
                    <hr className={"hr-msg"}/>
                    <div className={'div-exped'}>
                        <input
                            type="text"
                            name="message"
                            id="message"
                            placeholder={"Message"}
                            value={this.state.CurrentMessage}
                            onChange={(e)=>{
                                this.setState({CurrentMessage : e.target.value})
                            }}
                            className="input-register"
                        />
                        <Button onClick={()=>this.SendMessage(this.state.CurrentMessage)}
                                className={"envoyer-msg-map message"}>
                            <img src={require('../Images/pick_site/phone.png')}/> Envoyer un message
                        </Button>
                    </div>
                </Row>
            </Col>
        );
    }
}
export default Messages

