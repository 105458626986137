import React, {Component, useMemo} from 'react'
import {checkFileSize, checkMimeType, checkPDFType, maxSelectFile} from "../Controls/FilesErrors";
import axios from "axios";
import {Col, Container, Spinner} from "react-bootstrap";
import {FaAngleRight, AiOutlinePlus,IoIosCloseCircleOutline} from "react-icons/all";

import {Checkbox, Select} from 'antd';
import 'react-phone-number-input/style.css';
import Row from "react-bootstrap/Row";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import Button from "antd/es/button";
import {BACKEND_API_URL} from "../env";
import {openNotificationError} from "../Helpers";
import {Link} from "react-router-dom";

let options = {
    componentRestrictions: {country: "fr"}
}
const { Option } = Select;


class Register_expedi_entr extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorPays : false,
            errorPhone : false,
            ErrorAge : false,
            denominationErr : false,
            adresseSiegeErr : false,
            adresseFacturErr : false,
            represenatnt_legalErr : false,
            statutErr : false,
            nom_a_contacterErr : false,
            telephoneErr : false,
            emailErr : false,
            passwordErr : false,
            activiteErr : false,
            siretErr : false,
            tvaErr : false,
            contactErr : false,
            LoadingSubmit: false,
            password_confirmErr: false,
            adresseEnleveErr: false,
            password_confirmErrMsg: '',
            errors: {
                piece: '',
            },
            kbis : "",
            rib : "",
            adresseSiege : "",
            adresseFactur: "",
            denomination:"",
            represenatnt_legal : "",
            statut:"",
            nom_a_contacter:"",
            telephone:"",
            email :"",
            password: "",
            activite:"",
            siret:"", adresseEnleve : "",
            tva:"", password_confirm:"",
            checkedErr : false, checked:false,

        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.PieceOnChange = this.PieceOnChange.bind(this);
        this.onChangeDateChange = this.onChangeDateChange.bind(this);
    }

    validateForm(){
        let error = false
        if(this.state.denomination === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({denominationErr: true})
            error = true
            document.getElementById('denomination').scrollIntoView()
            return error
        }else{
            this.setState({denominationErr: false})
            error = false
        }
        if(this.state.activite === ''){
            console.log('Merci de choisir une activité')
            this.setState({activiteErr: true})
            error = true
            document.getElementById('activite').scrollIntoView()
            return error
        }
        else{
            this.setState({activiteErr: false})
            error = false
        }
        if(this.state.represenatnt_legal === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({represenatnt_legalErr: true})
            error = true
            document.getElementById('represenatnt_legal').scrollIntoView()
            return error
        }
        else{
            this.setState({represenatnt_legalErr: false})
            error = false
        }
        if(this.state.statut === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({statutErr: true})
            error = true
            document.getElementById('statut').scrollIntoView()
            return error
        }
        else{
            this.setState({statutErr: false})
            error = false
        }
        if(this.state.siret === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({siretErr: true})
            error = true
            document.getElementById('siret').scrollIntoView()
            return error
        }
        else{
            this.setState({siretErr: false})
            error = false
        }
        if(this.state.tva === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({tvaErr: true})
            error = true
            document.getElementById('tva').scrollIntoView()
            return error
        }
        else{
            this.setState({tvaErr: false})
            error = false
        }
        if(this.state.adresseSiege === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseSiegeErr: true})
            error = true
            document.getElementById('adresseSiege').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseSiegeErr: false})
            error = false
        }
        if(this.state.adresseFactur === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseFacturErr: true})
            error = true
            document.getElementById('adresseFactur').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseFacturErr: false})
            error = false
        }
        if(this.state.adresseEnleve === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseEnleveErr: true})
            error = true
            document.getElementById('adresseEnleve').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseEnleveErr: false})
            error = false
        }
        if(this.state.nom_a_contacter === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({nom_a_contacterErr: true})
            error = true
            document.getElementById('nom_a_contacter').scrollIntoView()
            return error
        }
        else{
            this.setState({nom_a_contacterErr: false})
            error = false
        }
        if(!validPhoneRegex.test(this.state.telephone.trim()) ){
            console.log('Veuillez remplir ce champ')
            this.setState({telephoneErr: true})
            error = true
            document.getElementById('telephone').scrollIntoView()
            return error
        }
        else{
            this.setState({telephoneErr: false})
            error = false
        }
        if(!validEmailRegex.test(this.state.email.trim())){
            console.log(validEmailRegex.test(this.state.email.trim()))
            this.setState({emailErr: true})
            error = true
            document.getElementById('email').scrollIntoView()
            return error
        }
        else{
            this.setState({emailErr: false})
            error = false
        }
        if(this.state.password === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({passwordErr: true})
            error = true
            document.getElementById('password').scrollIntoView()
            return error
        }
        else{
            this.setState({passwordErr: false})
            error = false
        }
        if(this.state.password_confirm === ''){
            this.setState({password_confirmErr: true,
                password_confirmErrMsg : 'Veuillez remplir ce champ'})
            error = true
            document.getElementById('password_confirm').scrollIntoView()
            return error
        }
        else{
            if(this.state.password_confirm === this.state.password){
                this.setState({password_confirmErr: false})
                error = false
            }
            else {
                this.setState({password_confirmErr: true,
                    password_confirmErrMsg : 'Les mots de passe ne correspondent pas'})
                error = true
                document.getElementById('password_confirm').scrollIntoView()
                return error
            }
        }
        if(!this.state.checked){
            this.setState({checkedErr: true})
            error = true
            document.getElementById('checked').scrollIntoView()
            return error
        }
        else{
            this.setState({checkedErr: false})
            error = false
        }

        return error
    }

    onChangecheckbox(e){
        this.setState({checked : e.target.checked})
    }

    deleteFile(v){
        let errors = this.state.errors;
        errors.piece =""
        if(v==="kbis"){
            this.setState({kbis : ""})
        }
        else {
            this.setState({rib : ""})
        }
    }
    onChange(e) {
        //this.setState({ [e.target.name]: e.target.value })

        const { name, value } = e.target;
        let errors = this.state.errors;

        this.setState({errors, [name]: value})
    }

    onChangeDateChange(date, dateString) {
        this.setState({date_naissance : dateString})
    }

    /**
     * checking and saving piece in state
     * @param event
     * @constructor
     */
    PieceOnChange= event =>{
        // Adding File to State
        let errors = this.state.errors;
        if(checkFileSize(event) ){
            if (maxSelectFile(event)){
                if (checkPDFType(event) ){
                    switch (event.target.name) {
                        case "kbis" :
                            this.setState({kbis : event.target.files[0]})
                            break;
                        case "rib" :
                            this.setState({rib : event.target.files[0]})
                            break
                    }
                    errors.piece=""
                }
                else{
                    errors.piece = "Formats acceptés : PNG, JPG et PDF"

                }

            }
            else{
                errors.piece = "Vous devez selectionner une seule piece d'identité"

            }
        }
        else{
            errors.piece = "le document doit etre inferieure à 2 Mb"
        }
        this.setState({errors})
    }


    handleChange = adresse => {
        console.log('onchange',adresse)
        this.setState({ adresseSiege : adresse });

    };

    handleSelect = address => {
        console.log('on select : ',address)
        this.setState({ adresseSiege : address });
    };

    handleChange_add_facturation = adresse => {
        this.setState({ adresseFactur : adresse });
    };

    handleSelect_add_facturation = address => {
        this.setState({ adresseFactur : address });
    };
    handleChange_add_enlev = adresse => {
        this.setState({ adresseEnleve : adresse });
    };

    handleSelect_add_enlev = address => {
        this.setState({ adresseEnleve : address });
    };

    handleSecteurActivi = value => {
        this.setState({ activite : value });
    }
    handleStatut = value => {
        this.setState({ statut : value });
    }
    async onSubmit() {

        let valid = await this.validateForm()
        console.log("etat : ",valid)
        if(valid === false){
            this.setState({LoadingSubmit : true})
            await axios.post(`${BACKEND_API_URL}expediteur/entreprise/registration`, {
                adresseSociale: this.state.adresseSiege,
                adresseFacturation: this.state.adresseFactur,
                adresseEtab: this.state.adresseEnleve,
                personneContact: this.state.nom_a_contacter,
                telephone: this.state.telephone,
                email: this.state.email.trim(),
                password: this.state.password,
                statutEntreprise: this.state.statut,
                denomination: this.state.denomination,
                siret: this.state.siret,
                tva: this.state.tva,
                secteurs: this.state.activite,
                nomRepresentant: this.state.represenatnt_legal
            }).then(async response => {
                console.log(response)
                this.setState({LoadingSubmit : false})
                if(!response.data.success){
                    if(response.data.message === 'Email déjà utilisé'){
                        openNotificationError('Adresse e-mail est déjà utilisée','')
                    }else
                        openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
                }else {
                    window.location.replace("/success_register");
                }

            }).catch(err=>{
                this.setState({LoadingSubmit : false})
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
            })
        }
    }



    render() {
        const {errors} = this.state;
            return (
                <Container style={{maxWidth: "90vw", marginBottom : "70px"}}>
                    <Row className="inscri-expediteur">
                        <Col>
                            <h2>Entreprise</h2>
                            <hr className={"hr-register"}/>
                        </Col>
                    </Row>
                    <Row className={"create-account"}>
                        <Col>
                        <h3>Création du compte</h3>
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="denomination"
                                id="denomination"
                                placeholder={"Dénomination sociale"}
                                value={this.state.denomination}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.denominationErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col >
                            <Select defaultValue="" className={"btn-piece"} id="statut"
                                    onChange={this.handleStatut}>
                                <Option value="">Statut de l'entreprise</Option>
                                <Option value="SAS">SAS</Option>
                                <Option value="SA">SA</Option>
                                <Option value="SARL">SARL</Option>
                                <Option value="EURL">EURL</Option>
                                <Option value="EI">EI</Option>
                                <Option value="EIRL">EIRL</Option>
                                <Option value="SNC">SNC</Option>
                                <Option value="SCOP">SCOP</Option>
                                <Option value="SCA">SCA</Option>
                                <Option value="SCS">SCS</Option>
                                <Option value="SCI">SCI</Option>
                                <Option value="ASSOCIATION">ASSOCIATION</Option>
                            </Select>
                            {this.state.statutErr === true &&
                            <span className='error'>Merci de choisir un statut</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="siret"
                                id="siret"
                                placeholder={"N° de SIRET"}
                                value={this.state.siret}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.siretErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                         <Col >
                            <Select defaultValue="" className={"btn-piece"} id="activite"
                                    onChange={this.handleSecteurActivi}>
                                <Option value="">Secteur d’activités</Option>
                                <Option value="ECOM">E-commerce</Option>
                                <Option value="EVEN">Evénementiel</Option>
                                <Option value="FLOR">Floral</Option>
                                <Option value="FOBU">Fourniture de bureau</Option>
                                <Option value="FOMO">Fourniture de mobilier</Option>
                                <Option value="FOPA">Fourniture de pièce auto</Option>
                                <Option value="GRDI">Grande distribution</Option>
                                <Option value="INDU">Industriel</Option>
                                <Option value="INEL">Informatique/électroménager</Option>
                                <Option value="LOGI">Logistique</Option>
                                <Option value="PAFI">Pâtisserie fine</Option>
                                <Option value="PHME">Pharmaceutique/médical</Option>
                                <Option value="SOTD">Sous température dirigé</Option>
                                <Option value="Taxi">Taxi</Option>
                                <Option value="TRAI">Traiteur</Option>
                                <Option value="TRDK">Transporteur du dernier kilomètre</Option>
                                <Option value="TRFR">Transport fret</Option>
                                <Option value="VTC">VTC</Option>
                            </Select>
                            {this.state.activiteErr === true &&
                            <span className='error'>Merci de choisir une activité</span>}
                        </Col>             
                        </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="represenatnt_legal"
                                id="represenatnt_legal"
                                placeholder={"Nom du représentant légal"}
                                value={this.state.represenatnt_legal}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.represenatnt_legalErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="tva"
                                id="tva"
                                placeholder={"N° de TVA"}
                                value={this.state.tva}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.tvaErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <PlacesAutocomplete
                                value={this.state.adresseSiege}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse du siège social',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseSiege"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseSiegeErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <PlacesAutocomplete
                                value={this.state.adresseFactur}
                                onChange={this.handleChange_add_facturation}
                                onSelect={this.handleSelect_add_facturation}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse de facturation',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseFactur"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseFacturErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>

                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <PlacesAutocomplete
                                value={this.state.adresseEnleve}
                                onChange={this.handleChange_add_enlev}
                                onSelect={this.handleSelect_add_enlev}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: "Adresse de l'établissement d'enlèvement",
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseEnleve"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseEnleveErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="nom_a_contacter"
                                id="nom_a_contacter"
                                placeholder={"Nom de la personne à contacter"}
                                value={this.state.nom_a_contacter}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.nom_a_contacterErr  === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>

                    <hr className={"hr-register"}/>
                    <Row className={"create-account"}>
                        <Col>
                            <h3>Document à télécharger</h3>
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <p>Extrait de KBIS (moins de 6 mois)</p>
                        </Col>
                        <Col>
                            <div className="upload-btn-wrapper">
                                <button className="btn-piece file">
                                    <label style={{float : "left"}}>Télécharger</label>
                                <label className={"tree-point"}>...</label>
                                </button>
                                <input
                                    type="file"
                                    name="kbis"
                                    onChange={this.PieceOnChange}
                                />
                                {(this.state.kbis !== undefined && this.state.kbis!== "") &&
                                <div>
                                <span className={'file-title'}>
                                    {this.state.kbis.name}</span>
                                    <span onClick={()=>{this.deleteFile("kbis")}} style={{cursor:"pointer"}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#1D1F39"}} size={"25px"}/>
                                        </span>
                                </div>
                                }
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                            </div>

                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                placeholder={"N° de téléphone"}
                                value={this.state.telephone}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.telephoneErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"Adresse e-mail"}
                                value={this.state.email}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder={"Mot de passe"}
                                value={this.state.password}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.passwordErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="password"
                                name="password_confirm"
                                id="password_confirm"
                                placeholder={"Confirmer le mot de passe"}
                                value={this.state.password_confirm}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.password_confirmErr === true &&
                            <span className='error'>{this.state.password_confirmErrMsg}</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"}>
                            <Checkbox onChange={(e)=>this.onChangecheckbox(e)}
                                      className={"check-cgu"} id={"checked"}>Je reconnais avoir
                                pris connaissance des <Link to={"/cgu"} target={"_blank"}>
                                    <label style={{color:"#1D1F39", fontWeight:"700", cursor:"pointer"}}>
                                        Conditions générales d'utilisation</label>
                                </Link> et les accepte.</Checkbox>
                            {this.state.checkedErr === true &&
                                <span className='error'>Veuillez cocher cette case</span>}
                        </Col>
                    </Row>

                    <Row className="inscri-expediteur">
                        <Col>
                            <Button className={"btn-continue"} onClick={()=>this.onSubmit()}
                            disabled={this.state.LoadingSubmit}>
                                {this.state.LoadingSubmit ? <div className="spinner">
                                    <Spinner animation="border" role="status" className={'spinner-btn'}
                                             variant="primary"/> </div>:
                                <span>Continuer</span>}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )

    }
}

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);



export default Register_expedi_entr
