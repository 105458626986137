import React, {useEffect, useState} from 'react';
import { Modal, Button } from 'antd';
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
let interval =""
const ModalEtatCommande = ({callback,Visiblity,demande}) => {
    console.log('paaaaaaaaasd state : ',demande)
    const [currentPosition,
        setCurrentPosition] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(Visiblity);
    useEffect( () => {
        console.log(currentPosition)
        console.log(demande.transporteur.personne)
        if (demande.statutdemandes) {
            let asAcceptedStatus = demande
                .statutdemandes
                .find(elem => elem.statutDemandeType == "ACC");
            if (asAcceptedStatus) {
                console.log(currentPosition)
                setCurrentPosition(2);
                if (demande.livraison) {
                    let lief = demande
                        .livraison.statutlivraisons
                        .find(elem => elem.statutLivraisonType == "LIEF");
                    if(lief){

                        setCurrentPosition(6)
                    }else{
                        let ENAC = demande
                            .livraison.statutlivraisons
                            .find(elem => elem.statutLivraisonType == "ENAC");
                        if(ENAC){
                            console.log('ddddddddd')
                            setCurrentPosition(5)
                        }else{
                            let ENAC = demande
                                .livraison.statutlivraisons
                                .find(elem => elem.statutLivraisonType == "ENEF");
                            if(ENAC){
                                setCurrentPosition(4)
                            }else{
                                let ACC = demande
                                    .livraison.statutlivraisons
                                    .find(elem => elem.statutLivraisonType == "ACC");
                                if(ACC){
                                    setCurrentPosition(3)
                                }
                            }
                        }
                    }
                    console.log(currentPosition)

                }
            }

        }
        checkStatutDemande()
        interval = setInterval(() => checkStatutDemande(), 7000)
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, []);

    function checkStatutDemande(){
        if (demande.livraison) {
            axios.post(`${BACKEND_API_URL}livraison/getLivraisonStats`,{
                livraisonId : demande.livraison.livraisonId, DemandeId:demande.demandeId
                , commandeId: demande.commandeId
            }).then(res=>
            {
                console.log(res.data)
                let lief = res.data
                    .livraison
                    .find(elem => elem.statutLivraisonType === "LIEF");
                if(lief){

                    setCurrentPosition(6)
                }else{
                    let ENAC = res.data
                        .livraison
                        .find(elem => elem.statutLivraisonType === "ENAC");
                    if(ENAC){
                        console.log('ddddddddd')
                        setCurrentPosition(5)
                    }else{
                        let ENAC = res.data
                            .livraison
                            .find(elem => elem.statutLivraisonType === "ENEF");
                        if(ENAC){
                            setCurrentPosition(4)
                        }else{
                            let ACC = res.data
                                .livraison
                                .find(elem => elem.statutLivraisonType === "ACC");
                            if(ACC){
                                setCurrentPosition(3)
                            }
                        }
                    }
                }


            })
                .catch(e=>{console.log(e)})
        }
    }

    if(Visiblity !== isModalVisible){
        setIsModalVisible(Visiblity)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        callback()
    };

    return (
        <>
            <Modal title="Etat de la commande"  visible={isModalVisible}
                   footer={null} onCancel={handleCancel} className={'etat-modal'} destroyOnClose={true}>
                <div className={"etat-modal"}>
                        <Container>
                            <Row>
                                <Col className={"left-border"} lg={0}/>
                                <Col className={"droite-left"}>
                                    {/* ********** Paiement accepté ******* */}
                                    <Row className={"etatrow"}>
                                        {currentPosition === 1 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 1 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }
                                        <Col className={"horizontal-line"} lg={1} xs={1}>
                                            <div style={{height: 2, backgroundColor: '#38E321'}} />
                                        </Col>
                                        <Col className={"horizontal-line"}>
                                            <p className={"actual-state"}>Paiement accepté</p>
                                        </Col>
                                    </Row>
                                    {/* ********** Transporteur affecté à la course  ********* */}
                                    <Row className={" after-etat"}>
                                        {currentPosition === 2 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 2 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }

                                        {currentPosition < 2 ?<>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#D9D9D9'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"next-state"}>
                                                        Transporteur affecté à la course</p>
                                                </Col></> :
                                            <>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#38E321'}} />
                                                </Col>
                                                <Row>
                                                    <Col className={"horizontal-line pic"} lg={2} xs={3}>
                                                        <img className={"transp-map-img"}
                                                            style={{
                                                                width: 40,
                                                                height: 40,borderRadius: 50
                                                            }}
                                                            src = {(demande.transporteur.personne.personne.photo != null)
                                                                ? BACKEND_ROOT_URL + demande.transporteur.personne
                                                                        .personne.photo
                                                                : require('../Images/pick_site/user.png')}
                                                        />
                                                    </Col>
                                                    <Col className={"horizontal-line"}>
                                                        <p className={"actual-state trans"}>Transporteur affecté à
                                                            la course
                                                        </p>
                                                        <p className={"transp-name"}>
                                                            {demande.transporteur.personne.prenoms
                                                        +" "+ demande.transporteur.personne.nom}</p>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                    </Row>

                                    {/* ********** Transporteur  à l’approche  ********* */}
                                    <Row className={" after-etat"}>
                                        {currentPosition === 3 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 3 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }

                                        {currentPosition < 3 ?<>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#D9D9D9'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"next-state"}>
                                                        Transporteur à l’approche</p>
                                                </Col></> :
                                            <>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#38E321'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"actual-state"}>
                                                        Transporteur à l’approche</p>
                                                </Col>
                                            </>
                                        }

                                    </Row>

                                    {/* ********** Enlèvement effectué  ********* */}
                                    <Row className={" after-etat"}>
                                        {currentPosition === 4 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 4 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }

                                        {currentPosition < 4 ?<>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#D9D9D9'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"next-state"}>Enlèvement effectué</p>
                                                </Col></> :
                                            <>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#38E321'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"actual-state"}>Enlèvement effectué</p>
                                                </Col>
                                            </>
                                        }

                                    </Row>

                                    {/* ********** En cours d’acheminement  ********* */}
                                    <Row className={"after-etat"}>
                                        {currentPosition === 5 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 5 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }

                                        {currentPosition < 5 ?<>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#D9D9D9'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"next-state"}>En cours d’acheminement</p>
                                                </Col></> :
                                            <>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#38E321'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"actual-state"}>En cours d’acheminement</p>
                                                </Col>
                                            </>
                                        }

                                    </Row>

                                    {/* ********** Livraison effectuée  ********* */}
                                    <Row className={"after-etat"}>
                                        {currentPosition === 6 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice"} />
                                        </Col>
                                        }
                                        {currentPosition < 6 &&
                                        <Col className={"circlecol"} lg={0}>
                                            <div className={"circle-indice-next"} />
                                        </Col>
                                        }

                                        {currentPosition < 6 ?<>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#D9D9D9'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"next-state"}>Livraison effectuée</p>
                                                </Col></> :
                                            <>
                                                <Col className={"horizontal-line"} lg={1} xs={1}>
                                                    <div style={{height: 2, backgroundColor: '#38E321'}} />
                                                </Col>
                                                <Col className={"horizontal-line"}>
                                                    <p className={"actual-state"}>Livraison effectuée</p>
                                                </Col>
                                            </>
                                        }

                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                </div>
            </Modal>
        </>
    );
};

export default ModalEtatCommande
