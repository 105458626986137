import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './VerticalNavbar2.css'
import {NavLink} from "react-router-dom";
import ellipse from '../Images/Ellipse 264.png'
import { withTranslation } from 'react-i18next';

class VerticalNavbar_assistance extends Component {
    render() {
        const {t} = this.props;

        return (
            <Container className="vericalNav" fluid >
                <Row>
                       <NavLink className="columnBack" style={{borderRadius: "30px 0 0 0",
                           borderBottom:"2px solid white", width:"100%"}} to="/assistance/accessibilite"
                                activeClassName="selected selected1">
                           <h5>{t('151')} </h5>
                           <img className="ellipse ellipse1" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%"}}
                                to="/assistance/faqS" activeClassName="selected selected2">
                           <h5>{t('152')}</h5>
                           <img className="ellipse ellipse2" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>
                <Row>
                       <NavLink className="columnBack" style={{borderBottom:"2px solid white", width:"100%",
                           borderRadius: "0 0 0 30px"}} exact to="/assistance" activeClassName="selected selected2">
                           <h5>{t('153')}</h5>
                           <img className="ellipse ellipse2" src={ellipse} width={30} height={30}/>
                       </NavLink>
                </Row>

            </Container>
        );
    }
}

export default withTranslation()(VerticalNavbar_assistance);
