import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from '../components/VerticalNavbar2';
import {Modal, Dropdown, Menu, notification, Tag, Select, Avatar, DatePicker, Input} from "antd";

import Button from "antd/es/button";
import axios from "axios";
import {saveAs} from "file-saver";

import { withTranslation } from 'react-i18next';
import PlacesAutocomplete from "react-places-autocomplete";
import jwt from "jsonwebtoken";
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";
import { UserType } from '../Constants/constants';
import { checkFileSize, checkPDFType, maxSelectFile } from '../Controls/FilesErrors';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { openNotificationError } from '../Helpers';

const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
let imageURL='../Images/pick_site/user.png';
const dateFormat = 'DD/MM/YYYY'

class MonCompte extends Component {
    constructor() {
        super()
        this.state = {
            errorPays : false,
            errorPhone : false,
            ErrorAge : false,
            denominationErr : false,
            adresseErr : false,
            adresseSiegeErr : false,
            adresseFacturErr : false,
            statutErr : false,
            telephoneErr : false,
            emailErr : false,
            activiteErr : false,
            represenatnt_legalErr:false,
            passwordErr : false,
            siretErr : false,
            nom_a_contacterErr : false,
            tvaErr : false,emailSecoursErr : false,
            Date_NaissanceErr : false,
            LoadingSubmit: false,adresseEnleveErr: false,
            password_confirmErrMsg: '',
            errors: {
                piece: '',
            },
            kbis : "",
            rib : "",represenatnt_legal : "",
            adresseFactur: "",
            denomination: "",
            statut:"",secteurs : "",
            password: "",
            siret:"",emailSecours:"",
            activite : "",
            tva:"",nom_a_contacter : "",
            dateNaissance:'',Nom:'',prenom:'',
            telephone:"", email :"", adresse : "",
            LoadingData : true,
            modalMDP : false,
            permis:'',cin:'',password_confirm:'',
            token : '',
            user : null,actualPassword:"",
            actualPasswordErr : false,confirmPassword:"",
            confirmPasswordErr:false,
            IsSamePassword :true,
            urlkbispiece :"",
            adresseSiege : "",adresseEnleve : "",
        }
        this.onChangeDateChange = this.onChangeDateChange.bind(this);
        this.onChange = this.onChange.bind(this);

        this.onPieceChange = this.onPieceChange.bind(this)
        this.onchange = this.onchange.bind(this)
        this.PictureOnChange = this.PictureOnChange.bind(this)
        this.change_motif_delete = this.change_motif_delete.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.GetUserInfo()
    }

    async GetUserInfo(){
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        console.log(decodedToken)
        this.setState({userId : decodedToken.userId,
            personneId : decodedToken.personneId,token : decodedToken.token})
            this.getPieces(decodedToken.personneId)
            this.getSecteurActivEntrp(decodedToken.personneId)
        axios.post(`${BACKEND_API_URL}expediteur/getInfoUser`, {
            utilisateurId: this.state.userId,
        }).then(async res => {
            if(res.data.success){
                console.log('user Info : ', res.data.user);
                if(res.data.user.userType === UserType.CLIENT_PARTICULIER.CODE){
                    await this.setState({
                        Nom: res.data.user.personne.personnephysique.nom,
                        prenom: res.data.user.personne.personnephysique.prenoms,
                        // dateNaissance: res.data.user.personne.personnephysique.dateNaissance,
                        telephone: res.data.user.personne.telephone,
                        email: res.data.user.personne.email,
                        adresse: res.data.user.personne.personnephysique.adresse,
                    })
                }else{
                    await this.setState({
                        statut : res.data.user.personne.personnemorale.statutEntreprise,
                        denomination : res.data.user.personne.personnemorale.denomination,
                        siret : res.data.user.personne.personnemorale.siret,
                        represenatnt_legal : res.data.user.personne.personnemorale.nomRepresentant,
                        tva : res.data.user.personne.personnemorale.tva,
                        adresseFactur : res.data.user.personne.personnemorale.adresseFacturation,
                        adresseSiege : res.data.user.personne.personnemorale.adresseSociale,
                        adresseEnleve : res.data.user.personne.personnemorale.adresseEtab,
                        nom_a_contacter : res.data.user.personne.personnemorale.personneContact,
                        telephone: res.data.user.personne.telephone,
                        email: res.data.user.personne.email,
                        emailSecours: res.data.user.personne.emailSecondaire,
                    })
                }
                
                await this.setState({user :res.data.user})
                await this.setState({LoadingData : false})
                this.displayImage()
            }
        }).catch(err => console.log(err));
    }
    getSecteurActivEntrp(personneId) {
        axios.post(`${BACKEND_API_URL}user/getSecteurActiviteUser`, {
          personneId: personneId,
        })
          .then(res => {
            console.log('secteurrr', res.data.secteur.secteurActiviteId);
            this.setState({secteurs: res.data.secteur.secteurActiviteId});
          })
          .catch(err => console.log(err));
      }
    getPieces(personneId){
        axios.post(BACKEND_API_URL+'transporteur/getUserPiece',
            {personneId : personneId})
            .then((res)=>{
                console.log("user piece : ",res.data)
                res.data.map(piece => {
                    console.log(piece)
                    if(piece.documentType === 'KBIS'){
                        this.setState({urlkbispiece : piece.contenu})
                    }
                })
            }).catch(err=>{console.log(err)})
    }

    validateForm(){
        let error = false
        if(this.state.actualPassword === ''){
            this.setState({actualPasswordErr: true})
            error = true
            return error
        }else{
            this.setState({actualPasswordErr: false})
            error = false
        }

        if(this.state.password === ''){
            this.setState({passwordErr: true})
            error = true
            return error
        }else{
            this.setState({passwordErr: false})
            error = false
        }
        if(this.state.confirmPassword === ''){
            this.setState({confirmPasswordErr: true})
            error = true
            return error
        }else{
            this.setState({confirmPasswordErr: false})
            error = false
        }
        console.log(this.state.confirmPassword+this.state.password)
        if (this.state.confirmPassword !== this.state.password) {
            this.setState({IsSamePassword: false});
            error = true
            return error
        } else {
            this.setState({IsSamePassword: true});
            error = false
        }

        return error

    }
    onChange(e) {
        //this.setState({ [e.target.name]: e.target.value })

        const { name, value } = e.target;

        this.setState({[name]: value})
    }
    onChangeDateChange(date, dateString) {
        this.setState({dateNaissance : dateString})
    }

    handleChange = adresse => {
        console.log('onchange',adresse)
        this.setState({ adresse : adresse });

    };

    handleSelect = address => {
        console.log('on select : ',address)

        this.setState({ adresse : address });
    };

    hide = () => {
        this.setState({
            avatarChoice: false,
        });
    };
    handleVisibleChange = avatarChoice => {
        this.setState({ avatarChoice });
    };



    //****************** Modal *******************
    showModal = () => {
        this.setState({
            modal: true,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,modalMDP : false
        });
    };
    //****************** Modal *******************


    onchange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})

    }

    onPieceChange(e){
        switch (e.target.name) {
            case "pieceRecto" :
                this.setState({pieceRecto : e.target.files[0]})
                break;
            case "pieceVerso" :
                this.setState({pieceVerso : e.target.files[0]})
                break
        }
    }

    openNotification = () => {
        notification['success']({
            message: "Succès",
            description:"Vos données ont été modifiées avec succès",
        });
    };
    openNotificationMdp = () => {
        notification['success']({
            message: "Succès",
            description:"Votre mot de passe a été modifié avec succès",
        });
    };

    openNotificationError = () => {
        notification['error']({
            message: 'Erreur',
            description:"Une erreur s'est produite veuillez réessayer",
        });
    };
    openNotificationErrorMsg = (Msg) => {
        notification['error']({
            message: 'Erreur',
            description:Msg,
        });
    };
    validateFormEntreprise(){
        let error = false
        if(this.state.denomination === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({denominationErr: true})
            error = true
            document.getElementById('denomination').scrollIntoView()
            return error
        }else{
            this.setState({denominationErr: false})
            error = false
        }
        
        if(this.state.represenatnt_legal === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({represenatnt_legalErr: true})
            error = true
            document.getElementById('represenatnt_legal').scrollIntoView()
            return error
        }
        else{
            this.setState({represenatnt_legalErr: false})
            error = false
        }
        if(this.state.statut === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({statutErr: true})
            error = true
            document.getElementById('statut').scrollIntoView()
            return error
        }
        else{
            this.setState({statutErr: false})
            error = false
        }
        if(this.state.siret === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({siretErr: true})
            error = true
            document.getElementById('siret').scrollIntoView()
            return error
        }
        else{
            this.setState({siretErr: false})
            error = false
        }
        if(this.state.tva === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({tvaErr: true})
            error = true
            document.getElementById('tva').scrollIntoView()
            return error
        }
        else{
            this.setState({tvaErr: false})
            error = false
        }
        if(this.state.adresseSiege === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseSiegeErr: true})
            error = true
            document.getElementById('adresseSiege').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseSiegeErr: false})
            error = false
        }
        if(this.state.adresseFactur === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseFacturErr: true})
            error = true
            document.getElementById('adresseFactur').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseFacturErr: false})
            error = false
        }
        if(this.state.adresseEnleve === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseEnleveErr: true})
            error = true
            document.getElementById('adresseEnleve').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseEnleveErr: false})
            error = false
        }
        if(this.state.nom_a_contacter === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({nom_a_contacterErr: true})
            error = true
            document.getElementById('nom_a_contacter').scrollIntoView()
            return error
        }
        else{
            this.setState({nom_a_contacterErr: false})
            error = false
        }
        if(!validPhoneRegex.test(this.state.telephone.trim()) ){
            console.log('Veuillez remplir ce champ')
            this.setState({telephoneErr: true})
            error = true
            document.getElementById('telephone').scrollIntoView()
            return error
        }
        else{
            this.setState({telephoneErr: false})
            error = false
        }
        if(!validEmailRegex.test(this.state.email.trim())){
            console.log(validEmailRegex.test(this.state.email.trim()))
            this.setState({emailErr: true})
            error = true
            document.getElementById('email').scrollIntoView()
            return error
        }
        else{
            this.setState({emailErr: false})
            error = false
        }
        if(this.state.emailSecours.trim()){
            if(!validEmailRegex.test(this.state.emailSecours.trim())){
                this.setState({emailSecoursErr: true})
                error = true
                document.getElementById('emailSecours').scrollIntoView()
                return error
            }
            else{
                this.setState({emailErr: false})
                error = false
            }
        }
        
        
        return error
    }
    updateProfile = async() =>{
        if(this.state.user.userType === UserType.CLIENT_ENTREPRISE.CODE){
            let valid = await this.validateFormEntreprise()
            if(valid === false){
            this.setState({
                loading : true
            })
            const data = new FormData();
      data.append('userId', this.state.user.userId);
      data.append('personneId', this.state.user.personneId);
      data.append('adresseSociale', this.state.adresseSiege);
      data.append('adresseFacturation', this.state.adresseFactur);
      data.append('adresseEtab', this.state.adresseEnleve);
      data.append('personneContact', this.state.nom_a_contacter);
      data.append('telephone', this.state.telephone);
      data.append('email', this.state.email);
      data.append('emailSecours', this.state.emailSecours);
      data.append('secteur', this.state.secteurs);
      data.append('statutEntreprise', this.state.statut);
      data.append('denomination', this.state.denomination);
      data.append('siret', this.state.siret);
      data.append('tva', this.state.tva);
      // data.append('password', this.state.password);
      data.append('nomRepresentant', this.state.represenatnt_legal);
      data.append('extraitkbis', this.state.kbis);
            await axios.post(`${BACKEND_API_URL}expediteur/entreprise/update`, data,
            {headers : {
                'Content-Type': 'multipart/form-data',
            }}
            ).then(async response => {
                console.log(response)
                this.setState({LoadingSubmit : false})
                if(!response.data.success){
                    if(response.data.message === 'Email déjà utilisé'){
                        openNotificationError('Adresse e-mail est déjà utilisée','')
                    }else
                        openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
                }else{
                    this.openNotification()
                }
            }).catch(err=>{
                this.setState({LoadingSubmit : false})
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
            })
            
        }
        }
        else{
            this.setState({
                loading : true
            })
            const data = new FormData();
            data.append('userId', this.state.user.userId);
            data.append('photo', this.state.image);
            console.log(this.state.token)
            axios.post(`${BACKEND_API_URL}expediteur/client/update`, {
                userId: this.state.userId,
                personneId: this.state.personneId,
                nom: this.state.Nom,
                prenoms: this.state.prenom,
                email: this.state.email.trim(),
                telephone: this.state.telephone,
                adresse: this.state.adresse,
            }).then(async response => {
                console.log('reponse', response.data.user)
                if (response.data.success) {
                    this.openNotification()
                } else {
                    this.openNotificationError()
                }
            });
        }
    }

    deleteUser = () => {
        axios.delete(`api/updateuser/${this.state.user.id}`).then(res => {
            console.log(res)
            localStorage.removeItem('usertoken');
            localStorage.removeItem('username');
            localStorage.removeItem('checkbox');
            window.location.reload();
        })
    }

    displayImage(){
        if(!this.state.LoadingData){
            if (this.state.user.personne.photo) {
                imageURL = BACKEND_ROOT_URL + this.state.user.personne.photo
            } else {
                imageURL = require('../Images/pick_site/user.png')
            }
            document.getElementById("profile-img").src=imageURL;
        }

    }

    PictureOnChange(event){
        let file = event.target.files[0];
        // ******* have a preview on the image before loading  *******
        let reader = new FileReader();
        let url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            imageURL = [reader.result];
            document.getElementById("profile-img").src=imageURL;
        }
        this.setState({image : event.target.files[0]})
        //********************************************
        const data = new FormData();
        data.append('userId', this.state.user.userId);
        data.append('photo', file);
        axios
            .put(`${BACKEND_API_URL}user/editPP`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data; ',
                    Authorization: 'Bearer ' + this.state.token
                }
            })
            .then(async (response) => {this.openNotification()
            }).catch(e=>{
            this.openNotificationError()})
    }

    change_motif_delete(value){
        let val = value;
        console.log(val)
        this.setState({
            motif: val
        })}

    imgToBase64(img) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;

        // I think this won't work inside the function from the console
        //img.crossOrigin = 'anonymous';

        ctx.drawImage(img, 0, 0);

        return canvas.toDataURL();
    }

    getLangue(value){
        console.log(value)
        this.setState({Langue_prefere: value})
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
    async changePassword(){
        let valid = await this.validateForm()
        console.log(this.state.confirmPassword+ "  " + this.state.password)
        if (valid === false) {
            axios.post(`${BACKEND_API_URL}transporteur/transp/update/password`, {
                userId: this.state.user.userId,
                personneId: this.state.user.personneId,
                password: this.state.password,
                Actualpassword: this.state.actualPassword,
            }).then(async response => {
                console.log(response)
                if (response.data.success) {
                    this.openNotificationMdp()
                    this.handleCancel()
                } else {
                    this.openNotificationErrorMsg(response.data.message)
                }
            }).catch(e=>{
                this.openNotificationError()
            })
               }

    }

    handleChange = adresse => {
        console.log('onchange',adresse)
        this.setState({ adresseSiege : adresse });

    };

    handleSelect = address => {
        console.log('on select : ',address)
        this.setState({ adresseSiege : address });
    };

    handleChange_add_facturation = adresse => {
        this.setState({ adresseFactur : adresse });
    };

    handleSelect_add_facturation = address => {
        this.setState({ adresseFactur : address });
    };
    handleChange_add_enlev = adresse => {
        this.setState({ adresseEnleve : adresse });
    };

    handleSelect_add_enlev = address => {
        this.setState({ adresseEnleve : address });
    };

    handleSecteurActivi = value => {
        this.setState({ activite : value });
    }
    handleStatut = value => {
        this.setState({ statut : value });
    }
    PieceOnChange= event =>{
        // Adding File to State
        console.log("fiiiiile : ",event.target.files[0])
        let errors = this.state.errors;
        if(checkFileSize(event) ){
            if (maxSelectFile(event)){
                if (checkPDFType(event) ){
                    console.log(event)
                    switch (event.target.name) {
                        case "kbis" :
                            this.setState({kbis : event.target.files[0]})
                            break;
                    }
                    errors.piece=""
                }
                else{
                    errors.piece = "Formats acceptés : PNG, JPG et PDF"

                }

            }
            else{
                errors.piece = "Vous devez selectionner une seule piece d'identité"

            }
        }
        else{
            errors.piece = "le document doit etre inferieure à 2 Mb"
        }
        this.setState({errors})
    }

    deleteFile(v){
        let errors = this.state.errors;
        errors.piece =""
        if(v==="kbis"){
            this.setState({kbis : ""})
        }
        else {
            this.setState({rib : ""})
        }
    }


    F_ClientParticulier(){
        if (this.state.user){
            return(
                <>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="Nom"
                                id="Nom"
                                placeholder={"Nom"}
                                value={this.state.Nom}
                                onChange={this.onChange}
                                className="input-register"
                            />
                            {this.state.NomErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="prenom"
                                id="prenom"
                                placeholder={"Prénom"}
                                value={this.state.prenom}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.prenomErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col id="Date_Naissance">
                            <DatePicker format={'DD/MM/YYYY'}
                                        defaultValue={moment(this.state.dateNaissance, dateFormat)}
                                        placeholder={'Date de naissance'}
                                        className={"date-input"}
                                        onChange={this.onChangeDateChange}/>
                            {this.state.Date_NaissanceErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col>
                            <PlacesAutocomplete
                                value={this.state.adresse}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"/>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresse"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                placeholder={"N° de téléphone"}
                                value={this.state.telephone}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.telephoneErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"Adresse e-mail"}
                                value={this.state.email}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>
                </>
            )
        }
    }
    F_ClientEntreprise(){
        console.log("this",this.state.user)
        const {errors} = this.state;
        if (this.state.user){
            return(
                <>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="denomination"
                                id="denomination"
                                placeholder={"Dénomination sociale"}
                                value={this.state.denomination}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.denominationErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Select defaultValue={this.state.statut} className={"btn-piece"} id="statut"
                                    onChange={this.handleStatut}>
                                <Option value="">Statut de l'entreprise</Option>
                                <Option value="SAS">SAS</Option>
                                <Option value="SA">SA</Option>
                                <Option value="SARL">SARL</Option>
                                <Option value="EURL">EURL</Option>
                                <Option value="EI">EI</Option>
                                <Option value="EIRL">EIRL</Option>
                                <Option value="SNC">SNC</Option>
                                <Option value="SCOP">SCOP</Option>
                                <Option value="SCA">SCA</Option>
                                <Option value="SCS">SCS</Option>
                                <Option value="SCI">SCI</Option>
                                <Option value="ASSOCIATION">ASSOCIATION</Option>
                            </Select>
                            {this.state.statutErr === true &&
                            <span className='error'>Merci de choisir un statut</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <input
                                type="text"
                                name="siret"
                                id="siret"
                                placeholder={"N° de SIRET"}
                                value={this.state.siret}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.siretErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <input
                                type="text"
                                name="represenatnt_legal"
                                id="represenatnt_legal"
                                placeholder={"Nom du représentant légal"}
                                value={this.state.represenatnt_legal}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.represenatnt_legalErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <input
                                type="text"
                                name="tva"
                                id="tva"
                                placeholder={"N° de TVA"}
                                value={this.state.tva}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.tvaErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    {/* <Row>
                    <Col >
                            <Select defaultValue={this.state.activite} className={"btn-piece"} id="activite"
                                    onChange={this.handleSecteurActivi}>
                                <Option value="">Secteur d’activités</Option>
                                <Option value="ECOM">E-commerce</Option>
                                <Option value="EVEN">Evénementiel</Option>
                                <Option value="FLOR">Floral</Option>
                                <Option value="FOBU">Fourniture de bureau</Option>
                                <Option value="FOMO">Fourniture de mobilier</Option>
                                <Option value="FOPA">Fourniture de pièce auto</Option>
                                <Option value="GRDI">Grande distribution</Option>
                                <Option value="INDU">Industriel</Option>
                                <Option value="INEL">Informatique/électroménager</Option>
                                <Option value="LOGI">Logistique</Option>
                                <Option value="PAFI">Pâtisserie fine</Option>
                                <Option value="PHME">Pharmaceutique/médical</Option>
                                <Option value="SOTD">Sous température dirigé</Option>
                                <Option value="Taxi">Taxi</Option>
                                <Option value="TRAI">Traiteur</Option>
                                <Option value="TRDK">Transporteur du dernier kilomètre</Option>
                                <Option value="TRFR">Transport fret</Option>
                                <Option value="VTC">VTC</Option>
                            </Select>
                            {this.state.activiteErr === true &&
                            <span className='error'>Merci de choisir une activité</span>}
                        </Col> 
                    </Row> */}
                    <Row>
                        <Col>
                        <PlacesAutocomplete
                                value={this.state.adresseSiege}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse du siège social',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseSiege"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseSiegeErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                            <PlacesAutocomplete
                                value={this.state.adresseFactur}
                                onChange={this.handleChange_add_facturation}
                                onSelect={this.handleSelect_add_facturation}
                                searchOptions={options}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse de facturation',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseFactur"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseFacturErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                            <PlacesAutocomplete
                                value={this.state.adresseEnleve}
                                onChange={this.handleChange_add_enlev}
                                onSelect={this.handleSelect_add_enlev}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: "Adresse de l'établissement d'enlèvement",
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseEnleve"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseEnleveErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                            <input
                                type="text"
                                name="nom_a_contacter"
                                id="nom_a_contacter"
                                placeholder={"Nom de la personne à contacter"}
                                value={this.state.nom_a_contacter}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.nom_a_contacterErr  === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>

                    </Row>
                    <Row>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <p>Extrait de KBIS (moins de 6 mois) {this.state.urlkbispiece ? 
                            <button onClick={()=>saveAs(BACKEND_ROOT_URL+
                                '/FilesClientEntreprise/' + this.state.user.userId +'/'+
                                this.state.urlkbispiece, this.state.urlkbispiece)
                        } className={'file-title'}>
                            <span className={'file-title'}>Voir</span></button> : 
                            <span className={'file-title'}>! Aucun document</span>}
                            </p>
                        </Col>
                        <Col>
                            <div className="upload-btn-wrapper">
                                <button className="btn-piece file">
                                    <label style={{float : "left"}}>Télécharger</label>
                                <label className={"tree-point"}>...</label>
                                </button>
                                <input
                                    type="file"
                                    name="kbis"
                                    onChange={this.PieceOnChange}
                                />
                                {(this.state.kbis !== undefined && this.state.kbis!== "") &&
                                <div>
                                <span className={'file-title'}>
                                    {this.state.kbis.name}</span>
                                    <span onClick={()=>{this.deleteFile("kbis")}} style={{cursor:"pointer"}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#1D1F39"}} size={"25px"}/>
                                    </span>
                                </div>
                                }
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                placeholder={"N° de téléphone"}
                                value={this.state.telephone}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.telephoneErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"Adresse e-mail"}
                                value={this.state.email}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                name="email"
                                id="emailSecours"
                                placeholder={"Adresse e-mail de secours"}
                                value={this.state.emailSecours}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailSecoursErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>
                </>
            )
        }
    }

    render() {
        const {errors} = this.state;
        //********************* DESKTOP VER  **************************
            return (
                <Container fluid className={'dashboard-pages'}>
                    <Row className="profile">
                        <Col md={'auto'}>
                            <VerticalNavbar2/>
                        </Col>
                        {/*  ************ modal *********************  */}
                        <div>
                            <Modal closable={false}
                                visible={this.state.modal}
                                title={'Suppression de mon compte'}
                                footer={null} className={'delete-modal'}
                                >
                                    <Row>
                                        <Col>
                                            <span className={"confirm-txt"}>
                                                Êtes-vous bien sûr de vouloir supprimer
                                                votre compte ?
                                            </span>
                                        </Col>
                                    </Row>
                                <Row>
                                    <Col>
                                    <Button className={"btn-oui"} onClick=
                                        {()=>this.deleteUser()}>
                                        <span>Oui</span>
                                    </Button>
                                    </Col>
                                    <Col>
                                    <Button className={"btn-non"} onClick=
                                        {()=>this.handleCancel()}>
                                        <span>Non</span>
                                    </Button>
                                    </Col>

                                </Row>
                            </Modal>
                        </div>
                        {/*  ************ modal *********************  */}

                        {/*  ************ modal *********************  */}
                        <div>
                            <Modal closable={false}
                                visible={this.state.modalMDP}
                                title={'Réinitialiser votre mot de passe'}
                                footer={null} className={'delete-modal'}
                                >
                                <Row>
                                    <Col>
                                        <div className={'input-compte'}>
                                            <Input.Password name="actualPassword" placeholder="Votre mot de passe"
                                                            value={this.state.actualPassword} className={"input-pass"}
                                                            onChange={this.onChange} required={true} />
                                            {this.state.actualPasswordErr === true &&
                                            <span className='error'>Entrez un mot de passe</span>}
                                            </div>
                                        <div className={'input-compte'}>
                                            <Input.Password name="password" placeholder="Nouveau mot de passe"
                                                            value={this.state.password} className={"input-pass"}
                                                            onChange={this.onChange} required={true} />
                                            {this.state.passwordErr === true &&
                                            <span className='error'>Entrez un mot de passe</span>}
                                            </div>
                                        <div className={'input-compte'}>
                                            <Input.Password name="confirmPassword"
                                                            placeholder="Confirmer nouveau mot de passe"
                                                            value={this.state.confirmPassword} className={"input-pass"}
                                                            onChange={this.onChange} required={true} />
                                            {!this.state.IsSamePassword &&
                                            <span className='error'>Mot de passe différent</span>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'center-btn'}>
                                    <Button className={"btn-oui"} onClick=
                                        {()=>this.changePassword()}>
                                        <span>Enregistrer</span>
                                    </Button>
                                    </Col>
                                    <Col>
                                    <Button className={"btn-non"} onClick=
                                        {()=>this.handleCancel()}>
                                        <span>Annuler</span>
                                    </Button>
                                    </Col>

                                </Row>
                            </Modal>
                        </div>
                        {/*  ************ modal *********************  */}
                        {this.state.LoadingData ?<div className="spinner-center"><Spinner animation="border" role="status"
                                                                           variant="primary"/> </div>
                            :
                            <Col className={'right-dashboard'}>
                                <Row className="inscri-expediteur">
                                    <Col>
                                        <h2>Mon compte</h2>
                                        <hr className={"hr-register hr-dash"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'right-dashboard-compte'} lg={{order: 1,span:7}}
                                         xs={{ order: 3,span:12 }}>
                                        {(this.state.user.userType === UserType.CLIENT_PARTICULIER.CODE) &&
                                        this.F_ClientParticulier()}
                                        {(this.state.user.userType === UserType.CLIENT_ENTREPRISE.CODE) &&
                                        this.F_ClientEntreprise()}
                                    </Col>
                                    <Col lg={{order: 2,span:3}} className={'actions-compte'} xs={{ order: 2,span:12 }}>
                                        <button className={'link-btn'}>
                                            <label className={'upload'} htmlFor="file">Ajouter / modifier
                                                <br/>
                                                votre photo de profil
                                            </label></button>
                                        <input id="file" className="input-file"
                                               type="file" name="image"
                                               onChange={this.PictureOnChange} />
                                        <hr className={"little-hr"}/>
                                        <button className={'link-btn'}
                                                onClick={this.showModal}>Supprimer mon compte</button>
                                    </Col>
                                    <Col lg={{order: 3,span:2}} xs={{ order: 1 }} className={'picture-prof'}>
                                        <div style={{float: "right",display: "grid",placeItems:"center"}}>
                                            <img id="profile-img" style={{borderRadius: "50%", width :"150px",
                                                height:"150px"}}/>

                                            {/*<Button type="link" onClick={e=>{e.preventDefault();
                                                localStorage.removeItem('usertoken');
                                                console.log("deconnexion ...")
                                                window.location.reload();                }}
                                            >{t('66')}</Button>*/}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={'actions-compte'}>
                                        <div>
                                            <button className={'link-btn'} onClick={()=>this.updateProfile()}>
                                                <span>Modifier mon profil</span>
                                            </button>
                                            <hr className={"little-hr-bottom"}/>
                                            <button className={'link-btn'} onClick={()=>{
                                                this.setState({modalMDP : true})}}>
                                                <span>Réinitialiser mon mot de passe</span>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>

                </Container>
            );

    }
}
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);



export default withTranslation()(MonCompte)
