import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../Images/logoFooter.png"
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import {FaTwitter} from "react-icons/all";
import "./Footer.css"
import logoGaming2 from "../Images/CuracaoEgaming-Logo.png"
import {FaYoutube} from "react-icons/all";
import {Link, NavLink} from "react-router-dom";
import {FaTelegramPlane} from "react-icons/all";
import {withTranslation} from "react-i18next";

class Footer extends Component {
    render() {


        return (
            <div className="mainDiv">
            <Container className="max">
                <Row>
                    <label className={"footer-txt"} style={{width:"100%"}}>
                        © PICK&DELI – Tous droits réservés. Site réalisé et propulsé par <a
                        href="https://comoncloud.com/" style={{textDecoration:"underline"}}>COM ON CLOUD</a> – <Link to="/Mentions-légales">
                        <span className={"mention-footer"}>Mentions légales</span>
                        </Link> – <Link to="/politique-de-confidentialite">
                        <span className={"mention-footer"}>Politique de confidentialité</span>
                        </Link> – <Link to="/cgu">
                        <span className={"mention-footer"}>Conditions Générales d’Utilisation</span>
                        </Link>
                    </label>
                </Row>
            </Container>
            </div>
        );

    }
}

export default withTranslation()(Footer);
