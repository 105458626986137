import React, {Component, useMemo} from 'react'
import {checkFileSize, checkMimeType, checkPDFType, maxSelectFile} from "../Controls/FilesErrors";
import {LocationSearchInput} from "./LocationSearchInput";
import axios from "axios";
import './Register.css';
import {Col, Container, Spinner} from "react-bootstrap";
import {Avatar, Tooltip, Dropdown, Menu, notification, message, DatePicker, Checkbox} from "antd";
import Popover from "antd/es/popover";
import {FaAngleRight, AiOutlinePlus,IoIosCloseCircleOutline} from "react-icons/all";
import Avatar1 from "../Images/Avatars/Avatar1.png";
import Avatar2 from "../Images/Avatars/Avatar2.png";
import Avatar3 from "../Images/Avatars/Avatar3.png";
import Avatar4 from "../Images/Avatars/Avatar4.png";
import Avatar5 from "../Images/Avatars/Avatar5.png";
import Avatar6 from "../Images/Avatars/Avatar6.png";
import Avatar7 from "../Images/Avatars/Avatar7.png";
import Avatar8 from "../Images/Avatars/Avatar8.png";
import Avatar9 from "../Images/Avatars/Avatar9.png";
import Avatar10 from "../Images/Avatars/Avatar10.png";
import defaultAvatar from "../Images/Avatars/DeafultAvatar.png";
import frenchFlag from '../Images/french-flag.png';
import SpainFlag from '../Images/Spain_flag.png';
import BritainFlag from '../Images/Flag_Britain.png';
import ItalyFlag from '../Images/italy.png';
import info from "../Images/info.png";
import {GoAlert} from "react-icons/all";
import countryList from 'react-select-country-list';
import { Select } from 'antd';
import {withTranslation} from "react-i18next";
import PhoneInput,{isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import Row from "react-bootstrap/Row";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import Button from "antd/es/button";
import {BACKEND_API_URL} from "../env";
import {openNotificationError} from "../Helpers";
import {Link} from "react-router-dom";

let options = {
    componentRestrictions: {country: "fr"}
}
const { Option } = Select;


class Register_entr_transp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorPays : false,
            errorPhone : false,
            ErrorAge : false,
            denominationErr : false,
            adresseSiegeErr : false,
            adresseFacturErr : false,
            represenatnt_legalErr : false,
            statutErr : false,
            nom_a_contacterErr : false,
            telephoneErr : false,
            emailErr : false,
            passwordErr : false,
            activiteErr : false,
            siretErr : false,
            tvaErr : false,
            LoadingSubmit: false,
            errors: {
                email: '',
                password: '',
                piece: '',
                adresseSiege:'',
                adresseFactur:'',
                denomination:'',
                represenatnt_legal:'',
                statut:'',
                nom_a_contacter:'',
                telephone:'',
                activite:'',
                siret:'',
            },
            kbis : "",
            rib : "",
            adresseSiege : "",
            adresseFactur: "",
            denomination:"",
            represenatnt_legal : "",
            statut:"",
            nom_a_contacter:"",
            telephone:"",
            email :"",
            password: "",
            activite:"",
            siret:"",
            tva:"",checkedErr : false, checked:false,
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.PieceOnChange = this.PieceOnChange.bind(this);
        this.onChangeDateChange = this.onChangeDateChange.bind(this);
    }

    onChangecheckbox(e){
        this.setState({checked : e.target.checked})
    }
    validateForm(){
        let error = false
        if(this.state.denomination === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({denominationErr: true})
            error = true
            document.getElementById('denomination').scrollIntoView()
            return error
        }else{
            this.setState({denominationErr: false})
            error = false
        }
        if(this.state.activite === ''){
            this.setState({activiteErr: true})
            error = true
            document.getElementById('activite').scrollIntoView()
            return error
        }
        else{
            this.setState({activiteErr: false})
            error = false
        }
        if(this.state.represenatnt_legal === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({represenatnt_legalErr: true})
            error = true
            document.getElementById('represenatnt_legal').scrollIntoView()
            return error
        }
        else{
            this.setState({represenatnt_legalErr: false})
            error = false
        }
        if(this.state.statut === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({statutErr: true})
            error = true
            document.getElementById('statut').scrollIntoView()
            return error
        }
        else{
            this.setState({statutErr: false})
            error = false
        }
        if(this.state.siret === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({siretErr: true})
            error = true
            document.getElementById('siret').scrollIntoView()
            return error
        }
        else{
            this.setState({siretErr: false})
            error = false
        }
        if(this.state.tva === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({tvaErr: true})
            error = true
            document.getElementById('tva').scrollIntoView()
            return error
        }
        else{
            this.setState({tvaErr: false})
            error = false
        }
        if(this.state.adresseSiege === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseSiegeErr: true})
            error = true
            document.getElementById('adresseSiege').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseSiegeErr: false})
            error = false
        }
        if(this.state.adresseFactur === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({adresseFacturErr: true})
            error = true
            document.getElementById('adresseFactur').scrollIntoView()
            return error
        }
        else{
            this.setState({adresseFacturErr: false})
            error = false
        }
        if(this.state.nom_a_contacter === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({nom_a_contacterErr: true})
            error = true
            document.getElementById('nom_a_contacter').scrollIntoView()
            return error
        }
        else{
            this.setState({nom_a_contacterErr: false})
            error = false
        }
        if(!validPhoneRegex.test(this.state.telephone.trim()) ){
            console.log('Veuillez remplir ce champ')
            this.setState({telephoneErr: true})
            error = true
            document.getElementById('telephone').scrollIntoView()
            return error
        }
        else{
            this.setState({telephoneErr: false})
            error = false
        }
        if(!validEmailRegex.test(this.state.email.trim())){
            console.log(validEmailRegex.test(this.state.email.trim()))
            this.setState({emailErr: true})
            error = true
            document.getElementById('email').scrollIntoView()
            return error
        }
        else{
            this.setState({emailErr: false})
            error = false
        }
        if(this.state.password === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({passwordErr: true})
            error = true
            document.getElementById('password').scrollIntoView()
            return error
        }
        else{
            this.setState({passwordErr: false})
            error = false
        }
        if(!this.state.checked){
            this.setState({checkedErr: true})
            error = true
            document.getElementById('checked').scrollIntoView()
            return error
        }
        else{
            this.setState({checkedErr: false})
            error = false
        }

        return error
    }


    deleteFile(v){
        let errors = this.state.errors;
        errors.piece =""
        if(v==="kbis"){
            this.setState({kbis : ""})
        }
        else {
            this.setState({rib : ""})
        }
    }
    onChange(e) {
        //this.setState({ [e.target.name]: e.target.value })

        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            /*case 'fullName':
                errors.fullName =
                    value.length < 5
                        ? 'Full Name must be 5 characters long!'
                        : '';
                break;*/
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : this.props.t('126');
                break;
            case 'confirm_email':
                errors.confirm_email =
                    value === this.state.email
                        ? ''
                        : this.props.t('127');
                break;
            /*case 'password':
                errors.password =
                    value.length < 8
                        ? 'Password must be 8 characters long!'
                        : '';
                break;*/
            case 'confirm_password':
                errors.confirm_password =
                    value === this.state.password
                        ? ''
                        : this.props.t('128');
                break;

            default:
                break;
        }

        this.setState({errors, [name]: value})
    }

    onChangeDateChange(date, dateString) {
        this.setState({date_naissance : dateString})
    }

    handleVisibleChange = visiblepop => {
        this.setState({ visiblepop });
    };





    /**
     * checking and saving piece in state
     * @param event
     * @constructor
     */
    PieceOnChange= event =>{
        // Adding File to State
        let errors = this.state.errors;
        if(checkFileSize(event) ){
            if (maxSelectFile(event)){
                if (checkPDFType(event) ){
                    switch (event.target.name) {
                        case "kbis" :
                            this.setState({kbis : event.target.files[0]})
                            break;
                        case "rib" :
                            this.setState({rib : event.target.files[0]})
                            break
                    }
                    errors.piece=""
                }
                else{
                    errors.piece = "Formats acceptés : PNG, JPG et PDF"

                }

            }
            else{
                errors.piece = "Vous devez selectionner une seule piece d'identité"

            }
        }
        else{
            errors.piece = "le document doit etre inferieure à 2 Mb"
        }
        this.setState({errors})
    }


    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    handleChange = adresse => {
        console.log('onchange',adresse)
        this.setState({ adresseSiege : adresse });

    };

    handleSelect = address => {
        console.log('on select : ',address)

        this.setState({ adresseSiege : address });
    };

    handleChange_add_facturation = adresse => {
        this.setState({ adresseFactur : adresse });
    };

    handleSelect_add_facturation = address => {
        this.setState({ adresseFactur : address });
    };

    handleSecteurActivi = value => {
        this.setState({ activite : value });
    }


    async onSubmit() {

        let valid = await this.validateForm()
            console.log("etat : ",valid)
        if(valid === false){
            this.setState({LoadingSubmit : true})
            await axios.post(`${BACKEND_API_URL}transporteur/entreprise/registration`, {
            adresseSociale: this.state.adresseSiege,
            adresseFacturation: this.state.adresseFactur,
            adresseEtab: this.state.adresseSiege,
            personneContact: this.state.nom_a_contacter,
            telephone: this.state.telephone,
            email: this.state.email.trim(),
            password: this.state.password,
            statutEntreprise: this.state.statut,
            denomination: this.state.denomination,
            siret: this.state.siret,
            tva: this.state.tva,
            secteurs: this.state.activite,
            nomRepresentant: this.state.represenatnt_legal
        }).then(async response => {
                console.log(response)
                this.setState({LoadingSubmit : false})
                if(!response.data.success){
                    if(response.data.message === 'Email déjà utilisé'){
                        openNotificationError('Adresse e-mail est déjà utilisée','')
                    }else
                        openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
                }else {
                    window.location.replace("/success_register");
                }

            }).catch(err=>{
                this.setState({LoadingSubmit : false})
                console.log(err.response)
                openNotificationError('Erreur','Une erreur s\'est produite veuillez réessayer')
            })
        }
    }

    render() {
        const {errors} = this.state;
        let width = window.innerWidth;
        const {t} = this.props
        const infoText = <span><p>{t('122')}</p>
    <p>{t('123')}</p>
    <p>{t('124')}</p>
</span>;
        // ********************************** DESKTOP VERS ***************************************

            return (
                <Container style={{maxWidth: "90vw", marginBottom : "70px"}}>
                    <Row className="inscri-expediteur">
                        <Col>
                            <h2>Entreprise de transport</h2>
                            <hr className={"hr-register"}/>
                        </Col>
                    </Row>
                    <Row className={"create-account"}>
                        <Col>
                        <h3>Création du compte</h3>
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="denomination"
                                id="denomination"
                                placeholder={"Dénomination sociale"}
                                value={this.state.denomination}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.denominationErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col >
                            <Select defaultValue="" className={"btn-piece"} id="activite"
                                    onChange={this.handleSecteurActivi}>
                                <Option value="">Secteur d’activités</Option>
                                <Option value="1 Transpor">Transporteur de frais</Option>
                                <Option value="2 Transpor">Transport de colis</Option>
                                <Option value="3 Transpor">Transport de mobilier</Option>
                                <Option value="4 Transpor">Transport médical</Option>
                                <Option value="Taxi">Taxi</Option>
                                <Option value="VTC">VTC</Option>
                                <Option value="Coursier">Coursier</Option>
                                <Option value="Autres">Autres</Option>
                        </Select>
                            {this.state.activiteErr === true &&
                            <span className='error'>Merci de choisir une activité</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="represenatnt_legal"
                                id="represenatnt_legal"
                                placeholder={"Nom du représentant légal"}
                                value={this.state.represenatnt_legal}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.represenatnt_legalErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="statut"
                                id="statut"
                                placeholder={"Statut de l'entreprise"}
                                value={this.state.statut}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.statutErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="tva"
                                id="tva"
                                placeholder={"N° de TVA"}
                                value={this.state.tva}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.tvaErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="siret"
                                id="siret"
                                placeholder={"N° de SIRET"}
                                value={this.state.siret}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.siretErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <hr className={"hr-register"}/>
                    <Row className={"create-account"}>
                        <Col>
                            <h3>Documents à télécharger</h3>
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <p>Extrait de KBIS (moins de 6 mois)</p>
                        </Col>
                        <Col>
                            <div className="upload-btn-wrapper">
                                <button className="btn-piece file">
                                    <label style={{float : "left"}}>Télécharger</label>
                                <label className={"tree-point"}>...</label>
                                </button>
                                <input
                                    type="file"
                                    name="kbis"
                                    onChange={this.PieceOnChange}
                                />
                                {(this.state.kbis !== undefined && this.state.kbis!== "") &&
                                <div>
                                <span >
                                    {this.state.kbis.name}</span>
                                    <span onClick={()=>{this.deleteFile("kbis")}} style={{cursor:"pointer"}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                </div>
                                }
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                            </div>

                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <p>RIB / IBAN</p>
                        </Col>
                        <Col xs={12} lg={true}>
                            <div className="upload-btn-wrapper">
                                <button className="btn-piece file">
                                    <label style={{float : "left"}}>Télécharger</label>
                                <label className={"tree-point"}>...</label>
                                </button>
                                <input
                                    type="file"
                                    name="rib"
                                    onChange={this.PieceOnChange}
                                />
                                {(this.state.rib !== undefined && this.state.rib!== "") &&
                                <div>
                                <span >
                                    {this.state.rib.name}</span>
                                    <span onClick={()=>{this.deleteFile("rib")}} style={{cursor:"pointer"}}>
                                        <IoIosCloseCircleOutline
                                            style={{color:"#0C4EFC"}} size={"25px"}/>
                                        </span>
                                </div>
                                }
                                {errors.piece.length >= 0 &&
                                <span className='error'>{errors.piece}</span>}
                            </div>

                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <PlacesAutocomplete
                                value={this.state.adresseSiege}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse du siège social',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseSiege"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseSiegeErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <PlacesAutocomplete
                                value={this.state.adresseFactur}
                                onChange={this.handleChange_add_facturation}
                                onSelect={this.handleSelect_add_facturation}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <i className="fa fa-map-marker-alt icon input-icon"></i>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Adresse de facturation',
                                                className: 'location-search-input',
                                            })}
                                            className="input-register"
                                            id="adresseFactur"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Chargement...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {this.state.adresseFacturErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>

                    </Row>

                    <hr className={"hr-register"}/>
                    <div className={'div-height'}/>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="nom_a_contacter"
                                id="nom_a_contacter"
                                placeholder={"Nom de la personne à contacter"}
                                value={this.state.nom_a_contacter}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.nom_a_contacterErr  === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                        <Col>
                            <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                placeholder={"N° de téléphone"}
                                value={this.state.telephone}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.telephoneErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                    </Row>

                    <Row className={"create-account-row"}>
                        <Col className={"input-col"} xs={12} lg={true}>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder={"Adresse e-mail"}
                                value={this.state.email}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.emailErr  === true &&
                            <span className='error'>Merci de saisir un format valide</span>}
                        </Col>
                        <Col>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder={"Mot de passe"}
                                value={this.state.password}
                                onChange={this.onChange}
                                className="input-register"
                                required={true}
                            />
                            {this.state.passwordErr === true &&
                            <span className='error'>Veuillez renseigner ce champ</span>}
                        </Col>
                    </Row>
                    <Row className={"create-account-row"}>
                        <Col className={"input-col"}>
                            <Checkbox onChange={(e)=>this.onChangecheckbox(e)}
                                      className={"check-cgu"} id={"checked"}>Je reconnais avoir
                                pris connaissance des <Link to={"/cgu"} target={"_blank"}>
                                    <label style={{color:"#1D1F39", fontWeight:"700", cursor:"pointer"}}>
                                        Conditions générales d'utilisation</label>
                                </Link> et les accepte.</Checkbox>
                            {this.state.checkedErr === true &&
                                <span className='error'>Veuillez cocher cette case</span>}
                        </Col>
                    </Row>

                    <Row className="inscri-expediteur">
                        <Col>
                            <Button className={"btn-continue"} onClick={()=>this.onSubmit()}
                            disabled={this.state.LoadingSubmit}>
                                {this.state.LoadingSubmit ? <div className="spinner">
                                    <Spinner animation="border" role="status" className={'spinner-btn'}
                                             variant="primary"/> </div>:
                                <span>Continuer</span>}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )

        //************************************** MOBILE VERS ***************************************

    }
}

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);


// const validateForm = (errors) => {
//     let valid = true;
//     Object.values(errors).forEach(
//         // if we have an error string set valid to false
//         (val) => val.length > 0 && (valid = false)
//     );
//     return valid;
// }

const underAgeValidate = (birthday) => {

    // convert from DD/MM/YYYY to yyyy/mm/dd
    var datearray = birthday.split("/");
    var newdate =  datearray[2] + '/' + datearray[1] + '/' + datearray[0] ;

    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    var optimizedBirthday = newdate.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

    if(myAge < 18) {
        return true;
    }else{
        return false;
    }

}


export default withTranslation()(Register_entr_transp)
