import React, { useState,Component } from 'react';
import Navbar from "react-bootstrap/Navbar";
import { Menu, Dropdown } from 'antd';
import frenchFlag from '../Images/french-flag.png';
import SpainFlag from '../Images/Spain_flag.png';
import BritainFlag from '../Images/Flag_Britain.png';
import {AccountCircle} from "@material-ui/icons";

import AccountCircle_mobile from '../Images/Account-circle.png';
import ItalyFlag from '../Images/italy.png';
import {Link, NavLink, Redirect} from "react-router-dom"; // Tell webpack this JS file uses this image
import {IsLoggedIn} from "../Helpers";
import Logo from "../Images/Logo.png"
import profile from "../Images/profile_pick.png"
import search from "../Images/search_pick.png"
import "./Navbar2.css"
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import {Drawer, Select} from "antd";
import { withTranslation } from 'react-i18next';
import Button from "antd/lib/button";
import i18next from "i18next";
import jwt from "jsonwebtoken";
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";

const { Option } = Select;


logout=(e)=>{

}





function logout(){
    localStorage.clear('usertoken')
    this.setState({navigate : true});
}


class NavBar extends Component {
    constructor() {
        super()
        this.state = {
            user:[],
            DrawerVisible : false,
            selectedLang : localStorage.getItem("i18nextLng") || "fr"
        }
        this.showDrawer = this.showDrawer.bind(this)
        this.onClose = this.onClose.bind(this)

    }

    showDrawer(){
        console.log("clicked")
        this.setState({
            DrawerVisible : true
            })
    };
    onClose(){
        console.log("click")
        this.setState({
            DrawerVisible : false
        })
    };

    state = {
        navigate: false
    };
    componentDidMount() {
        if (IsLoggedIn()){
           this.GetUserInfo()
        }
    }
    async GetUserInfo(){
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        console.log(decodedToken)
        this.setState({userId : decodedToken.userId,
            personneId : decodedToken.personneId})
        axios.post(`${BACKEND_API_URL}expediteur/getInfoUser`, {
            utilisateurId: this.state.userId,
        }).then(async res => {
            if(res.data.success){
                console.log('user Info : ', res.data);
                await this.setState({user :res.data.user})
                this.displayImage()
            }
        }).catch(err => console.log(err));
    }


    displayImage(){
        let imageURL =''
        if (this.state.user.personne.photo) {
            imageURL = BACKEND_ROOT_URL + this.state.user.personne.photo
        } else {
            imageURL = require('../Images/pick_site/user.png')
        }
        document.getElementById("navbar-img").src=imageURL;
    }

    async changeLang(value){
        await i18next.changeLanguage(value);
    }

    async changeLangMobile(value){
        await i18next.changeLanguage(value);
        this.onClose()
    }

    render() {
        const {t} = this.props

        const menu_mobile = (
            <Menu>
                <Menu.Item onClick={this.onClose}>
                    <NavLink
                        exact
                        to="/connexion-choix"
                        className="menu-a"
                    >
                        <span>Connexion</span>
                    </NavLink>

                </Menu.Item>
                <Menu.Item onClick={this.onClose}>
                    <NavLink
                        exact
                        to="/choix-inscription"
                        className="menu-a"
                    >
                        <span >Inscription</span>
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const menu = (
            <Menu>
                <Menu.Item className={"dropdown-nav"}>
                    <NavLink
                        exact
                        to="/connexion-choix"
                    >
                        Connexion
                    </NavLink>

                </Menu.Item>
                <Menu.Item className={"dropdown-nav"}>
                    <NavLink
                        exact
                        to="/choix-inscription"
                    >
                        Inscription
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const LoggedMenu_mobile = (
            <Menu>
                <Menu.Item>
                        <span className="menu-a" onClick={this.onClose}>
                            {this.state.user.Pseudonyme}</span>
                </Menu.Item>
                <Menu.Item>
                    <NavLink
                        exact
                        className="menu-a"
                        to="/login"
                        onClick={e=>{e.preventDefault();
                            localStorage.removeItem('usertoken');
                            console.log("deconnexion ...")
                            window.location.reload();                }}>
                        <span onClick={this.onClose}>Se déconnecter</span>
                    </NavLink>
                </Menu.Item>

            </Menu>
        );

        const LoggedMenu = (
            <Menu>
                <Menu.Item>
                    <NavLink exact to="/mon-compte">Mon compte</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/mes-expeditions">Mes expéditions</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/nouvelle-expedition">Nouvelle expédition</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink exact to="/mes-paiements">Mes paiements</NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink
                        exact
                        to="/login"
                        onClick={e=>{e.preventDefault();
                            localStorage.removeItem('usertoken');
                            console.log("deconnexion ...")
                            window.location.reload();                }}>
                        {t('66')}
                    </NavLink>
                </Menu.Item>

            </Menu>
        );


        let width = window.innerWidth;
        //console.log("width of the screen : " + width)

        if (width > 768){

            return (
                <div className="mt-2">
                    <Navbar bg="transparent" expand="lg" className="main-nav shadow-sm">
                        <Navbar.Brand href="/" style={{textAlign: "-webkit-center"}}>
                            <img src={Logo} height={"60px"} style={{verticalAlign:"super"}} />
                        </Navbar.Brand>
                        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <nav className="navbar" >
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/"
                                >
                                    Accueil
                                </NavLink>
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/choix-inscription-expediteur"
                                >
                                    Expéditeur
                                </NavLink>
                                <NavLink
                                    exact
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/choix-inscription-transpo"
                                >
                                    Transporteur
                                </NavLink>
                                <NavLink
                                    activeClassName="navbar__link--active"
                                    className="navbar__link"
                                    to="/contact"
                                >
                                    Contact
                                </NavLink>

                            </nav>
                        </Navbar.Collapse>
                        {IsLoggedIn() ?
                            (
                                <label className="name">
                                    {this.state.user.Pseudonyme}</label>
                            )   :
                            (<label/>)}

                        <Dropdown overlay={IsLoggedIn() ? LoggedMenu : menu} className="nav-link"
                                  placement="bottomCenter">
                            <a className="ant-dropdown-link" style={{marginBottom : "10px"}}
                               onClick={e => e.preventDefault()}>
                                {IsLoggedIn() ?
                                    (
                                        <img id="navbar-img" style={{borderRadius: "50%", width :"60px", height:"60px"}}/>
                                    )   :
                                    (<img src={profile} style={{width:"20px"}} />)}
                            </a>
                        </Dropdown>
                    </Navbar>
                </div>
            );
        }
        /*
        * *****************************************************************************
        * **************************** VERSION MOBILE ********************************
        * *****************************************************************************
        * */
        else {
            return (<div className="mt-2" >
                <Container fluid style={{marginTop : "25px"}}>
                    <Row className="main-nav-mobile">
                        <Col xs={9} sm={7} >
                            <Link to={"/"} >
                            <img src={Logo} width={"250px"} style={{verticalAlign:"top"}} />
                            </Link>
                        </Col>
                        <Col style={{marginTop: "-10px"}}>
                            <Button onClick={this.showDrawer} style={{height:"auto"}}>
                                <MenuOutlined style={{fontSize : "30px", color : "#1D1F39"}}/>
                            </Button>
                        </Col>
                    </Row>

                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.DrawerVisible}
                        getContainer={false}
                        style={{ position: 'fixed' }}
                    >
                        <Container fluid style={{padding : "0"}}>
                            <Row style={{padding: "5px 20px", paddingTop : "50px", alignItems:'center'}}>
                                <Col xs={8}>
                                    {IsLoggedIn() ? LoggedMenu_mobile : menu_mobile}
                                </Col>
                                <Col>
                                    <NavLink exact to="/mon-compte">
                                        {IsLoggedIn() ?
                                            (
                                                <img id="navbar-img" style={{borderRadius: "50%", width :"50px",
                                                height:'50px'}}
                                                     onClick={this.onClose}/>
                                            )   :
                                            (<img src={profile} alt="account circle"
                                                  onClick={this.onClose}
                                            />)}
                                    </NavLink>

                                </Col>
                            </Row>

                            <div className={"back-drawer"}>
                                {/*<Row className = "mobile-men">
                                    <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/nous_connaitre"
                                    >
                                        <span onClick={this.onClose}>Accueil</span>
                                    </NavLink>
                                </Row>*/}
                                {IsLoggedIn() &&
                                <>
                                    <Row className = "mobile-men">
                                        <NavLink
                                            exact
                                            className="navbar__link nav-mobile"
                                            to="/mon-compte"
                                        >
                                            <span onClick={this.onClose}>Mon compte</span>
                                        </NavLink>
                                    </Row>
                                    <Row className = "mobile-men">
                                        <NavLink
                                            exact
                                            className="navbar__link nav-mobile"
                                            to="/mes-expeditions"
                                        >
                                            <span onClick={this.onClose}>Mes expéditions</span>
                                        </NavLink>
                                    </Row>
                                    <Row className = "mobile-men">
                                        <NavLink
                                            exact
                                            className="navbar__link nav-mobile"
                                            to="/nouvelle-expedition"
                                        >
                                            <span onClick={this.onClose}>Nouvelle expédition</span>
                                        </NavLink>
                                    </Row>
                                    <Row className = "mobile-men">
                                        <NavLink
                                            exact
                                            className="navbar__link nav-mobile"
                                            to="/mes-paiements"
                                        >
                                            <span onClick={this.onClose}>Mes paiements</span>
                                        </NavLink>
                                    </Row>
                                </>}

                            <Row className = "mobile-men">
                                    <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/choix-inscription-expediteur"
                                    >
                                        <span onClick={this.onClose}>Expéditeur</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        exact
                                        className="navbar__link nav-mobile"
                                        to="/choix-inscription-transpo"
                                    >
                                        <span onClick={this.onClose}>Transporteur</span>
                                    </NavLink>
                            </Row>
                            <Row className = "mobile-men">
                                    <NavLink
                                        className="navbar__link nav-mobile"
                                        to="/contact"
                                    >
                                        <span onClick={this.onClose}>Contact</span>
                                    </NavLink>
                            </Row>
                            </div>
                        </Container>
                    </Drawer>

                </Container>

            </div>)
        }

    }
}

export default withTranslation()(NavBar);




