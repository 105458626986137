
/*export const BACKEND_ROOT_URL = "http://192.168.1.5:8080";
export const BACKEND_API_URL = "http://192.168.1.5:8080/api/";*/

export const GOOGLE_MAP_API_KEY = "AIzaSyCmYcdh9JlS6WYRSQ5G6YIYK40HQ5pUIis";
export const STRIPE_PUBLIC_KEY = "pk_live_51JIaMoCob5RvkFZx83awfGlhCmi4pnFG6gJEuDCCggMRkoyvBoPKCeL5ZJb3YvpQN9wXWGNYLnAG5X3qJDaBUiig00osYZ4SEj";
export const STRIPE_SECRET_KEY = "sk_test_51JIaMoCob5RvkFZxgO0CH8kwERWyUjcZ6HkY2Md7zPuedFcPLqv8zQ42nvN0pLhqyFL1pbytZNeXH5AMM6hNArKm00dNZMo0Ni";

export const BACKEND_ROOT_URL = "https://master.d2lnsdxdmm7xhb.amplifyapp.com";
export const BACKEND_API_URL = "https://master.d2lnsdxdmm7xhb.amplifyapp.com/api/";

