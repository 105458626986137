import React, { Component } from 'react'
import {Visibility} from "@material-ui/icons";
import './login.css';
import './Register.css'
import {Link} from "react-router-dom";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {Checkbox, message, Input} from "antd";
import {CheckCountry, getIP, openNotificationError} from "../Helpers";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import Button from "antd/es/button";
import {BACKEND_API_URL} from "../env";

let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            PasswordVisibility: true,
            loadingLogin : false,
            SeSouvenir : false,
            emailErr : false,
            passwordErr : false,
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleShow = this.toggleShow.bind(this)
        this.onChange_Souvenir = this.onChange_Souvenir.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }


    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChange_Souvenir (e){
        this.setState({SeSouvenir : e.target.checked})
    }

    /**
     * For showing and hidden password
     */
    toggleShow(){
        this.setState({ PasswordVisibility: !this.state.PasswordVisibility });
    }

    validateForm(){
        let error = false

        if(!validEmailRegex.test(this.state.email.trim())){
            console.log(validEmailRegex.test(this.state.email.trim()))
            this.setState({emailErr: true})
            error = true
            document.getElementById('email').scrollIntoView()
            return error
        }
        else{
            this.setState({emailErr: false})
            error = false
        }
        if(this.state.password === ''){
            console.log('Veuillez remplir ce champ')
            this.setState({passwordErr: true})
            error = true
            document.getElementById('password').scrollIntoView()
            return error
        }
        else{
            this.setState({passwordErr: false})
            error = false
        }
        return error
    }



    async onSubmit() {
        let valid = await this.validateForm()
        console.log("etat : ",valid)
        if(valid === false){
            this.setState({loadingLogin : true})
            await axios.post(`${BACKEND_API_URL}expediteur/login`, {
                    email: this.state.email.trim(),
                    password: this.state.password,
                })
                .then(async response => {
                    console.log(response)
                    this.setState({loadingLogin : false})
                    if(response.data.error){
                            openNotificationError(response.data.errorMessage,'')
                    }else if(response.data.success) {
                        console.log(response.data.token)
                        localStorage.setItem('usertoken', response.data.token)
                        window.location.replace("/nouvelle-expedition")
                    }
                })
                .catch(err => {
                    this.setState({loadingLogin : false})
                    message.error('Une erreur s\'est produite veuillez réessayer',5.5)
                })

        }

    }


    render() {

        const {t} = this.props;

        let width = window.innerWidth;
            return (
                    <Container className={'pages-container'}>
                        <Row className="inscri-expediteur">
                            <Col>
                                <h2>Connexion Expéditeur :</h2>
                                <hr/>
                                {/*<h3 className={"expedieteur-connect"}>Expéditeur</h3>*/}
                            </Col>
                        </Row>
                        <Row className={"input-login"}>
                            <Col>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder='Adresse e-mail'
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        required={true}
                                        className="input-register"
                                    />
                                    {this.state.emailErr  === true &&
                                    <span className='error'>Merci de saisir un format valide</span>}
                                    </div>
                                    <div className="form-group">
                                        <Input.Password name="password" placeholder="Mot de passe"
                                            value={this.state.password} className={"input-pass"}
                                                        id={'password'}
                                            onChange={this.onChange} required={true} />
                                        {this.state.passwordErr === true &&
                                        <span className='error'>Veuillez renseigner ce champ</span>}
                                    </div>
                            <div className={"pass-oublie"}>
                                <Link to="/forgotPassword">Mot de passe oublié ?</Link>
                            </div>
                                <Row className={'row-ou'}>
                                    <Col>
                                        <hr style={{borderTop: "2px solid #1D1F39"}}/>
                                    </Col>
                                    <Col className={'col-ou'}>
                                        <h5 className={'h5-bottom'}>OU</h5>
                                    </Col>
                                    <Col>
                                        <hr style={{borderTop: "2px solid #1D1F39"}}/>
                                    </Col>
                                </Row>
                                <div className={"pass-oublie"}>
                                    <Link to="/choix-inscription-expediteur">
                                        <b>Créer</b> un nouveau compte Expéditeur</Link>
                                </div>
                                <div className={"pass-oublie"}>
                                <Button className={"btn-continue"} type="submit"
                                        onClick={()=>this.onSubmit()}
                                        disabled={this.state.loadingLogin}>
                                    {this.state.loadingLogin ? <div className="spinner">
                                            <Spinner animation="border" role="status" className={'spinner-btn'}
                                                     variant="primary"/> </div>:
                                        <span>Connexion</span>}
                                </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            )

    }
}
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


export default withTranslation()(Login)
