import React, {Component, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home1/home1.css'
import {Col} from "react-bootstrap";
import Button from "antd/es/button";
import {Link} from "react-router-dom";

//**************** import images ********************


const Inscription_expediteur = () =>{

        return (
                <Container fluid style={{minHeight : "75vh"}} className={'inscription'}>
                    <Row className="inscri-expediteur">
                    <Col>
                        <h2 className={'title-form'}>Inscription Expéditeur :</h2>
                        <hr/>
                        <p>Vous êtes ?</p>
                    </Col>
                </Row>
                    <Row>
                        <Col md={3}/>
                        <Col md={3} style={{textAlign : "center"}}>
                            <Link to={"/register-exped-partic"}>
                                <Button className={"btn-inscr"}>Particulier</Button>
                            </Link>
                        </Col>
                        <Col md={3} style={{textAlign : "center"}}>
                            <Link to={"/register-exped-entr"}>
                                <Button className={"btn-inscr"}>Entreprise</Button>
                            </Link>
                        </Col>
                        <Col md={3}/>
                    </Row>
                </Container>
        );
    }



export default Inscription_expediteur;
