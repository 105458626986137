import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './HeaderNavbarDashboard.css'
import {NavLink} from "react-router-dom";
import i18n from '../i18n/i18n'
import { withTranslation } from 'react-i18next';

class HeaderNavbarDashboard extends Component {
    render() {
        const {t} = this.props;

        let width = window.innerWidth;
        //********************* DESKTOP VER  **************************
        if (width > 768){
            return (
                <Container fluid className="headerNav">
                    <Row>
                        <NavLink className="column col" style={{borderLeft:"5px solid white",borderRadius: "30px 0 0 0"}}
                                 to={"/moncompte"} activeClassName="selected">
                            <h5>{t('141')}</h5>
                        </NavLink>
                        <NavLink className="column col" style={{borderLeft:"5px solid white"}} to={"/Tirage"}
                                 activeClassName="selected">
                            <h5>{t('142')}</h5>
                        </NavLink>
                        <NavLink className="column col" style={{borderLeft:"5px solid white",borderRadius: "0 30px 0 0"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5>{t('143')}</h5>
                        </NavLink>
                    </Row>

                </Container>
            );
        }

        //****************** MOBILE VER  ******************************
        else {
            return (
                <Container fluid className="headerNav">
                    <Row>
                        <NavLink className="column col" style={{borderLeft:"5px solid white",borderRadius: "30px 0 0 0"}}
                                 to={"/moncompte"} activeClassName="selected">
                            <h5>{t('141')}</h5>
                        </NavLink>
                    </Row>
                    <Row>
                        <NavLink className="column col" style={{borderLeft:"5px solid white"}} to={"/Tirage"}
                                 activeClassName="selected">
                            <h5>{t('142')}</h5>
                        </NavLink>
                    </Row>
                    <Row>
                        <NavLink className="column col" style={{borderLeft:"5px solid white",borderRadius: "0 30px 0 0"}}
                                 to={"/assistance"} activeClassName="selected">
                            <h5>{t('143')}</h5>
                        </NavLink>
                    </Row>

                </Container>
            );
        }

    }
}

export default withTranslation()(HeaderNavbarDashboard);
