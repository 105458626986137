import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {Input, Select} from "antd";
import axios from "axios";
import enlevement from '../Images/pick_site/enlevement.png'
import livraison from '../Images/pick_site/destination.png'
import {BACKEND_API_URL} from "../env";
import jwt from "jsonwebtoken";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import {FormatDate, FormatDateNoTime} from "../Helpers";

let bgcolor="#fff"

class MesPaiements extends Component {
    constructor() {
        super()
        this.state = {
            CommandesSansLivreur: [],
            LoadingSubmit: false,
            loadingData: false,
            userId:'',ListeCommandes: []
        }
        this.onChange = this.onChange.bind(this)


    }

    componentDidMount() {
        window.scrollTo(0, 0)

        this.GetListePaiements()
    }

    async GetListePaiements() {
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        this.setState({userId : decodedToken.userId})
        this.setState({loadingData : true})
        axios.post(`${BACKEND_API_URL}commandes/listePaiements`, {
            utilisateurId: this.state.userId
        })
            .then(res => {
                res.data.commandes.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                this.setState({ ListeCommandes: res.data.commandes });
                console.log(res.data.commandes)
                this.setState({loadingData : false})
            }).catch(err => {console.log(err)
            this.setState({loadingData : false})
        });
    }


    F_OnShowListeCommandes() {
        return (<>
            <Row className={"paim-title"}>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title-paiement"}>Date</span>
                </Col>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title-paiement"}>Enlèvement</span>
                </Col>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title-paiement"}>Livraison</span>
                </Col>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title-paiement"}>Montant</span>
                </Col>
            </Row>
            {this.state.ListeCommandes.map((item) => {
                if(bgcolor === "#F1F1F1"){bgcolor="#fff"}
                else {bgcolor="#F1F1F1"}
                return (
                    <Row className={'paim-data'} style={{backgroundColor:bgcolor}}>
                        <Col className={'hist-title'} xs={3}>
                        <span className={'paiement-data'}>{FormatDateNoTime(item.encaissement.createdAt)}
                        </span>
                        </Col>
                        <Col className={'hist-title'} xs={3}>
                            <span className={'paiement-data'}>{item.VilleDepart}<br/>{item.CPDepart}</span>
                        </Col>
                        <Col className={'hist-title'} xs={3}>
                            <span className={'paiement-data'}>{item.VilleArrivee} <br/> {item.CPArrivee}</span>
                        </Col>
                        <Col className={'hist-title'} xs={3}>
                        <span className={'paiement-data paiement'}>{Number(item.encaissement.montant).toFixed(2)
                            .toString().replace(".", ",")} € TTC
                        </span>
                        </Col>
                    </Row>
                )
            })}
        </>)
    }

    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})
    }

    async onSubmit() {
    }

    render() {
        return (<Container fluid className={'dashboard-pages'}>
                <Row className="profile">
                    <Col md={'auto'}>
                        <VerticalNavbar2/>
                    </Col>
                    <Col className={'right-dashboard'}>
                        <Row className="inscri-expediteur">
                            <Col>
                                <h2> Mes Paiements</h2>
                                <hr className={"hr-register hr-dash"}/>
                            </Col>
                        </Row>
                        {this.F_OnShowListeCommandes()}
                    </Col>
                </Row>
            </Container>
        );


    }
}

export default MesPaiements
