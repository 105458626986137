import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {Input, notification, Select} from "antd";
import axios from "axios";
import enlevement from '../Images/pick_site/enlevement.png'
import livraison from '../Images/pick_site/destination.png'
import {BACKEND_API_URL, BACKEND_ROOT_URL} from "../env";
import jwt from "jsonwebtoken";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import {FormatDate, FormatDateNoTime} from "../Helpers";
import {Link} from "react-router-dom";
import {saveAs} from "file-saver";
import {GenerateFactureNumber} from "../Helpers/Facture";
import ColisArrive from "./ColisArrive";

const validPhoneRegex = RegExp(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/);
const { Option } = Select;
let options = {
    componentRestrictions: {country: "fr"}
}
const { TextArea } = Input;

let interval=""
let interval2=""
class MesExpeditions extends Component {
    constructor() {
        super()
        this.state = {
            CommandesSansLivreur: [],
            LoadingSubmit: false,
            userId:'',ListeCommandes: [],ListeExpeditionsRunning: [],
            CanceledCommandes : [],
            colisLivre:false, colisArriveData:""
        }
        this.onChange = this.onChange.bind(this)


    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.GetListeExpeditionsRunning()
    }
    componentWillUnmount() {
        if(interval){
            clearInterval(interval)
        }
        if(interval2){
            clearInterval(interval2)
        }
    }

    getLivraisonNonValide(){
        axios.post(`${BACKEND_API_URL}livraison/getLivraisonNonValide`, {
            userId: this.state.userId
        }).then(res => {
            if (res.data.success){
                console.log('noooooooooooooooooooooooooooooooooooooooooo',res.data.livraison.commande.enlevement)
                notification['success']({
                    message: 'La marchandise a été livrée !',
                    description:"",
                    duration: 10,
                });
                this.setState({colisLivre : true, colisArriveData : res.data.livraison})
                if(interval2){
                    clearInterval(interval2)
                }
                /*return this.props.navigation.replace('ColisArrive', { demande: res.data.livraison });*/
            }

        }).catch(err => console.log(err));
    }
    async GetListeExpeditionsRunning() {
        const token = localStorage.usertoken;
        let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
        this.setState({userId : decodedToken.userId})
        axios.post(`${BACKEND_API_URL}expediteur/listeExpeditionsRunning`, {
            utilisateurId: this.state.userId
        }).then(res => {
            res.data.demandes.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.commande.createdAt) - new Date(a.commande.createdAt);
            });
            console.log('reeeeees',res.data.demandes);
            this.setState({ListeExpeditionsRunning: res.data.demandes.reverse()});

        }).catch(err => console.log(err));
        this.getLivraisonNonValide()
        this.DemandeSearchDeliver()
        this.GetListeCommande()
        this.CanceledCommande()
        interval = setInterval(() => {this.DemandeSearchDeliver();
        this.continueGetListeExpeditionsRunning();this.CanceledCommande()}, 15000)
        interval2 = setInterval(() => {this.getLivraisonNonValide()}, 15000)
    }

    async continueGetListeExpeditionsRunning() {
        axios.post(`${BACKEND_API_URL}expediteur/listeExpeditionsRunning`, {
            utilisateurId: this.state.userId
        }).then(res => {
            res.data.demandes.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.commande.createdAt) - new Date(a.commande.createdAt);
            });
            console.log('reeeeees',res.data.demandes);
            this.setState({ListeExpeditionsRunning: res.data.demandes.reverse()});

        }).catch(err => console.log(err));
    }
    DemandeSearchDeliver(){
        console.log('id',this.state.userId)
        axios.post(`${BACKEND_API_URL}expediteur/listeExpeditionsSearchDeliver`, {
            utilisateurId: this.state.userId
        }).then(res => {
            res.data.demandes.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.commande.createdAt) - new Date(a.commande.createdAt);
            });            this.setState({CommandesSansLivreur : res.data.demandes})
        }).catch(err => console.log(err));
    }

    async F1_createPDF(livraisonId,dateFin) {
        let num = await GenerateFactureNumber(livraisonId, dateFin,'FTE')
        console.log("num ",num)
        await saveAs(BACKEND_ROOT_URL + '/Factures/F1/' + num[1]+'.pdf', num[1])
    }

    async F2_createPDF(livraisonId,dateFin) {
        let num = await GenerateFactureNumber(livraisonId, dateFin,'FE')
        console.log("num ",num)
        await saveAs(BACKEND_ROOT_URL + '/Factures/F2/' + num[1]+'.pdf',num[1])
    }

    GetListeCommande(){
        axios.post(`${BACKEND_API_URL}commandes/listeCommandeClientParticulier`, {
            utilisateurId: this.state.userId
        })
            .then(res => {
                console.log(res.data)
                res.data.commandes.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                this.setState({ListeCommandes: res.data.commandes});
            }).catch(err => console.log(err));
    }
    CanceledCommande(){
        axios.post(`${BACKEND_API_URL}/commandes/listCanceledCommande`, {
            utilisateurId: this.state.userId
        }).then(res => {
            res.data.commandes.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            this.setState({CanceledCommandes : res.data.commandes})
        }).catch(err => console.log(err));
    }

    F_OnShowDemandeEnRechercheLivreur() {
        if (this.state.CommandesSansLivreur.length>0) {
            let liste = this.state.CommandesSansLivreur.map((dem,index)=>{
                return (<Link to={{pathname: "/mes-expeditions/details-expedition/"+dem.commandeId}}>
                        <Row className={"row-search"}>
                            <Col className={'vl'} lg={3} xs={3}>
                                <span className={'center-vertical'}>Recherche d'un transporteur...</span>
                            </Col>
                            <Col className={'little-vl'} lg={1} xs={1}>
                                <img src={enlevement} className={'img-mes-exp'}/>
                            </Col>
                            <Col className={'adresses-city'} xs={2}>
                                <span>{dem.commande.VilleDepart}<br/>
                                    {dem.commande.CPDepart}</span>
                            </Col>
                            <Col className={'adresses-city'} lg={0} xs={0}>
                                <RightOutlined className={'rightIcon'}/>
                            </Col>
                            <Col className={'little-vl'} xs={1}>
                                <img src={livraison} className={'img-mes-exp'}/>
                            </Col>
                            <Col className={'vl adresses-city'} xs={2}>
                                <span>{dem.commande.VilleArrivee}<br/>
                                    {dem.commande.CPArrivee}</span>
                            </Col>
                            <Col lg={2} xs={2} className={'delete-padding'}>
                                <span className={"hist-price"}>{Number(dem.commande.encaissement.montant)
                                    .toFixed(2).toString()
                                    .replace(".", ",")} € TTC</span>
                            </Col>
                        </Row>
                    </Link>
                );
            });
            return liste;

        }
    }

    F_OnShowRunningExpedition(){
        if (this.state.ListeExpeditionsRunning.length>0) {
            let liste = this.state.ListeExpeditionsRunning.map((dem,index)=>{
                return (<Link to={{pathname: "/mes-expeditions/details-expedition/"+dem.commandeId}}>
                        <div className={"row-search"}>
                        <Row >
                            <Col className={'vl'} lg={3} xs={3}>
                                <Row className={'pic-name'}>
                                    <Col lg={'4'} xs={4} className={'delete-padding'}>
                                        <img className={"transp-picture"}
                                            src = {(dem.transporteur.personne.personne.photo != null)
                                                ? BACKEND_ROOT_URL + dem.transporteur.personne.personne.photo
                                                : require('../Images/pick_site/user.png')}
                                        />
                                    </Col>
                                    <Col className={'delete-padding'} lg={8} xs={8}>
                                        <span className={"transp-titl"}>Transporteur</span><br/>
                                        <span className={"transp-name mesexped"}>
                                    {dem.transporteur.personne.prenoms + " " + dem.transporteur.personne.nom}
                                        </span>
                                    </Col>
                                </Row>


                            </Col>
                            <Col className={'little-vl'} lg={1} xs={1}>
                                <img src={enlevement} className={'img-mes-exp'}/>
                            </Col>
                            <Col className={'adresses-city'} xs={2}>
                                <span>{dem.commande.VilleDepart}<br/>
                                    {dem.commande.CPDepart}</span>
                            </Col>
                            <Col className={'adresses-city'} lg={0}>
                                <RightOutlined className={'rightIcon'}/>
                            </Col>
                            <Col className={'little-vl'} xs={1} >
                                <img src={livraison} className={'img-mes-exp'}/>
                            </Col>
                            <Col className={'vl adresses-city'} xs={2}>
                                <span>{dem.commande.VilleArrivee}<br/>
                                    {dem.commande.CPArrivee}</span>
                            </Col>
                            <Col lg={2} xs={2} className={'delete-padding'}>
                                <span className={'hist-price'}>{Number(dem.commande.encaissement.montant)
                                    .toFixed(2)
                                    .toString().replace(".", ",")} € TTC</span>
                            </Col>
                        </Row>
                            <Row className={"en-cours"}>
                                <span>Livraison en cours</span>
                            </Row>
                        </div>
                    </Link>
                );
            });
            return liste;

        }
    }

    F_OnShowListeCommandes() {
        let liste = this.state.ListeCommandes.map((item) => {
        return (<>
            <Row>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Date</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Enlèvement</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Livraison</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Montant</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>État</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Voir</span>
                </Col>
            </Row>
                <Row className={'hist-data'}>
                    <Col className={'hist-title'} xs={2}>
                        <span>{FormatDateNoTime(item.createdAt)}</span>
                    </Col>
                    <Col className={'hist-title'} xs={2}>
                        <span>{item.VilleDepart}</span><br/>
                        <span>{item.CPDepart}</span>
                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <span>{item.VilleArrivee}</span><br/>
                        <span>{item.CPArrivee}</span>
                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <span>{Number(item.encaissement.montant).toFixed(2)
                            .toString().replace(".", ",")} € TTC</span>
                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <span>{item.demandelivraisons[0].livraison.statutlivraisons[0].statutLivraisonTypeLibelle}
                        </span>

                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <img onClick={()=>{this.F1_createPDF(item.demandelivraisons[0]
                            .livraison.livraisonId,item.demandelivraisons[0].livraison.dateFin)}}
                             src={require("../Images/pick_site/facture.png")}/>
                        <img onClick={()=>{this.F2_createPDF(item.demandelivraisons[0]
                            .livraison.livraisonId,item.demandelivraisons[0].livraison.dateFin)}}
                             src={require("../Images/pick_site/facture.png")}/>
                    </Col>
                </Row>
        </>)
        })
        return liste
}

    F_OnShowCanceledListeCommandes() {
        let liste = this.state.CanceledCommandes.map((item) => {
        return (<>
            <Row>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Date</span>
                </Col>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title"}>Enlèvement</span>
                </Col>
                <Col className={'hist-title'} xs={3}>
                    <span className={"title"}>Livraison</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>Montant</span>
                </Col>
                <Col className={'hist-title'} xs={2}>
                    <span className={"title"}>État</span>
                </Col>
            </Row>
                <Row className={'hist-data'}>
                    <Col className={'hist-title'} xs={2}>
                        <span>{FormatDateNoTime(item.createdAt)}</span>
                    </Col>
                    <Col className={'hist-title'} xs={3}>
                        <span>{item.VilleDepart}</span><br/>
                        <span>{item.CPDepart}</span>
                    </Col>
                    <Col xs={3} className={'hist-title'}>
                        <span>{item.VilleArrivee}</span><br/>
                        <span>{item.CPArrivee}</span>
                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <span>{Number(item.encaissement.montant).toFixed(2)
                            .toString().replace(".", ",")} € TTC</span>
                    </Col>
                    <Col xs={2} className={'hist-title'}>
                        <span>Commande annulée</span>
                    </Col>
                </Row>
        </>)
        })
        return liste
}

    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})
    }

    searchNonValide() {
        this.setState({colisLivre : false})
    }

    render() {
        return (<Container fluid className={'dashboard-pages'}>
                <Row className="profile">
                    <Col md={'auto'}>
                        <VerticalNavbar2/>
                    </Col>
                    <Col className={'right-dashboard'}>
                        {this.state.colisLivre ?
                            <ColisArrive demande={this.state.colisArriveData}
                                         parentCallback = {()=>this.searchNonValide()}/> :
                            <>
                                <Row className="inscri-expediteur">
                                    <Col>
                                        <h2>Mes expéditions</h2>
                                        <hr className={"hr-register hr-dash"}/>
                                    </Col>
                                </Row>
                                {this.F_OnShowRunningExpedition()}
                                {this.state.CommandesSansLivreur.length>0 &&
                                    <hr className={"hr-dash mobile"}/>
                                }
                                {this.F_OnShowDemandeEnRechercheLivreur()}
                                {this.state.CommandesSansLivreur.length>0 &&
                                <hr className={"hr-dash mobile"}/>}
                                {this.F_OnShowListeCommandes()}
                                {this.state.CanceledCommandes.length>0 &&
                                <hr className={"hr-dash mobile"}/>}
                                {this.F_OnShowCanceledListeCommandes()}
                            </>
                        }
                        {/**/}
                    </Col>
                </Row>
            </Container>
        );


    }
}

export default MesExpeditions
