import {BACKEND_API_URL} from "../env";
import axios from "axios";

export async function GenerateFactureNumber(livraisonId,_date,type) {
    let _num ="e"
    let bonliv = ""
    let numfact = ""
    await axios.post(`${BACKEND_API_URL}commandes/numeroFacture`,
            { livraisonId: livraisonId })
        .then(response => {
            console.log(response.data)
            let num = ('0000' + response.data.numero).slice(-5)
            num = 'BL' + num
            let date = new Date(response.data.date);
            let day = date.getDate();
            let month = date.getMonth()+1;
            let year = date.getFullYear();
            let MyDateString = ('0' + day).slice(-2)+
                ('0' + (month)).slice(-2) + ('' + year);
            num = num + MyDateString
            console.log(num)
            _num = num

            bonliv = num
            numfact = type + _num
        })
    return [bonliv,numfact]
}
