import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFR from "./translations/fr";
import translationEN from "./translations/en";
import translationES from "./translations/es";
import translationIT from "./translations/it";

const fallbackLng = ["fr"];
const availableLanguages = ["fr", "en", "es", "it"];

const resources = {
    fr: {
        translation: translationFR
    },
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    it: {
        translation: translationIT
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        react: {
            useSuspense: false,
            wait: false,
        },
        detection: {
            checkWhitelist: true
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
