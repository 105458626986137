import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import NouvelleExpedForm from "../components/NouvelleExpedForm";
import RecapCommande from "../components/RecapCommande";
import {GetNatureColis} from "../Services";
import PaiementCommande from "../components/PaiementCommande";


class NouvelleExpedition extends Component {
    constructor() {
        super()
        this.state = {
            step : 1,
            FormData:{}
        }

    }
    componentDidMount() {
        GetNatureColis()
    }

    handleCallbackForm = (FormData, NextStep, PrevStep) =>{
        let temp = this.state.step
        this.setState({FormData: FormData})
        console.log('daata',this.state.FormData)
        if (NextStep){
            temp++
            this.setState({
                step : temp
            })
        }
        if (PrevStep){
            temp--
            this.setState({
                step : temp
            })
        }
    }

    render() {
            return (
                <Container fluid className={'dashboard-pages'}>
                    <Row className="profile">
                        <Col md={'auto'}>
                            <VerticalNavbar2/>
                        </Col>
                        {this.state.step === 1 &&
                            <NouvelleExpedForm
                                ExpeditionData = {this.state.FormData}
                                parentCallback = {this.handleCallbackForm}/>
                        }
                        {this.state.step === 2 &&
                            <RecapCommande ExpeditionData = {this.state.FormData}
                                           parentCallback = {this.handleCallbackForm}
                            />
                        }
                        {this.state.step === 3 &&
                            <PaiementCommande ExpeditionData = {this.state.FormData}
                                           parentCallback = {this.handleCallbackForm}
                            />
                        }
                    </Row>
                </Container>
            );


    }
}

export default NouvelleExpedition
