import React, { Component } from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import {IsLoggedIn} from "../Helpers";
import {Input, notification, Select,Button, Modal} from "antd";
import VerticalNavbar_assistance from "../components/VerticalNavbar_assistance";
import axios from "axios";
import HeaderNavbarDashboard from "../components/HeaderNavbarDashboard";
import {NavLink} from "react-router-dom";
import arrow_bottom from "../Images/arrow-bottom.png";
import arrow from "../Images/arrow-right.png";
import {withTranslation} from "react-i18next";
import "./contact.css"
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";


const {Option} = Select;
const { TextArea } = Input;
const key = `open${Date.now()}`;

const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
    </Button>
);

class Contact extends Component {
    constructor() {
        super()
        this.state = {
            user:[],
            nom :"",
            prenom :"",
            email:"",
            num_tele:"",
            message:"",
            sujet:"",
            sendEmail : false,
            visible : false

        }
        this.onChange = this.onChange.bind(this);
        this.handleSujetChange = this.handleSujetChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }


    openNotificationWithIcon = (statut) => {
        if(statut === 200){
                Modal.success({
                    closable:true,
                    icon:"",
                    content: (
                        <div>
                            <p>Message envoyé</p>
                            <hr/>
                            <p>Votre message a bien été reçu par l’équipe de PICK&DELI</p>
                        </div>
                    )
                });
        }
        else
        {
            notification['error']({
                message: this.props.t('203'),
            });
        }

    };

    componentDidMount() {
        window.scrollTo(0, 0)
        fetch('/api/users/profile')
            .then(res => {
                console.log(res);
                return res.json()
            })
            .then(user => {
                console.log(user);
                this.setState({ user,
                    nom : user.first_name , prenom : user.last_name, email:user.email, num_tele :user.Num_telephone
                })
            });

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSujetChange(value) {
        let val = value;
        console.log(val)
        this.setState({
            sujet: val
        })
    }
    onSubmit(e){
        e.preventDefault();
        this.setState({sendEmail : true})
        axios.post('api/nouscontacter',{
            nom     : this.state.nom,
            prenom  : this.state.prenom,
            email   :this.state.email,
            num_tele:this.state.num_tele,
            message :this.state.message,
            sujet   :this.state.sujet
        })
            .then(res => {
                if (res.status === 200){
                    this.setState({sendEmail : false, visible : true})
                }
            })
            .catch(error => {
                this.setState({sendEmail : false})
                this.openNotificationWithIcon(404)
            })
    }

    render() {
        let width = window.innerWidth

        const {t} = this.props
        //-------------------------------------------- DESKTOP VERS -------------------------------------
            return (
                <Container fluid className={"conta"}>
                    <Row className="inscri-expediteur">
                        <Col>
                            <h2>Contactez-nous</h2>
                            <hr className={"hr-register"}/>
                            <div>
                                <h6 className={"label-contact"}>Nom</h6>
                                <input className={"input-contact"}
                                       type={"text"}
                                       name="nom"
                                       value={this.state.nom}
                                       required={true}
                                       onChange={this.onChange}/>
                                <h6 className={"label-contact"}>Prénom</h6>
                                <input className={"input-contact"}
                                       type={"text"}
                                       name="prenom"
                                    // value={this.state.user.last_name}
                                       value={this.state.prenom}
                                       required={true}
                                       onChange={this.onChange}/>
                                <h6 className={"label-contact"}>E-mail</h6>
                                <input className={"input-contact"}
                                       type={"text"} name="email" value={this.state.email}
                                       required={true}
                                       onChange={this.onChange}/>
                                <h6 className={"label-contact"}>Téléphone</h6>
                                <input className={"input-contact"}
                                       type="tel" name="num_tele"
                                       value={this.state.num_tele}
                                       onChange={this.onChange}/>
                                <h6 className={"label-contact"}>Sujet</h6>
                                <input className={"input-contact"}
                                       type={"text"}
                                       name="sujet"
                                       value={this.state.sujet}
                                       required={true}
                                       onChange={this.onChange}/>
                                <h6 className={"label-contact"}>Message</h6>
                                <TextArea className={"input-contact"}
                                          name={"message"}
                                          onChange={this.onChange}/>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{textAlign : "center"}}>
                            <button
                                type="submit"
                                className="btn-contact"
                                disabled={this.state.sendEmail}
                                onClick={this.onSubmit}
                            >{this.state.sendEmail ?<span>Envoir en cours ...</span> : <span>Envoyer</span>}
                            </button>
                        </Col>
                    </Row>
                    <Row className={"contact-bottom"}>
                        <p>Vous pouvez également nous contacter par E-mail à l’adresse suivante : <a
                            href = "mailto: contact@pickdeli.fr">contact@pickdeli.fr</a>
                        </p>
                    </Row>

                    <Modal
                        centered
                        visible={this.state.visible}
                        onCancel={() => this.setState({visible : false})}
                        footer={null}
                        title ={null}
                        width={"400px"}
                    >
                        <div className={"succes-contact"}>
                            <h6>Message envoyé</h6>
                            <hr/>
                            <p>Votre message a bien été reçu par l’équipe de PICK&DELI</p>
                        </div>
                    </Modal>
                </Container>
            );


    }
}

export default withTranslation()(Contact)
