import React, {Component, useEffect, useState} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {IsLoggedIn, openNotificationError} from "../Helpers";
import {Modal, Dropdown, Menu, notification, Tag, Select, Avatar, Input, Result} from "antd";
import Button from "antd/es/button";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import PlacesAutocomplete from "react-places-autocomplete";
import {BACKEND_API_URL, STRIPE_PUBLIC_KEY} from "../env";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {GetNatureColis, GetPlagePoids} from "../Services";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './card_details.css'
import jwt from "jsonwebtoken";
const appearance = {
    theme: 'flat',
};
let ClientSecret =''
class PaiementCommande extends Component {
    constructor() {
        super()
        this.state = {
            nomExpediteur:'',
            telephoneExpediteur:'',
            adresseDepart:'',
            nomDestinataire:'',
            telephoneDestinataire:'',
            adresseArrivee:'',
            nombreColis:'',
            isHorsGabarit:'',
            nombreChariot:'',
            natureColisId:'',
            montant:'',
            plagePoidsId:'',
            commentaire :'',
            PlagePoids:{},
            poidsText:'',
            ClientSecret:'',data:{}
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if(this.props.ExpeditionData){
            this.setState({
                nomExpediteur:this.props.ExpeditionData.nomExpediteur,
                telephoneExpediteur:this.props.ExpeditionData.telephoneExpediteur,
                adresseDepart:this.props.ExpeditionData.adresseDepart,
                nomDestinataire:this.props.ExpeditionData.nomDestinataire,
                telephoneDestinataire:this.props.ExpeditionData.telephoneDestinataire,
                adresseArrivee:this.props.ExpeditionData.adresseArrivee,
                nombreColis:this.props.ExpeditionData.nombreColis,
                isHorsGabarit:this.props.ExpeditionData.isHorsGabarit,
                nombreChariot:this.props.ExpeditionData.nombreChariot,
                natureColisId:this.props.ExpeditionData.natureColisId,
                montant:this.props.ExpeditionData.montant,
                plagePoidsId:this.props.ExpeditionData.plagePoidsId,
                commentaire :this.props.ExpeditionData.commentaire,
            })
            console.log("expd data : ",this.props.ExpeditionData)
            this.setState({data : {
                    nomExpediteur:this.props.ExpeditionData.nomExpediteur,
                    telephoneExpediteur:this.props.ExpeditionData.telephoneExpediteur,
                    adresseDepart:this.props.ExpeditionData.adresseDepart,
                    nomDestinataire:this.props.ExpeditionData.nomDestinataire,
                    telephoneDestinataire:this.props.ExpeditionData.telephoneDestinataire,
                    adresseArrivee:this.props.ExpeditionData.adresseArrivee,
                    nombreColis:this.props.ExpeditionData.nombreColis,
                    isHorsGabarit:this.props.ExpeditionData.isHorsGabarit,
                    nombreChariot:this.props.ExpeditionData.nombreChariot,
                    natureColisId:this.props.ExpeditionData.natureColisId,
                    montant:this.props.ExpeditionData.montant,
                    plagePoidsId:this.props.ExpeditionData.plagePoidsId,
                    commentaire :this.props.ExpeditionData.commentaire,
                    livreurs: this.props.ExpeditionData.livreurs,
                    commissionLivraison: this.props.ExpeditionData.commissionLivraison,
                    ClientSecret : this.state.ClientSecret
                }})

        }
        this.fetchPaymentSheetParams()
        this.F_DisplayPlagePoids()
        this.F_DisplayNatureColis()

    }

     fetchPaymentSheetParams = async () => {
        //   ********** GET USER MAIL **********
            const token = localStorage.usertoken;
            let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
            console.log(decodedToken)
            this.setState({userId : decodedToken.userId,
                personneId : decodedToken.personneId,token : decodedToken.token})
            let mail = "paiement@pick.fr"
            let res = await axios.post(`${BACKEND_API_URL}expediteur/getInfoUser`, {
                utilisateurId: decodedToken.userId,
            })
            if(res.data.success){
                mail = res.data.user.personne.email
            }


        let amount = this.props.ExpeditionData.montant
        console.log('aaaaaaaa',amount,"  ",mail)
        const data = new FormData();
        data.append("amount", amount);
        data.append("userId", mail);

        const response = await fetch(`${BACKEND_API_URL}expediteur/payment-sheet`, {
            method: 'POST',
            body: data,
        });
        const { paymentIntent, ephemeralKey, customer } = await response.json();

        this.setState({
            ClientSecret : paymentIntent
        })
         ClientSecret = paymentIntent
         console.log(
             this.state.ClientSecret, '       ',ClientSecret
             )

    };


    async F_DisplayPlagePoids() {
        await axios.get(`${BACKEND_API_URL}params/plagePoids`).then(res => {
            if(res.data.length > 0){
                console.log(this.state.plagePoidsId,' rrrrrr ', res.data)
                let temp = 'De ' + res.data.find(elem => elem.id === Number(this.state.plagePoidsId)).poidsMin
                    + ' à ' + res.data.find(elem => elem.id === Number(this.state.plagePoidsId)).poidsMax + ' kg'
                this.setState({poidsText : temp})
            } else {
                this.setState({poidsText : ''})
            }
        }).catch(err => {
            console.log(err)
            return []});

    }
    async F_DisplayNatureColis() {
        await axios.get(`${BACKEND_API_URL}params/natureColis`).then(res => {
            if (res.data.length > 0) {
                let temp = res.data.find(elem => elem.id === this.state.natureColisId)
                    .libelle
                this.setState({NatureText : temp})
            } else {
                this.setState({NatureText : ''})
            }
        }).catch(err => console.log(err));
    }

    onChange(e){
        e.preventDefault()
        const { name, value } = e.target;
        this.setState({[name]: value})

    }


    async onSubmit() {
    }
    async ReturnToForm() {
        let FormData = {
            adresseDepart: this.state.adresseDepart,
            adresseArrivee: this.state.adresseArrivee,
            natureColisId: this.state.natureColisId,
            nombreChariot: this.state.nombreChariot,
            isHorsGabarit: this.state.isHorsGabarit,
            commentaire: this.state.commentaire,
            nombreColis: this.state.nombreColis,
            plagePoidsId: this.state.plagePoidsId,
            nomDestinataire: this.state.nomDestinataire,
            telephoneDestinataire: this.state.telephoneDestinataire,
            nomExpediteur: this.state.nomExpediteur,
            telephoneExpediteur: this.state.telephoneExpediteur,
            montant: this.state.montant,
            // userId: this.state.user.userId
        }
        this.props.parentCallback(FormData,false,true);
    }


    render() {

        return (
                    <Col className={'right-dashboard'}>
                        <Row className="inscri-expediteur">
                            <Col>
                                <h2>Paiement</h2>
                                <hr className={"hr-register hr-dash"}/>
                            </Col>
                        </Row>
                        {this.state.ClientSecret && (
                            <Elements options={this.state.ClientSecret} stripe={stripePromise}>
                                <ElementsConsumer>
                                    {({stripe, elements}) => (
                                        <CheckoutForm stripe={stripe} elements={elements}
                                        montant ={this.state.montant} secret={this.state.ClientSecret}
                                                      data={this.state.data}/>
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        )}
                    </Col>
        );

    }
}

export default PaiementCommande
const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#1D1F39',
            color: '#979797',
            fontWeight: 400,
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#979797',
            },
            '::placeholder': {
                color: '#979797',
            },
        },
        invalid: {
            iconColor: '#ff3333',
            color: '#ff3333',
        },
    },
};

const CardField = ({onChange}) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const Field = ({
                   label,
                   id,
                   type,
                   placeholder,
                   required,
                   autoComplete,
                   value,
                   onChange,
               }) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
        <input
            className="FormRowInput"
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        />
    </div>
);



const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const ResetButton = ({onClick}) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#FFF"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

const DEFAULT_STATE = {
    error: null,
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    email: '',
    phone: '',
    name: '',
    montant:''
};

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
        this.state = {
            data:{}
        }

    }
    componentDidMount() {
        this.setState({montant : this.props.montant})
        console.log("data form : ",this.props.data)
        console.log("secret  : ",this.props.secret)
        this.setState({data : this.props.data})
    }
    F_FinaliserCommande = (datas) => {
        axios
            .post(`${BACKEND_API_URL}commandes/createCommande`, datas)
            .then(response => {
                if (response.data.success) {
                    console.log('reponse', response.data)
                    this.F_EnvoieDeDemande(response.data.commande.commandeId);
                }
            }).catch(e=>{
                console.log(e)
        })
    }


    F_EnvoieDeDemande = (commandeId) => {
        //Liste des recpteurs
        let recp = [];

        this.state.data.livreurs
            .forEach(livr => {
                recp.push({ entrepriseId: livr.transporteur.entrepriseId, transporteurId: livr.transporteurId });
            });

        let request = {
            commandeId: commandeId,
            recepteurs: JSON.stringify(recp),
            commissionLivraison: this.state.data.commissionLivraison
        }

        axios
            .post(`${BACKEND_API_URL}demande/create`, request)
            .then(response => {
                if (response.data.success) {
                    console.log('demande', response.data)
                    window.location.replace("/mes-expeditions/details-expedition/"+commandeId)
                }
            });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const {stripe, elements} = this.props;
        const {email, phone, name, error, cardComplete} = this.state;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardElement);

        if (card == null) {
            return;
        }

        if (error) {
            card.focus();
            return;
        }

        if (cardComplete) {
            this.setState({processing: true});
        }

        console.log('clicked')
        const payload = await stripe
            .confirmCardPayment(ClientSecret, {
                payment_method: {
                    card: card,
                },
            })

        this.setState({processing: false});

        if (payload.error) {
            this.setState({error: payload.error});
        } else {
            console.log('reeees ',payload.paymentIntent)
            this.setState({paymentMethod: payload.paymentIntent});
            const token = localStorage.usertoken;
            let decodedToken = await jwt.decode(token, process.env.jwtPrivateKey)
            console.log(decodedToken)
            this.setState({userId : decodedToken.userId,
                personneId : decodedToken.personneId,token : decodedToken.token})
            let data = {
                userId: decodedToken.userId,
                cartePaiementId: null,
                referencePaiement: this.props.secret,
                adresseDepart: this.state.data.adresseDepart,
                adresseArrivee: this.state.data.adresseArrivee,
                natureColisId: this.state.data.natureColisId,
                nombreColis: this.state.data.nombreColis,
                nombreChariot: this.state.data.nombreChariot,
                commentaire: this.state.data.commentaire,
                plagePoidsId: this.state.data.plagePoidsId,
                isHorsGabarit: this.state.data.isHorsGabarit,
                nomExpediteur: this.state.data.nomExpediteur,
                telephoneExpediteur: this.state.data.telephoneExpediteur,
                nomDestinataire: this.state.data.nomDestinataire,
                telephoneDestinataire: this.state.data.telephoneDestinataire,
                montant: this.state.data.montant
            }
            console.log(data)

            this.F_FinaliserCommande(data);
        }
    };

    reset = () => {
        this.setState(DEFAULT_STATE);
    };

    render() {
        const {error, processing, paymentMethod, name, email, phone} = this.state;
        const {stripe} = this.props;
        return paymentMethod ? (
            <div className="Result">
                {error ?
                    <>
                        <Result
                            status="error"
                            title={"Paiement échoué"}
                            subTitle = {"Le paiement a échoué, veuillez ressayer à nouveau."}
                        />
                        <Spinner animation="border" role="status" className={'spinner-btn'}
                                 variant="primary"/>
                    </>
                    :
                    <><Result
                    status="success"
                    title={"Paiement réussi"}
                    subTitle = {"Veuillez patienter, votre commande est en cours de création..."}
                    />
                    <Spinner animation="border" role="status" className={'spinner-btn'}
                    variant="primary"/></>
                }


            </div>
        ) : (
            <form className="Form" >
                <fieldset className="FormGroup">
                    <CardField
                        onChange={(event) => {
                            this.setState({
                                error: event.error,
                                cardComplete: event.complete,
                            });
                        }}
                    />
                </fieldset>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <Row className="inscri-expediteur">
                    <Col>
                        <hr className={"hr-register hr-dash"}/>
                    </Col>
                </Row>
                <Row className={'price'}>
                    <h5>{Number(this.state.montant).toFixed(2).toString()
                        .replace(".", ",")} €</h5>
                    <span>TTC</span>
                </Row>
                <Row className="inscri-expediteur">
                    <Col xs={12} lg={true}>
                <Button
                    className={`btn-continue nouvellexp recap ${error ? 'SubmitButton--error' : ''}`}
                    type="submit"
                    disabled={processing || !stripe}
                    onClick={(e)=>this.handleSubmit(e)}
                >
                    Procédez au<br/>
                    paiement
                </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}



const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
