import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const ModalDetails = ({callback,Visiblity,typeVehicule,Marque,Modele,Matricule}) => {
    console.log('paaaaaaaaasd state : ',Visiblity)
    const [isModalVisible, setIsModalVisible] = useState(Visiblity);
    if(Visiblity !== isModalVisible){
        setIsModalVisible(Visiblity)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        callback()
    };

    return (
        <>
            <Modal title="Détails" visible={isModalVisible} footer={null} onCancel={handleCancel}>
                <div>
                    <h5>Type de véhicule</h5>
                    <p>{typeVehicule}</p>
                    <h5>Marque / modèle</h5>
                    <p>{Marque}<br/>{Modele}</p>
                    <h5>Plaque d’immatriculation</h5>
                    <p>{Matricule}</p>
                </div>
            </Modal>
        </>
    );
};

export default ModalDetails
