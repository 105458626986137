import React, {Component, useEffect, useState} from 'react';
    import Container from "react-bootstrap/Container";
    import Row from "react-bootstrap/Row";
    import "slick-carousel/slick/slick.css";
    import "slick-carousel/slick/slick-theme.css";
    import './Home1/home1.css'
    import './global.css'
    import {Col} from "react-bootstrap";
    import Button from "antd/es/button";
    import {Link} from "react-router-dom";
    import big_phone from "../Images/pick_site/big_phone.png"
    import small_phone from "../Images/pick_site/small_phone.png"
    import AppleFilled from "@ant-design/icons/lib/icons/AppleFilled";
    import AndroidFilled from "@ant-design/icons/lib/icons/AndroidFilled";
    import phone_white from "../Images/phone_pick_white.png"
    import { notification } from 'antd';
    
    //**************** import images ********************
    
    const openNotification = () => {
        notification['info']({
            message: "L'application IOS sera bientôt disponible.",
            description: "",
        });
    };
    
    const redirect_transporteur = () =>{
    
            return (
                    <Container fluid style={{minHeight : "75vh"}} className={'back-success'}>
                        <Row className={'row-success'}>
                            <Col lg={7} className={'col-felicitaion'}>
                            <h3 className={'text-felicitaion'}>
                            Connexion Transporteur
                                                        </h3>
                                <hr className={"hr-felicitation"}/>
                                <h6 className={'txt-telecharger'}>
                                    Afin de pouvoir accéder à votre compte,<br/>
                                    veuillez télécharger l’application <b>PICK&DELI</b>.
                                </h6>
                                <div className={'btn-div'}>
                                    <a target='_blank' href='https://apps.apple.com/fr/app/pick-deli/id1617125362'>
                                        <Button className={'ios-btn'} icon={<AppleFilled/>}>
                                            IOS
                                        </Button>
                                    </a>
                                    <a target='_blank'
                                        href="https://play.google.com/store/apps/details?id=com.pickanddeli&gl=FR">
                                        <Button className={'ios-btn android-btn'} icon={<AndroidFilled />}>
                                            Android
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col lg={3} className={"center-phone big"}>
                                <img src={phone_white} className={'big-phone'}/>
                            </Col>
                            <Col className={"center-phone small"}>
                                <img src={require('../Images/pick_site/new_commande.png')} className={'small-phone'}/>
                                <img src={require('../Images/pick_site/phone_enlev.png')} className={'small-phone'}/>
                                <img src={require('../Images/pick_site/accepter.png')} className={'small-phone'}/>
                            </Col>
                        </Row>
                    </Container>
            );
        }
    
    
    
    export default redirect_transporteur;