import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './components/Login'
import NavBar from "./components/Navbar2";
import Inscription_success from "./Pages/Inscription_succes";
import {LoginRoute, PrivateRoute} from "./Services/authService";
import 'antd/dist/antd.less';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Pages/global.css'
import Home1 from "./Pages/Home1/Home1";
import Inscription_transporteur from "./Pages/Inscription_transporteur";
import Inscription_expediteur from "./Pages/Inscription_expediteur";
import Inscription_choix from "./Pages/Inscription_choix";
import mentionslegales from "./Pages/mentionslegales";
import Register_exped_partic from "./Pages/Register_exped_partic";
import Register_expedi_entr from "./Pages/Register_exped_entrp";
import Connexion_Choix from "./Pages/Connexion_Choix";
import Register_entrp_transp from "./components/Register_entrp_transp";
import Register_transp_indep from "./Pages/Register_transp_indep";
import Contact from "./Pages/Contact";
import NouvelleExpedition from "./Pages/NouvelleExpedition";
import MesExpeditions from "./Pages/MesExpeditions";
import DetailsExpedition from "./Pages/DetailsExpedition";
import MesPaiements from "./Pages/MesPaiements";
import MonCompte from "./Pages/MonCompte";
import cgu from "./Pages/cgu";
import redirect_transporteur from "./Pages/redirect_transporteur";
import CookieConsent from "react-cookie-consent";
import forgotPassword from "./Pages/ForgotPassword";
import politique_confidentialite from "./Pages/politique-confidentialite";

import {Container, Row} from "reactstrap";
import Footer from "./components/Footer";

class App extends Component {

  constructor(){
    super()
  }


  render() {
    return (

        <Router>
                <div className="App" style={{marginTop :"-15px"}}>
                  <NavBar />
                  <Container fluid style={{minHeight: "33vh"}}>

                    <Row className ={"main-tp"}>
                      <Switch>
                      <Route exact path="/" component={Home1} />
                      <Route exact path="/choix-inscription-transpo" component={Inscription_transporteur} />
                      <LoginRoute exact path="/choix-inscription-expediteur" component={Inscription_expediteur} />
                      <Route exact path="/choix-inscription" component={Inscription_choix} />
                      <Route exact path="/Mentions-légales" component={mentionslegales} />
                      <Route exact path="/politique-de-confidentialite" component={politique_confidentialite} />
                      <Route exact path="/cgu" component={cgu} />
                      <LoginRoute exact path="/register-exped-partic" component={Register_exped_partic} />
                      <LoginRoute exact path="/register-exped-entr" component={Register_expedi_entr} />
                      <Route exact path="/connexion-choix" component={Connexion_Choix} />
                      <Route exact path="/register-transport-entr" component={Register_entrp_transp} />
                      <Route exact path="/register-transport-indep" component={Register_transp_indep} />
                      <Route exact path="/contact" component={Contact} />
                      <Route exact path="/success_register" component={Inscription_success} />
                      <Route exact path="/connexion-transporteur" component={redirect_transporteur} />
                      <LoginRoute exact path="/login" component={Login} />
                      <PrivateRoute exact path="/nouvelle-expedition" component={NouvelleExpedition} />
                      <PrivateRoute exact path="/mes-expeditions" component={MesExpeditions} />
                      <PrivateRoute exact path="/mes-expeditions/details-expedition/:commandeId"
                                    component={DetailsExpedition} />
                      <PrivateRoute exact path="/mes-paiements" component={MesPaiements} />
                      <PrivateRoute exact path="/mon-compte" component={MonCompte} />
                      <LoginRoute exact path="/forgotPassword" component={forgotPassword} />
                        <Route path="*" component={Home1} />
                      </Switch>
                    </Row>
                  </Container>
                </div>
                <Footer/>
          <CookieConsent
              // debug={true}
              enableDeclineButton
              declineButtonText="Non"
              buttonText="Oui"
              style={{ background: "#1D1F39" }}
              acceptOnScroll={true}
              buttonStyle={{backgroundColor:"#B38053",borderRadius : "5px",color:"#FFF",marginRight:"10px",
                padding:"5px 15px",}}
              declineButtonStyle = {{backgroundColor:"#FFF",borderRadius : "5px",color:"#B38053",margin:0,
                padding:"5px 15px",}}
              containerClasses={"cookie-container"}
              contentClasses={"cookie-content"}
              buttonWrapperClasses={"btn-wrap"}
              flipButtons={true}
          >
            Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez
            à utiliser ce site, nous supposerons que vous en êtes satisfait.{" "}
            <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span>
          </CookieConsent>

        </Router>
    )
  }
}

export default App
