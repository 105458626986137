import React, {Component} from 'react'
import Container from "react-bootstrap/Container";
import {Col, Row, Spinner} from "react-bootstrap";
import '../components/profile.css'
import VerticalNavbar2 from "../components/VerticalNavbar2";
import {Input, Select} from "antd";
import {BACKEND_API_URL, BACKEND_ROOT_URL, GOOGLE_MAP_API_KEY} from "../env";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";
import Geocode from "react-geocode";
import Button from "antd/es/button";
import ModalDetails from "../components/ModalDetails";
import MapExpedition from "../components/MapExpedition";
import Messages from "../components/Messages";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

Geocode.setApiKey(GOOGLE_MAP_API_KEY);
// set response language. Defaults to english.
Geocode.setLanguage("fr");
const distance = require('google-distance-matrix');
distance.key(GOOGLE_MAP_API_KEY);
distance.language('fr');
distance.units('imperial');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("fr");
const style = {
    width: '100%',
    height: '100%'
}
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}
let interval=''

class DetailsExpedition extends Component {
    constructor() {
        super()
        this.state = {
            CommandesSansLivreur: [],
            LoadingSubmit: false,
            userId:'',ListeCommandes: [],
            CommandeId : '',Commande:'',
            Livreurs :'', SearchDeliver : false,
            DepartMarkers : {},
            V_LivreurPosition :{},
            ArriveMarkers : {},
            TempsApproche :0,
            region: {
                lat: 31.776685,
                lng: 35.234491,
            },
            maprops:'',Demande : '',
            mapref:'',livrState:'',directionsRenderer:'',
            detailvisibility : false, displayMessage: false
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            CommandeId:this.props.match.params.commandeId
        })
        console.log('detail commande : ',this.props.match.params.commandeId)

    }


    hide(){
        this.setState({detailvisibility:false})
    }
    DisplayModal(){
        if(this.state.Demande !== ''){
            return(
                <ModalDetails Visiblity={this.state.detailvisibility} callback={()=>this.hide()}
                typeVehicule={this.state.Demande.transporteur.vehicule.vehiculeType.libelle}
                Marque = {this.state.Demande.transporteur.vehicule.marque}
                Modele = {this.state.Demande.transporteur.vehicule.modele}
                Matricule = {this.state.Demande.transporteur.vehicule.matricule}/>
            )
        }
    }


    handleDisplayMap = (display) =>{
        this.setState({displayMessage : display})
    }

    render() {
        return (<Container fluid className={'dashboard-pages'}>
            {this.state.displayMessage &&
                <>
            <Row className={"retour"}>
                <Col>
                    <Button onClick={()=>{
                        this.setState({
                            displayMessage : false
                        })
                    }}>
                    <LeftOutlined />
                    <span>retour</span>
                    </Button>
                </Col>
            </Row>
            <hr className={"hr-msg"}/>
            </>
            }
                <Row className="profile">
                    <Col md={'auto'}>
                        <VerticalNavbar2/>
                    </Col>
                    {this.state.displayMessage ?
                    <Messages commandeId={this.state.CommandeId}/> :
                    <MapExpedition history={this.props.history}
                        commandeId={this.state.CommandeId} parentCallback = {this.handleDisplayMap}/>
                    }

                </Row>
                {this.DisplayModal()}
            </Container>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP_API_KEY),
    language : 'fr'
})(DetailsExpedition)

