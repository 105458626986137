import React, {Component, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home1/home1.css'
import {Col} from "react-bootstrap";
import Button from "antd/es/button";
import {Link} from "react-router-dom";



const Cgu = () =>{

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

        return (
                <Container fluid style={{minHeight : "75vh"}} className={'inscription'}>
                    <Row className="inscri-expediteur">
                    <Col>
                        <h2 className={'title-form'}>Conditions générales d'utilisation</h2>
                        <hr/>
                    </Col>
                </Row>
                    <Row className={"mentions-legales cgu"}>
                        <Col>
                            <h4>ARTICLE 1 : PRESENTATION GENERALE DE LA SOCIETE</h4>
                            <p>
                                PICK&DELI ci-après désignée la « Société PICK&DELI » ou « la Société »,
                                est une société par action simplifiée immatriculée au RCS de Nanterre sous le numéro
                                894 672 302, dont le siège social est situé 8 rue du General Roguet, 92110 Clichy et
                                qui exploite une Application mobile et un site internet.
                            </p>
                            <h5 className={"first-title"}><lable>1.1</lable> Description de l’activité</h5>
                        <p>
                            PICK&DELI est une plateforme de mise en relation entre une personne physique ou une personne morale ayant besoin d'un service de livraison de marchandises (ci-après désigné comme « Expéditeur ») et un transporteur professionnel (ci-après désigné comme « Transporteur ») qui est suggéré par la plateforme en vue de la conclusion d’un accord ayant trait à l’exécution d’un service de transport de marchandises.
                        </p>
                        <p>
                            Il est rappelé dès à présent que la Société ne propose qu'une prestation de service de mise en relation entre un Expéditeur et un Transporteur, tous d’eux indépendant de la Société. Par conséquent, la Société n’intervient pas dans le contrat de transport conclu seulement entre l’Expéditeur et le Transporteur pour la livraison de la marchandise.
                        </p>
                            <p>
                                La Société n’effectue donc pas la prestation de transport et n’a vocation qu’à mettre en relation les Parties en vue de la conclusion d’un contrat de transport.
                            </p>
                            <p>
                                Cependant et dans le cadre de la mise en relation entre l’Expéditeur et le Transporteur, la Société veillera à rappeler que, conformément aux dispositions contenues dans les règles particulières (ci-après « RP ») au Transporteur, ces derniers doivent conduire les opérations de transport dans des conditions strictement compatibles avec la réglementation des conditions de travail et de sécurité.
                            </p>
                        <h4>ARTICLE 2 – DEFINITIONS GENERALES</h4>
                            <ul>
                                <li>
                                    « Société » : PICK&DELI ayant pour enseigne PICK&DELI, Société par action simplifiée, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 894 672 302, dont le siège social est 8 rue du General Roguet, 92110 Clichy, et dont l'activité consiste en la mise en relation via une Application et un site web entre une personne physique ou une personne morale ayant besoin d'un service de livraison de marchandises (ci-après « Expéditeur ») et d’un Transporteur professionnel (ci-après « Transporteur ») suggéré par la plateforme en vue de la conclusion d’un accord relatif à l’exécution d’un service de transport ou d’une livraison de marchandises .
                                </li>
                                <li>
                                    « Utilisateur(s) » : personne physique majeure et capable, ou personne morale représentée par une personne physique dûment habilitée, qui utilise la plateforme (application ou site web) pour effectuer une demande de livraison de marchandise ou qui réalise une livraison de marchandise.
                                </li>
                                <li>
                                    « Partie(s) » : désigne indifféremment l'une quelconque des parties au contrat de transport, à savoir le Transporteur ou l’Expéditeur.
                                </li>
                                <li>
                                    « Expéditeur » : personne physique majeure et capable, ou personne morale représentée par une personne physique dûment habilitée, recherchant un service de livraison de marchandise et de mise en relation, par le biais de l'Application ou du site web, avec un Transporteur professionnel.
                                </li>
                                <li>
                                    « Transporteur » : personne physique ou morale (selon les cas, désigne le représentant de ladite personne morale) qui propose ses services de transport via le site web et/ou l'Application afin d'effectuer des livraisons de marchandises pour le compte des Expéditeurs qui en font la demande via l’application.
                                </li>
                                <li>
                                    « RP » : règles particulières des Conditions Générales d’Utilisation qui s'appliquent aux Transporteurs.
                                </li>
                                <li>« CGU » : Conditions Générales d’Utilisation
                                </li>
                                <li>« Application » : désigne l'Application informatique consistant en une plateforme de mise en relation par
                                    voie électronique telle que visée par l'article 60 de la loi 2016-1088 du 8 août 2016 dont la conception, le développement, la maintenance et l'exploitation constituent l'activité et le savoir-faire du groupe auquel appartient la Société. Elle est accessible via une Application mobile, un site web et permet l'accès aux services.
                                </li>
                                <li>« Smartphone » : désigne tout terminal mobile ou non (Smartphone, PC, tablette, ordinateur, etc.) disposant d'une connexion au réseau Internet et permettant ainsi de télécharger, d’installer et d’utiliser l'Application.
                                </li>
                                <li>
                                    « Services » : désignent l'ensemble des services permettant la mise en relation entre les Expéditeurs et les Transporteurs proposés par le biais de l'Application et des diligences de la Société au titre de cette mise en relation, permettant aux parties d'obtenir une livraison de marchandise, et pouvant être fournis dans certaines zones du territoire métropolitain français.
                                </li>
                                <li>
                                    « Accord » : désigne l’acceptation définitive donnée par le Transporteur aux Expéditeurs pour effectuer le service de transport de marchandises et en conséquence l’engagement contractuel du Transporteur vis- à-vis de l’Expéditeur d’effectuer la livraison de la marchandise et pour l’Expéditeurs à accepter que la livraison soit effectuée par le Transporteur désigné par l’application.
                                </li>
                                <li>
                                    « Adresse d’enlèvement » : désigne l'adresse de prise en charge de la marchandise (point de retrait) renseignée par l’expéditeurs dans l'Application pour une demande de livraison de marchandise.
                                </li>
                                <li>
                                    « Adresse de livraison » : désigne l'adresse de livraison de la marchandise renseignée par l’Expéditeur dans l'Application pour une demande de livraison de marchandise.
                                </li>
                                <li>
                                    « Destinataire » : désigne la personne physique ou la personne morale (agissant par le biais de l’un de ses représentants) dont l'identité et les coordonnées sont renseignées sur l'Application par l’Expéditeur comme étant le destinataire de la marchandise et se trouvant à l'adresse de livraison ou, le cas échéant, sous la réserve de sa désignation, par tout moyen, par l’expéditeur.
                                </li>
                                <li>
                                    « Tarifs » : Somme(s) facturée(s) par le Transporteur à l’Expéditeur au titre d'une livraison de marchandise effectuée via l'Application.
                                </li>
                                <li>
                                    « Véhicule de Transport » : désigne-le(s) procédé(s) utilisé(s) par le Transporteur pour transporter la marchandise aux fins d'assurer la livraison de marchandise, à savoir transport à pied, transport en trottinette, transport à vélo, transport en scooter/moto ou transport par véhicule terrestre à moteur de moins de 3,5 tonnes PTAC (voitures, utilitaires ou camionnettes) ci-après dénommés « Véhicule(s) », transport par véhicule terrestre à moteur de plus de 3,5 tonnes PTAC.
                                </li>
                            </ul>
                        <h4>ARTICLE 3 - MODALITE DE VALIDATION DES CONDITIONS GENERALES D’UTILISATION</h4>
                       <p>
                           Les présentes conditions générales d’utilisation de l’Application éditée par la Société ont pour objet de définir les conditions dans lesquelles la Société fournit aux Expéditeurs et aux Transporteurs un droit d'utilisation de l’Application permettant aux Expéditeurs de trouver des Transporteurs afin qu’ils réalisent immédiatement des livraisons de marchandise.
                       </p>
                            <p>L'utilisation de l'Application est subordonnée à l'acceptation par les parties des présentes CGU mais également pour les Transporteurs à l’acceptation des RP propres aux Transporteurs figurant en annexe des présentes CGU. L'application des présentes CGU dure tant que durent l'accès aux services proposés par l'Application aux Parties.
                            </p>
                            <p>
                               Au moment de la finalisation de la création de leur compte, les Expéditeurs et les Transporteurs doivent donc indiquer avoir pris connaissance et avoir accepté sans réserve les présentes CGU en cliquant sur « Je reconnais avoir pris connaissance des Conditions Générales d'Utilisation et les accepte ».
                           </p>
                            <p>Seule l'acceptation de ces CGU permet aux parties de finaliser la création de leur compte et d'accéder aux services.
                            </p>
                            <p>L'acceptation des présentes CGU est entière et forme un tout indivisible, et les parties ne peuvent pas choisir de voir s'appliquer seulement une partie des CGU ni formuler des réserves.
                            </p>
                            <p>
                                En cas de manquement, par l'une des parties, à l'une quelconque des obligations prévues par les présentes CGU ou au RP, la Société se réserve la possibilité de mettre fin temporairement ou définitivement à sa relation avec l’une ou l’autre ou les deux parties et par conséquent, à restreindre temporairement ou définitivement l'accès aux services, sans préavis et sans que la partie ne puisse prétendre à aucun remboursement ou obtenir une indemnisation quelconque, selon les modalités décrites par les présentes CGU
                            </p>
                            <p>
                                Aucune condition particulière ni autres conditions générales émanant de l’Expéditeur et/ou du Transporteur ne peuvent prévaloir sur les présentes CGU.
                            </p>
                            <p>
                                Si l'Application a été téléchargée depuis l'App Store d'Apple ou le Google Play Store, l’Expéditeur s'engage à respecter les conditions générales propres à ces deux plateformes, sans préjudice de la pleine Application des présentes CGU qui prévaudront en cas de conflit.
                            </p>
                            <p>
                                La Société se réserve le droit de mettre à jour à tout moment les présentes CGU ou RP, et ce notamment du fait de l'évolution des fonctionnalités offertes par l'Application ou des règles de fonctionnement des services.
                            </p>
                            <p>
                                La modification sera notifiée aux parties sur l’Application au moment de leur connexion et prendra effet dès la mise en ligne des CGU. Cette mise à jour devra avoir été préalablement consultée et devra être acceptée sans réserve par les deux parties pour pouvoir continuer à utiliser les services.
                            </p>
                            <p>La partie qui entend s'opposer à la modification des CGU doit s'abstenir d'utiliser l'Application et les services.
                            </p>
                            <p>Lorsque la modification survient après le paiement par l’Expéditeur d'une somme d'argent correspondant à une livraison de marchandise, la modification ne s'applique pas à la transaction en cours.
                            </p>
                            <p>Si cette modification implique des modifications de la procédure d'accès et/ou d'utilisation de l'Application, la Société en informera préalablement chaque partie par courrier électronique à l'adresse renseignée par elle.
                            </p>
                            <p>
                            La Société peut céder les présentes CGU ainsi que l'ensemble des droits et obligations qui y sont attachés, à tout tiers, sans accord préalable et écrit des parties, notamment en cas de fusion par constitution d'une nouvelle société, de fusion absorption, de scission ou de tout changement de contrôle affectant la Société. Chaque partie déclare accepter d'ores et déjà tout changement de contrôle dans la personne de la Société et reconnaissent l'éventuel successeur de cette dernière comme son cocontractant.
                            </p>

                            <h4>ARTICLE 4 - INSCRIPTION DE L’UTILISATEUR</h4>
                            <p>
                                Pour pouvoir accéder aux services, les Utilisateurs doivent préalablement disposer d'un moyen d'accéder à l'Application et doivent créer un compte.
                            </p>
                            <p>
                                Cet accès et cette création peuvent se faire via l'Application ou le site internet.
                            </p>
                            <h5 className={"first-title"}><label>4.1</label> Création d’un compte «EXPEDITEUR»</h5>

                            <p>Pour utiliser l’Application, l’Expéditeur doit télécharger l’application PICK&DELI, créer un compte et transmettre les informations et documents susceptibles d’être demandés par l’application.
                                </p>
                            <p>L’Expéditeur peut être un Particulier ou une Entreprise (personne morale).
                                </p>
                            <p>Chaque expéditeur ne peut créer qu’un seul compte à son nom et ne peut créer de compte pour un tiers.
                                </p>
                            <p>L’Expéditeur s’engage à garder secret le mot de passe choisi lors de la création de son compte et à ne le communiquer à personne. En cas de perte, vol ou d’utilisation détournée de son mot de passe, l’Expéditeur s’engage à en informer sans délai PICK&DELI qui pourra permettre à l’expéditeur de renouveler la procédure pour l’obtention d’un nouveau mot de passe.
                            </p>
                            <p>L’Expéditeur s’engage à fournir des informations complètes et exactes lors de son inscription et à les maintenir à jour. Les informations communiquées par l’Expéditeur sur son identité par exemple doivent correspondre à celles indiquées sur le justificatif d’identité pouvant lui être réclamé. Chaque Expéditeur s’engage à indiquer sa véritable identité lors de son inscription et donc à fournir son vrai nom et prénom.
                            </p>
                            <p>L’expéditeur s’engage à fournir les informations indiquées comme obligatoires sur l’Application.
                            </p>
                            <h5 className={"first-title"}><label>4.2</label> Création d’un compte «TRANSPORTEUR»</h5>
                            <p>
                                Pour utiliser l’Application, le Transporteur doit créer un compte et transmettre les informations et documents susceptibles d’être demandés par la Société.
                            </p>
                            <p>
                                Le Transporteur peut être un indépendant ou une Entreprise de transport.
                            </p>
                            <p>
                                Le Transporteur indépendant ne peut créer qu’un seul compte au nom de sa société (le cas échéant ou son nom) et ne peut créer de compte pour un tiers.
                            </p>
                            <p>
                                Le Transporteur s’engage à garder secret le mot de passe choisi lors de la création de son compte et à ne le communiquer à personne. En cas de perte, vol ou d’utilisation détournée de son mot de passe, le Transporteur s’engage à en informer sans délai PICK&DELI.
                               </p>
                            <p>
                                Le Transporteur s’engage à fournir des informations complètes et exactes et à les maintenir à jour.
                               </p>
                            <p>
                                Les informations communiquées par le Transporteur doivent correspondre à celles indiquées sur les justificatifs qui seront demandés.
                            </p>
                            <p>
                                Le Transporteur s’engage à fournir les informations indiquées comme obligatoires sur l’Application.
                            </p>
                            <p>
                                L’application se réserve le droit de retenir ces informations.
                            </p>
                            <p>
                                Les dispositions ci-dessus contenues sous l’article 4.2 ont vocation à s’appliquer également aux entreprises de transport. Toutefois les entreprises de transport pourront bénéficier en sus, d’un compte administrateur leur permettant de créer et de gérer des sous-comptes dédiés à leurs chauffeurs salariés. Des informations particulières et des justificatifs seront demandés à l’entreprise de transport pour chaque chauffeur salarié et chaque véhicule ajoutés.
                            </p>
                            <h5 className={"first-title"}><label>4.3</label> Règles communes d’inscription aux Utilisateurs</h5>
                            <p>
                                Chaque utilisateur s'engage à suivre les indications fournies à la Société via l'Application pour la création de son compte et à fournir des données d'identifications requises (nom, prénom, adresse postale, adresse email, raison sociale et nom d'organisation le cas échéant, numéro SIRET, numéro TVA, numéro de téléphone, photographie de façon complète, véridique et non trompeuse...).
                                </p>
                            <p>Chaque utilisateur est tenu de mettre à jour en temps utile ses données d'identification via l'Application en suivant les procédures fournies par la Société. Si les données fournies s'avéraient inexactes, incomplètes ou fallacieuses, ou n'étaient pas mises à jour, la Société se réserve le droit de suspendre sans préavis l'accès aux services selon les modalités prévues par les présentes CGU temporairement, ce jusqu’à la mise à jour des informations ou définitivement s’il est constaté que les données sont frauduleuses.
                                </p>
                            <p>Chaque utilisateur est informé que les services sont uniquement accessibles en ligne via l'Application.
                            </p>
                            <p>
                                L’Utilisateur déclare, préalablement à son inscription sur l’Application :
                            </p>
                            <ul>
                                <li>S’il est une personne physique agissant pour son compte, qu’il est une personne majeure et qu’il dispose de la capacité lui permettant de s’engager contractuellement avec un autre Utilisateur.
                                </li>
                                <li>S’il est mineur :</li>
                                <ul>
                                <li>L’Utilisateur expéditeur déclare être âgé d’au moins 16 ans, disposer d’une autorisation parentale ou être émancipé, et respecter les conditions prévues par la loi pour agir le cas échéant en tant qu’Expéditeur
                                </li><li>L’utilisateur Transporteur ne pourra s’inscrire que dans le cadre de la conduite de véhicule non motorisé ou &lt; 50cm3;
                                </li></ul>
                                <li>S’il agit pour le compte d’une personne morale, qu’il est le représentant légal ou qu’il dispose d’un mandat de la personne morale concernée pour s’engager contractuellement avec un autre Utilisateur.
                                </li>
                                </ul>
                            <p>L’Utilisateur s’engage préalablement à son inscription sur l’Application à :</p>
                            <ul>
                                <li>Fournir des informations exactes, complètes et actualisées, et toutes autres données (notamment personnelles) qui seraient nécessaires à la finalisation et au maintien de l’inscription sur l’Application, notamment une adresse électronique valide et toutes informations complémentaires nécessaires pour devenir Expéditeur ou Transporteur.
                            </li>
                                <li>
                                Mettre à jour son compte et à y apporter sans délai toute modification concernant ses informations.
                            </li>
                            </ul>
                            <p>
                                Chaque Utilisateur sera seul responsable des conséquences pouvant résulter de la fourniture d’informations mensongères, invalides ou erronées à la société et/ou à un autre Utilisateur. La Société se réserve donc le droit de suspendre le compte de l’Utilisateur en cas d’informations erronées voir à supprimer le compte de l’Utilisateur en cas d’informations fausses ou frauduleuses et pourra le cas échéant en fonction de la gravité de la fraude engager les poursuites nécessaires contre l’Utilisateur pour autant que la Société soit impactée par cette fraude.
                            </p>
                            <p>
                                Pour s’inscrire, l’Utilisateur devra fourni une adresse email valide, un nom d’utilisateur et un mot de passe. S’agissant du nom d’utilisateur, l’Utilisateur s’engage à :
                            </p>
                            <ul>
                                <li>Ne pas s’enregistrer sous un nom d’utilisateur portant atteinte aux droits d’un tiers.
                            </li>
                                </ul>
                            <p>Précisément, l’Utilisateur n’utilisera pas un nom d’utilisateur portant atteinte aux droits d’auteur, marques, dénominations sociales ou enseignes d’un tiers ou d’un autre Utilisateur, ou encore un nom d’utilisateur ou pseudo visuellement ou phonétiquement proche du nom d’utilisateur d’un autre Utilisateur.
                            </p>
                            <ul>
                                <li>
                                    ne pas enregistrer comme nom d’utilisateur tout ou partie d’un lien URL, adresse ou autre nom de domaine pointant vers un site externe.
                                </li>
                            </ul>
                            <p>PICK&DELI se réserve le droit, notamment en cas de litige entre Utilisateur, de demander à l’Utilisateur qui n’a pas respecté le présent article de modifier son nom d’utilisateur dans un délai qui lui sera communiqué par la société. A défaut de se conformer à cette demande, la société pourra clôturer ou suspendre le compte de l’Utilisateur concerné dans les présentes CGU.</p>
                            <ul>
                                <li>Ne pas usurper l’identité d’un autre Utilisateur, ou faire usage d’une ou plusieurs données de toute nature permettant de l’identifier en vue de troubler sa tranquillité ou celle d’autrui, ou de porter atteinte à son honneur ou sa considération. Ces faits sont répréhensibles sur le fondement de l’article 226-1 du Code pénal, qui les punit d’un an de prison et de 15.000 euros d’amende. L’Utilisateur qui constate de tels fait doit en informer immédiatement PICK&DELI par email à l’adresse service.client@pickdeli.fr.
                                En cas de fausse déclaration, la société se réserve la possibilité de suspendre et/ou de clôturer le compte de l’Utilisateur.
                                </li>
                                <li>Et de manière générale, à ne pas utiliser de nom d’utilisateur qui pourrait faire l’objet de poursuites. PICK&DELI se réserve donc le droit de rejeter la proposition d’un nom utilisateur dans ce cas.
                                </li>
                                </ul>
                            <p>L’identifiant de l’Utilisateur et le mot de passe de l’Utilisateur sont strictement personnels et l’Utilisateur s’engage à en maintenir la confidentialité. Cette obligation relève de sa seule responsabilité.
                            </p>
                            <p>L’Utilisateur inscrit est seul autorisé à utiliser l’Application à l’aide de son nom d’utilisateur et de son mot de passe et s’engage à ne permettre à aucune autre personne un accès sous son identité ou son nom d’utilisateur. L’utilisation de l’identifiant de l’Utilisateur, associée à son mot de passe, fait présumer un accès et un usage de l’Application par cet Utilisateur. Dans l’hypothèse où il aurait connaissance d’un accès par un tiers à son compte, l’Utilisateur s’engage à en informer PICK&DELI sans délai par e-mail à l’adresse service.client@pickdeli.fr, afin de permettre à PICK&DELI de prendre des mesures pour remédier à la situation.
                        </p>
                            <ul>
                                <li>
                                    Chaque Utilisateur s’engage à ne créer et à n’utiliser qu’un seul compte. Toute dérogation à cette règle devra faire l’objet d’une demande de la part de l’Utilisateur par e-mail à l’adresse service.client@pickdeli.fr et d’une autorisation expresse et spécifique de la société. Le fait de créer ou d’utiliser de nouveaux comptes sous sa propre identité ou celle de tiers sans avoir demandé et obtenu l’autorisation préalable de la société pourra entraîner, à la discrétion de PICK&DELI, la suspension immédiate et/ou la clôture des comptes de l’Utilisateur et de tous les services associés.
                                </li>
                            </ul>
                            <h4>ARTICLE 5 - MISE EN RELATION ENTRE LES UTILISATEURS</h4>
                            <h5 className={"first-title"}><label>5.1</label> EXPEDITEUR</h5>
                            <h5 className={"second-title"}><label>5.1.1</label> Déroulement d’une demande de livraison</h5>
                            <ul>
                                <li>PICK&DELI est une Application mobile et un site internet proposant un service de mise en relation d’Utilisateurs (Expéditeur et Transporteur) en vue de la conclusion d’un Accord relatif à des prestations de service de transport.
                                </li>
                                <li>L’Expéditeur fait une demande de livraison de marchandise sur l’Application PICK&DELI, demande à laquelle un Transporteur professionnel, peut être amené à répondre sur l’application PICK&DELI.
                                </li><li>Les parties disposent d’une Messagerie privée sur l’Application offrant la possibilité d’échanger sur les modalités de réalisation de cette Prestation de transport.
                            </li><li>Lorsque le Transporteur accepte la demande de transport d’un expéditeur, un Accord est formé entre ces derniers.
                            </li><li>Il convient de préciser que PICK&DELI est tierce à cet Accord formé entre l’Expéditeur et le Transporteur, le rôle de PICK&DELI étant uniquement d’assurer la mise en relation des Utilisateurs via l’Application ou le site internet
                            </li><li>Au moment de l’émission d’une demande de livraison de marchandise, les Expéditeurs ont déjà pris connaissance et accepté les CGU et RP au moment de la première connexion à l’Application/site internet.
                            </li><li>Après la réalisation de la demande de livraison de la marchandise, l’Expéditeur est invité à émettre un
                            </li><li>avis concernant la prestation de service fourni par le transporteur.</li>
                            </ul>
                            <h5 className={"second-title"}><label>5.1.2</label> Formulation d’une demande de livraison et engagement de l’Expéditeur</h5>

                            <p>L’Expéditeur doit renseigner au sein de l'Application les informations suivantes :</p>
                            <ul><li>l'adresse d’enlèvement
                            </li><li>la personne à contacter sur le lieu d’enlèvement
                            </li><li>l'adresse de livraison et le destinataire auprès de qui le Transporteur doit délivrer la marchandise
                            </li><li>les coordonnées permettant au transporteur de joindre le destinataire (qui peut être l’Expéditeur lui-même et/ou le destinataire).
                            </li><li>L’Expéditeur devra aussi renseigner le nombre de palette et/ou chariots, la nature du colis, le nombre de colis, le poids total des colis.
                            </li><li>Si le colis est hors gabarit signifiant que ses dimensions sont non-standards (cas des petits colis très lourds, ou des colis léger très grand ou très large par exemple), l’Expéditeur est alors invité à cocher la case hors-gabarit et à indiquer en commentaire tout information utile à la bonne réalisation de la livraison qui sera effectuée par le transporteur.
                            </li></ul>
                            <p>L’Expéditeur est conscient que sa demande de livraison de marchandise sera transmise par l'Application au Transporteur disponible le plus proche de l'adresse de prise en charge, en fonction de l'itinéraire tel que fourni par Google Maps.</p>
                            <p>Il est à rappeler que l’Expéditeur devra payer, via l’Application, la prestation avant que celle-ci ne soit exécutée</p>
                            <p>Tout particulièrement, l’Expéditeur est conscient que c'est sur la base de ces informations essentielles que la livraison devra être effectuée et s’engage donc à formuler la demande la plus précise possible pour faciliter et permettre la réalisation de la livraison de marchandise à laquelle le Transporteur s'engagera.</p>
                            <p>Notamment, l’Expéditeur s'engage donc à :</p>
                            <ul>
                                <li>
                                    <p>Formuler une demande de livraison de marchandise au profit d'un destinataire identifié et identifiable ( ou d'une consigne automatique identifiée) ;</p>
                                </li>
                                <li>
                                    <p>Fournir à la Société et au Transporteur l’ensemble des informations permettant l’enlèvement et la livraison (par exemple nom, prénom, adresse postale, codes de porte le cas échéant, code ascenseur, adresse mail et numéro de téléphone etc.) et</p>
                                </li>
                                <li>
                                    <p>Détenir les autorisations des personnes à contacter pour l’enlèvement et la livraison ;</p>
                                </li>
                                <li>
                                    <p>Préciser les particularités apparentes ou non de la marchandise quand elles sont susceptibles d'avoir des répercussions sur le déroulement de la livraison par le Transporteur.</p>
                                </li>
                                <li>
                                    Ne pas demander la livraison de marchandise(s) à un destinataire inaccessible ou qui obligerait le Transporteur à des efforts considérables tels que des destinataires qui seraient incarcérés ou dont l'adresse serait difficilement accessible par voie terrestre et/ou ne serait pas à proximité d'une route balisée,
                                </li>
                                <li>
                                    <p>N’utiliser le service et la demande de livraison de marchandise qu'à des fins licites.</p>
                                </li>
                            <li>
                                <p>Dans le cas où l’Expéditeur ne respecterait pas l’un des engagements ci-dessus, ni le Transporteur ni la Société ne pourraient être tenu responsable d’un manquement dans la réalisation de la livraison. Sous réserve de la bonne indication des informations nécessaires pour l’enlèvement et la livraison, la Société s’engage dans le cadre de sa mise en relation et via l’Application à :</p>
                            </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>Suggérer un véhicule correspondant aux caractéristiques du produits à livrer – Ce véhicule pourra être n’importe quel véhicule proposé par l’Application en fonction des caractéristiques de la marchandise à livrer.</p>
                                </li>
                                <li>
                                    <p>Proposer un Tarif pour cette livraison conformément à la grille tarifaire de la Société.</p>
                                </li>
                                <li>
                                     Estimer le temps de réalisation de la livraison de marchandise (comprenant le délai d'approche vers l’enlèvement ainsi que le délai de prise en charge jusqu'à la livraison finale). Toutefois dans ce cas, cette estimation n’est fournie qu’à titre indicative et ne saurait constituer un quelconque engagement du 
                                    Transporteur ou de la Société.</li>
                                        <li>L’Expéditeur est tenu d’accepter les modalités proposées par la Société pour enclencher la demande de livraison
                                    Cette acceptation est conditionnée par le règlement de la livraison incluant le règlement des frais de service de la Société
                                        </li>
                                <li>Après l’acceptation, la Société fourni à l’Expéditeur les informations concernant le Transporteur est notamment la plaque d’immatriculation de son véhicule ainsi que l’identification du véhicule, son nom et prénom
                                    </li>
                                            <li>L’Expéditeur s’engage donc à vérifier ou faire vérifier (par la personne à contacter au lieu d’enlèvement) que la plaque d’immatriculation se présentant au lieu d’enlèvement correspond bien à celle fournit par la Société sur l’Application.
                                </li>
                            </ul>
                            <h5 className={"second-title"}><label>5.1.3</label> Conséquence de l’annulation d’une demande de livraison</h5>
                            <p>L’Expéditeur peut annuler sa demande de livraison de marchandise via l’Application dans les cas suivants et selon</p>
                            <p>les conditions suivantes :</p>
                            <ul>
                                <li>
                                    <p> Jusqu’à une minute après l'émission de la demande de livraison de marchandise qu'il a effectuée sur l'Application et ce, à condition que la marchandise n'ait pas encore été enlevée par le Transporteur auprès de l'Expéditeur : sa demande initiale et son annulation n'entrainent alors aucun frais ni coût pour l’Expéditeur.</p>
                                </li>
                                <li>
                                    <p> Après ce délai d’une minute et tant que la marchandise n'a pas été enlevée par le Transporteur, l’annulation entraine alors : la facturation d’une prise en charge forfaitaire ajoutée à un montant calculé sur la base du nombre de kilomètre parcouru par le Transporteur vers le lieu d’enlèvement de la marchandise, du moment de l’acceptation au moment de l’annulation par l’Expéditeur. L’annulation entraine par ailleurs la facturation des frais de service de la Société.</p>
                                </li>
                                <li>
                                    Après l’enlèvement de la marchandise par le Transporteur, l’annulation entraine alors la facturation d’un montant équivalent au tarif de la course ajouté à un montant correspondant aux kilomètres parcourus pour le retour de la marchandise au lieu d’enlèvement ou dans tout autre lieu de retour.<br /> L’annulation entraine par ailleurs la facturation des frais de service de la Société.
                                </li>
                                <li>Dans tous les cas d’annulation par l’Expéditeur, les communications devront se faire impérativement via l’Application (et notamment via la messagerie mise à disposition) et en contact direct avec la Société dont les coordonnées sont fournis dans l’Application.
                                    </li>
                                <li>La Société ne saurait donc être tenu pour responsable d’échanges qui auraient lieu en dehors de l’Application.
                                </li>
                            </ul>
                            <h4 className={"first-title"}><label>5.2</label> TRANSPORTEUR</h4>
                            <h5 className={"second-title"}><label>5.2.1</label> Acceptation d’une demande de livraison</h5>
                            <p>En fonction du véhicule de transport suggérée par l’Application et de la zone géographique concernée par le lieu d’enlèvement de la marchandise, la demande de livraison de marchandise est transmise par l'Application à un (ou des) Transporteur(s) disponible(s) le(s) plus proche(s).</p>
                            <p>La demande de livraison apparait donc sur l’interface transporteur avec des informations générales et non détaillées (telles que la commune d’enlèvement, de la commune de livraison, le gain à percevoir et un rappel des caractéristique du véhicule détenu par le transporteur ) lui permettant alors d’accepter ou refuser cette demande.</p>
                            <p>Le Transporteur qui accepte cette demande de livraison de marchandise reçoit un premier bon de livraison détaillant les caractéristique de la marchandise à livrer avec la possibilité soit d’accepter et de démarrer la livraison soit d’annuler l’acceptation.</p>
                            <p>Après cette étape et dès que le transporteur signifie son acceptation en cliquant sur le bouton « DEMARRER », le transporteur est considéré avec accepter pleinement et entièrement la demande de livraison et s'engage envers l'Expéditeur à son exécution dans les conditions prévues par les RP.</p>
                            <p>L'acceptation par le Transporteur de la livraison de marchandise sur son Smartphone est notifiée à l’Expéditeur par une notification sur l'Application, et vaut conclusion irrévocable du contrat liant l’Expéditeur au Transporteur, et engagement irrévocable du Transporteur à enlever la marchandise et à réaliser ladite livraison de marchandise dans les conditions des CGU.</p>
                            <h5 className={"second-title"}><label>5.2.2</label> Exécution de la demande de livraison</h5>
                            <p>Le transporteur s’engage à exécuter la demande de livraison (de son enlèvement jusqu’à sa livraison) selon les modalités prévues par les CGU mais également selon les RP propres aux transporteurs. Le transporteur s’engage donc à se référer impérativement aux RP qui s’ajoutent et viennent préciser les présentes CGU.</p>
                            <p>Le transporteur est considéré en possession de la marchandise dès la confirmation de la prise en charge par le Transporteur sur l'Application via son Smartphone et après donc avoir cliqué sur le bouton « DEMARRER » la course.</p>
                            <p>L'Application permet au Transporteur de se connecter sur des Applications mobiles de navigation GPS pour obtenir une ou des suggestions d'itinéraire(s) pour la livraison de marchandise, mais il est entièrement libre de l'organiser comme il le souhaite.</p>
                            <p>Le Transporteur n'est pas tenu à une obligation de résultat concernant le délai indicatif d’enlèvement et de livraison</p>
                            <p>de la marchandise tous deux communiqués à titre estimatif au moment de l’acceptation de la demande de livraison.</p>
                            <p>Toutefois, et dès son acceptation finale, il s'engage à faire tout son possible et de bonne foi, pour se diriger directement et sans rupture de charge vers le lieu d’enlèvement de la marchandise puis à réaliser immédiatement la livraison sans rupture de charge et dans un délai raisonnable, compte tenu notamment de son véhicule de transport, de la nature de la marchandise, des conditions de circulation, météorologiques, etc., et également en fonction des demandes de l’Expéditeur.</p>
                            <p>Dans le même sens, en cas d'annulation par l’Expéditeur de la livraison de marchandise alors que le Transporteur est en possession de la marchandise (cf. article 5.1.3), ce dernier s'engage à faire tout son possible pour rapporter la marchandise au lieu d’enlèvement, impérativement le même jour, dans un délai raisonnable sans être tenu toutefois de respecter un délai identique ou équivalent à celui engagé depuis sa prise de possession.</p>
                            <p>La remise de la marchandise par le Transporteur au destinataire désigné par l’Expéditeur est établie selon les modalités des présentes CGU et des RP.</p>
                            <p>A l’issue de la livraison, l'Application permet aux Utilisateurs de déposer un commentaire sur la livraison de la marchandise.</p>
                            <p>A cet égard, l’Expéditeur reconnaît avoir informé préalablement le destinataire de la livraison qu’une livraison est en route et lui sera faite mais également l’informe qu’il doit au moment de la livraison de marchandise signifié au Transporteur, les dommages ou vol doivent faire l'objet de la part de ce dernier de réserves écrites, précises, complètes, véridiques, datées et signées via le Smartphone et l'Application qui lui sont présentés.</p>
                            <p>Une notification de remise au destinataire est adressée à l’Expéditeur via l'Application, ainsi que les commentaires éventuels du Transporteur ou du destinataire.</p>
                            <p>Aux titres de ces commentaires, L’Expéditeur, le destinataire ou le Transporteur s'interdisent de diffuser tous messages et avis à caractère injurieux, insultant, dénigrant, dégradant ou diffamatoire. En tout état de cause, la Société se réserve le droit de supprimer, sans délai, tout ou partie d'un message qui lui serait notifié et qui, selon elle, ne répondrait pas aux critères des présentes CGU.</p>
                            <p>L'Application permet également à l’Expéditeur d'émettre un avis de satisfaction allant de 1 à 5 volants sur la qualité de la prestation.</p>

                            <h5 className={"second-title"}><label>5.2.3</label> Conséquence de l’annulation de l’acceptation d’une demande de livraison</h5>
                            <p>Le transporteur qui reçoit via l’Application une première notification de demande de livraison de marchandise sur son Smartphone a le choix d’accepter ou de refuser cette demande de livraison.</p>
                            <p>Un refus peut donc être librement formulée peu importe la raison et ce jusqu’à l’interface détaillant le bon de livraison et lui demandant de démarrer la course ou de l’annuler. Il est donc entendu que toute annulation formulée jusqu’à l’interface indiquant « DEMARRER ou ANNULER » n’entrainera aucune conséquence pour le Transporteur.</p>
                            <p>Au moment du refus par le transporteur, l’Application sera droit donc de relayer automatiquement cette demande de livraison au transporteur le plus proche sans que le prix ou le procédé de transport ne soit sujet à modification.</p>
                            <p>Après avoir cliqué sur le bouton « DEMARRER », le transporteur est considéré comme avoir accepté la demande de livraison de manière ferme et non révocable et par conséquent :</p>
                            <ul>
                                <li>
                                    <p> Renonce à se prévaloir d’un quelconque droit de rétractation</p>
                                </li>
                                <li>
                                    <p> S’engage à ne pas annuler la livraison de la marchandise sauf cas particuliers décrits ci-dessous qui devront faire systématiquement l’objet d’une autorisation préalable de la Société, la Société étant la seule habilitée à pouvoir annuler une livraison.</p>
                                </li>
                            </ul>
                            <h5 style={{textDecoration : "underline"}}><strong>CAS D’ANNULATION PERMIS PAR LA SOCIETE</strong></h5>
                            <p>Dans les cas décrits ci-dessous, la Société pourra donc permettre aux Transporteurs d’annuler la livraison :</p>
                            <p>En cas de manquement par l’Expéditeur à ses déclarations et en particulier s’agissant des caractéristiques de la marchandise confiée (marchandises illicites, poids ou quantités erronées, gabarit non mentionnée etc. La reconnaissance de ces manquements ne pourra se faire que par la Société qui pourra à ce moment là soit proposé une modification de la livraison et la soumettre aux Parties soit annuler la livraison du</p>
                            <p>Transporteur. Cette annulation entrainera les conséquences prévues dans les présentes CGU.</p>
                            <ul>
                                <li>
                                    <p> En l'absence de réponse de l’Expéditeur et/ou de la personne à contacter au lieu d’enlèvement et/ou du destinataire aux appels téléphoniques du Transporteur afin de réaliser la livraison de la marchandise. La Société prendre exceptionnellement contact avec les Parties pour constater ce manquement et procéder à l’annulation de la livraison. Cette annulation entrainera les conséquences prévues dans les présentes</p>
                                    <p>CGU.</p>
                                </li>
                                <li>
                                    <p> En cas d'absence du destinataire à l'adresse de livraison pendant une durée de 10 minutes à compter de l'arrivée du Transporteur à ladite adresse. Le Transporteur devra préalablement contacter et avoir reçu l’accord de la Société par tout moyen (email, SMS, via l’application) pour obtenir la validation de l’annulation.</p>
                                </li>
                                <li>
                                    <p> En cas de force majeure empêchant la livraison de la marchandise. L'annulation de la livraison de marchandise pour les causes précitées lorsqu’elle est validée par la Société entrainera :</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p> hors cas de force majeure, les conséquences décrites dans les présentes CGU et sous l’article 5.1.3, l’annulation étant considérée comme une annulation aux torts de l’Expéditeur.</p>
                                </li>
                                <li>
                                    <p> Dans un cas de force majeure et après étude du cas par la Société la facturation uniquement à l’Expéditeur des frais de service. Il appartiendra au Transporteur de prendre contact avec l’Expéditeur afin de mettre en œuvre son assurance dans les conditions des RP le cas échéant.</p>
                                </li>
                            </ul>
                            <h5 style={{textDecoration : "underline"}}><strong>CAS D’ANNULATION NON PERMIS PAR LA SOCIETE</strong></h5>
                            <p>Avant la prise en charge de la marchandise le Transporteur ne répond plus aux sollicitations de la Société, à celles de l’Expéditeur, se déconnecte de la plateforme ou n’est plus géolocalisable :</p>
                            <ul>
                                <li>
                                    <p>La société prendra contact avec le Transporteur par tout moyen pour comprendre la situation et le défaut de livraison et si ces derniers peuvent être justifiées et vérifiables.<br /> La Société décidera alors des suites à donner de cette livraison et des conséquences découlant de cette annulation soit la facturation uniquement des frais de services soit la facturation des frais de service ainsi qu’une suspension du compte utilisateur pendant un délai de 7 jours.</p>
                                </li>
                                <li>
                                    <p>A défaut de réponse du Transporteur aux sollicitations de la Société, la société annulera automatiquement l’acceptation, contactera l’Expéditeur pour l’informer d’un incident et lui adressera un nouveau transporteur.</p>
                                </li>
                            </ul>
                            <p>Après la prise en charge de la marchandise et avant la livraison, le Transporteur ne répond plus aux sollicitations de la Société, à celles de l’Expéditeur, se déconnecte de la plateforme ou n’est plus géolocalisable (etc.) :</p>
                            <p>A titre liminaire, il convient de rappeler que dans une telle situation (liste non exhaustive), la Société ne saurait être tenue pour Responsable du comportement ou des actions d’un Transporteur professionnel avec la marchandise d’un Expéditeur, la Société ne s’engageant dans le cadre de la mise en relation qu’à s’assurer de l’identité des transporteurs et de la détention des justificatifs leur permettant de transporter de la marchandises à titre professionnel (assurance, carte grise, licences et permis de conduire).</p>
                            <p>Dans une telle situation, les actions suivantes seront menées par la Société :</p>
                            <ul>
                                <li>
                                    <p>La société prendra donc contact avec le Transporteur par tout moyen pour comprendre les motifs de l’annulation et si celles-ci peuvent être justifiées et vérifiables.</p>
                                </li>
                                <li>
                                    <p>la Société avertira le Transporteur de ces obligations telles que définies dans les RP et notamment celles vis-à-vis de la marchandise retenue dans son véhicule.</p>
                                </li>
                                <li>
                                    <p>La Société suspendra immédiatement le compte du Transporteur et ce jusqu’au règlement du litige entre les parties concernées.</p>
                                </li>
                                <li>
                                    <p>Bien que le contrat de transport soit conclu entre le Transporteur et l’Expéditeur, la Société mettra donc tout en œuvre pour localiser le Transporteur, le contacter et obtenir la livraison de la marchandise ou le retour dans les plus brefs délais de la marchandise au lieu d’enlèvement.</p>
                                </li>
                                <li>
                                    <p>La Société fournira à l’Expéditeur l’ensemble des informations concernant le Transporteur lui permettant d’accompagner son dépôt de plainte.</p>
                                </li>
                            </ul>
                            <h5 className={"second-title"}><label>5.1.3</label> Communication entre l’Expéditeur et le Transporteur</h5>
                            <p>A compter de l’acceptation de la demande de livraison de marchandise, le Transporteur sera en mesure de contacter autant que besoin l’Expéditeur (et notamment par exemple en cas d’incidents pendant l’exécution de la livraison ou pour obtenir des informations complémentaires).</p>
                            <p>Ce contact se fera exclusivement via l’Application qui permettra des échanges soit par téléphone soit via la</p>
                            <p>messagerie sécurisée mise en place.</p>
                            <p>L’Expéditeur s’engage donc à être joignable sur son téléphone à compter de l’acceptation de la demande (aux coordonnées indiquées par lui dans son compte) afin de répondre aux demandes du Transporteur.</p>
                            <p>Le Transporteur peut aussi être amené à contacter par téléphone pour l’enlèvement ou pour la livraison, la personne désignée par l’Expéditeur (autre que l’Expéditeur) dans le formulaire à remplir dans l’Application.</p>
                            <p>A cet égard, l’Expéditeur qui aura renseigné les coordonnées téléphoniques de ces personnes (destinataire au lieu de livraison, et/ou de la personne sur le lieu d’enlèvement) déclare avoir obtenu leurs accords et leurs permissions afin qu’ils puissent être contactés directement par le Transporteur.</p>
                            <p>En tout état de cause, il est indiqué que les coordonnées téléphoniques renseignées par l’Expéditeur ne sont rendues accessibles par l'Application que pendant le temps de la livraison de la marchandise (de l’enlèvement à la remise finale après signature).</p>
                        <h5 className={"first-title"}>5.4 Contrat de transport entre les Utilisateurs</h5>
                            <h5 className={"second-title"}><label>5.4.1</label> Principe Généraux</h5>
                            <p>L’Utilisateur reconnaît expressément que le rôle de PICK&amp;DELI est de faciliter la mise en relation avec d’autres Utilisateurs.</p>
                            <p>Il est rappelé que la Société ne propose qu'une prestation de service de mise en relation entre les Utilisateurs, tous d’eux indépendant de la Société. Cette prestation de mise en relation génère donc de fait un contrat distinct du contrat de transport, dont les modalités sont prévues par les présentes CGU.</p>
                            <p>Par conséquent, la Société n'est pas partie au contrat de transport conclu entre l’Expéditeur et le Transporteur en ce qui concerne la livraison de marchandise.</p>
                            <h5 className={"second-title"}><label>5.4.2</label> Contrat électronique de transport</h5>
                            <p>La Société permet aux Utilisateurs d'être mis en relation via l’Application en vue de la conclusion d’un contrat électronique de transport afin de procéder à une livraison de marchandise dont les modalités sont définies par l'Application dans les conditions prévues par les présentes CGU.</p>
                            <p>Au titre de ce contrat, L’Expéditeur accepte renoncer sans réserve à son droit de rétractation au moment de la soumission de la demande de livraison via l’Application sous réserve des dispositions définies à l’article 5.1.3 lui permettant d’annuler une demande de livraison jusqu’à 1 minute après la validation de la course. Passé ce délai, la rétractation ne peut donc plus avoir lieu dès lors que la demande de livraison est exécutée et traitée immédiatement via l’Application qui la soumet immédiatement pour acceptation au Transporteur.</p>
                            <p>A son tour, le Transporteur accepte renoncer sans réserve à son droit de rétractation s’agissant de la conclusion du contrat de transport, au moment de l’acceptation de la demande de livraison (soit, après avoir cliqué sur le bouton « DEMARRER ») sous réserve des dispositions définies dans les présentes CGU lui permettant d’annuler une livraison.</p>
                            <p>Le contrat de transport conclu entre l’Expéditeur et le Transporteur ne pourra être considéré comme conclu que pour autant que la demande de livraison soit clairement définie, l’Expéditeur étant tenu de renseigner dans l’Application au moment de la formulation de la demande, l’ensemble des informations sollicitées par l’Application.</p>
                            <p>Il est rappelé que l’Expéditeur ne pourra valider sa demande de livraison que si l’ensemble des champs considérés comme obligatoire par la Société sont clairement renseignés.</p>
                            <p>L’Expéditeur déclare et reconnaît irrévocablement que l’acceptation par le Transporteur de sa demande, créée une offre suffisamment précise, ferme, non équivoque, sans réserve, de nature à engager l’Expéditeur, dès l'acceptation par un Transporteur de la livraison de marchandise sauf annulation dans les conditions et délais prévus par les présentes CGU.</p>
                            <h5 className={"second-title"}>5.5 Contrat d’Utilisation de l’Application entre la Société et les Utilisateurs</h5>
    <div  >

        <div >
            <div >
                <p>Il est rappelé que la Société ne propose qu'une prestation de service de mise en relation entre les Utilisateurs, tous d’eux indépendant de la Société. Cette prestation de mise en relation est exécutée immédiatement via l’Application dès lors que les Utilisateurs acceptent d’utiliser les services proposées par la Société.</p>
                <p>Conformément à l’article L 221-28 1° et 2° du Code de la consommation, le droit de rétractation ne peut donc pas être exercé pour les contrats résultant de l’utilisation s’agissant de services pleinement exécutées avant la fin du délai de rétractation et s’agissant de services de transport de bien.</p>
                <h5 className={"second-title"}>5.6 Preuve d’acheminement de la marchandise</h5>
                <p>L’enlèvement de la marchandise devra faire l’objet d’une photo prise par le Transporteur, ce dernier s’obligeant à solliciter la signature de la personne lui remettant la marchandise (à défaut l’Expéditeur).</p>
                <p>La livraison de la marchandise devra faire l’objet d’une photo prise par le Transporteur, ce dernier s’obligeant à solliciter la signature de la personne lui remettant la marchandise (à défaut le destinataire).</p>
                <p>A l’issue de la livraison, l’Expéditeur reçoit les informations recueillies ci-dessus par le Transporteur avec l’indication de la bonne livraison de la marchandise.</p>
                <p>Les parties reconnaissent que la signature du destinataire sur l'Application via le Smartphone du Transporteur ou l'attestation sur l'honneur du Transporteur en cas de refus insurmontable de signature de la part du destinataire ou d'oubli du Transporteur de demander la signature, ou l'activation de la consigne lors du dépôt de la marchandise par le transporteur, font preuve de la livraison de la marchandise et ont une valeur juridique identique à celle d'une signature sur support papier.</p>
                <h5 className={"second-title"}>5.7 Marchandises exclues</h5>
                <p>L’Expéditeur déclare et garantit qu'il n'utilise pas l'Application pour faire effectuer des livraison de marchandise(s) qui seraient interdites par la loi ou par toute autre disposition réglementaire et/ou qui pourraient présenter un quelconque danger soit pour le Transporteur soit pour les tiers.</p>
                <h5 className={"second-title"}>5.8 Tarif et règlement des prestations</h5>
                   <p> Il est rappelé que l’utilisation de l’Application est gratuite après ouverture d’un compte.</p>
                <p>La prestation de transport correspond à un tarif déterminé, par demande de livraison, en étant calculé automatiquement par l'Application au terme d'un algorithme qui prend en compte une somme de variables, tels que la caractéristique de la marchandise, le poids, le nombre de colis ainsi que la distance à parcourir entre les adresses d’enlèvement et de livraison.</p>
                <p>Ce tarif inclut les frais de service de mise en relation due par l’Expéditeurs à la Société.</p>
                <p>Le paiement par l’Expéditeur de la prestation de transport de marchandise est encaissé et facturé, par la Société, au nom et pour le compte du Transporteur, la Société agissant dans le cadre d’un mandat de facturation.</p>
                <p>En cas d’annulation de la demande de livraison aux torts de l’Expéditeur, la Société facturera au nom et pour le compte du Transporteur les frais d’annulation (déterminés par les présentes CGU) et prélèvera par conséquent le montant de ces frais directement sur le moyen de paiement utilisé par l’Expéditeur pour régler le montant de la prestation de transport.</p>
                <p>Les frais de service facturés par la Société à l’Expéditeur feront l’objet d’une facturation séparée, l’ensemble (prestation de transport et frais de services) étant consigné dans un document (type reçu) mise à disposition de l’Expéditeur pour informations et visible sur l’Application.</p>
                <p>Les factures seront mises à la disposition de l’Expéditeur sous format PDF en version téléchargeable. L’Expéditeur accepte donc de recevoir ces factures sur son Application et sous le format proposé par la Société.</p>
                <p>Les règlements des Tarifs sont effectués en euros et selon un moyen qui est fonction du site web et de l'application utilisée (carte bancaire).</p>
            </div>
        </div>
        <div >
            <div >
                <p>Le paiement est exigible immédiatement au moment de la demande de transport et aucune demande de livraison de marchandise ne sera prise en compte sans le paiement immédiat de la demande de transport.</p>
            </div>
        </div>
    </div>
    <div  >
        <div >
            <div >
                <p>Tout défaut de paiement par un Expéditeur, notamment du fait de l'expiration de la carte bancaire renseignée, d'un solde insuffisant sur le compte bancaire ou de coordonnées bancaire inexactes, entraînera la non prise en charge de la demande de livraison par la Société et l’annulation de celle-ci automatiquement.</p>
                <p>Il appartient donc à l’Expéditeur de s'assurer que ses informations de facturation sont à jour, complètes et correctes.</p>
                <p>À tout moment, l’Expéditeur peut se rendre sur l’Application pour demander que soit supprimée, la conservation de ses données bancaires par le prestataire bancaire de la Société.</p>
                <p>Une grille tarifaire est accessible sur demande en envoyant un message à l'adresse email suivante : service.client@pickdeli.fr</p>
                <h5 className={"second-title"}>5.9 Obligation de l’Expéditeur</h5>
                <p>La Société ou le Transporteur ne saurait donc être tenus pour responsable d’un quelconque incident en rapport avec la défectuosité de l’emballage.</p>
                <p>L’Expéditeur peut toutefois, s’il le souhaite laisser un commentaire lors de sa demande de livraison sur l’Application, pour informer le transporteur sur le gabarit, le conditionnement de la marchandise ou tout autre commentaire pour faciliter l’acheminement de la marchandise. Dans un tel cas, il ne saurait être reproché un manquement à l’Expéditeur si celui-ci précise une information au Transporteur au moment de la demande, le Transporteur restant libre d’accepter ou refuser la demande compte tenu de ces informations.</p>
                <h4>ARTICLE 6 - RESPONSABILITE</h4>
                <p>L’Expéditeur est informé que la responsabilité du Transporteur à son égard est limitée selon les RP.</p>
                <p>L’Expéditeur est par ailleurs informé que la Société ne saurait être tenu pour responsable d’un incident concernant l’enlèvement ou la marchandise, son rôle étant limité à la bonne mise en relation entre les Parties.</p>
                <p>Le Transporteur est informé que la responsabilité de l’Expéditeur à son égard est limité selon les dispositions des présentes CGU.</p>
                <p>Toutefois et en cas de litige entre les utilisateurs ou d’incident sur la marchandise, la Société pourra sur demande de l’une ou l’autre des Parties, tenter (comme médiateur uniquement) de les aider à résoudre leurs litiges nés de telles réclamations, et transmettre à l’Expéditeur les coordonnées de l'assureur de responsabilité du Transporteur en cas d'abstention prolongée de ce dernier.</p>
                <h5 className={"second-title"}>6.1 Garantie des parties</h5>
                <p>La Société rappelle aux utilisateurs qu’ils sont seuls responsables de l'utilisation des services proposés par l’Application.</p>
                <p>Les Utilisateurs s’interdisent donc :</p>
                <ul>
                    <li>
                        <p> d’utiliser l’Application à d’autres fins que celles définies dans les présentes CGU ;</p>
                    </li>
                    <li>
                        <p> d’utiliser l’Application pour tenter de nuire à la Société ou à l’une des Parties ;</p>
                    </li>
                    <li>
                        </li><li> d’extraire de quelconque données de l’Application à des fins commerciales ou à des fins étrangères qui viendrait à nuire à l’image de la Société.
                    </li><li>Dans le cas où la Société viendrait à constater l’un des manquements ci-dessous, étant rappelé que la liste ci- dessus est non exhaustive, la Société se réserve le droit de suspendre de manière définitive le compte de l’Utilisateur et d’engager les poursuites nécessaires le cas échéant à l’encontre de cet Utilisateur.
                    </li><li>De manière générale, la Société s’engage uniquement à une obligation de moyen quant à la mise en relation entre les Parties, le succès de cette mise en relation n’étant rendu possible que grâce aux interventions des deux Parties, à la pertinence des informations fournies par l’Expéditeur et à la disponibilité immédiate d’un Transporteur.
                    </li>
                </ul>
                <h5 className={"second-title"}>6.2 Responsabilité de la Société quant à la mise en relation des parties</h5>
            </div>
        </div>
        <div >
            <div >
                <p>Lors de l’enlèvement de la marchandise, l’Expéditeur ou la personne désignée pour remettre celle-ci s’engage à ce que la marchandise soit préparée correctement et soigneusement emballée.</p>
            </div>
        </div>
    </div>
    <div>

        <div >
            <div >
                <p>En outre, La Société décline donc toute responsabilité en cas d’informations erronées, partielles fournies par les Parties et ne peut voir sa responsabilité engagée en cas d'utilisation de l'Application dans des conditions non- conformes aux termes des présentes CGU,</p>
                <p>La Société ne saurait être tenue responsable de tout dysfonctionnement de quelque nature, qu'il soit relatif au Smartphone de l’Expéditeur ou du Smartphone du Transporteur, ainsi qu'à sa connexion d'accès à internet, lors de l'accès à l'Application et plus généralement aux services de mise en relation.</p>
                <p>La Société ne fournit aucune garantie en matière de vitesses d'accès à l'Application ou en matière de disponibilité et d'acceptation de Transporteurs.</p>
                <p>Chaque Utilisateur est invité à vérifier régulièrement qu'elle dispose de la dernière version de l'Application et qu'elle a bien téléchargé une version qui correspond à son Smartphone. La Société ne sera en aucun cas responsable si la partie a téléchargé la mauvaise version de l'Application ou une version incompatible avec son Smartphone, ou encore si elle ne respecte pas les règles propres à l'App Store d'Apple ou le Play Store de Google ou au site et Application d'une société offrant les services via son propre site ou Application.</p>
                <h5 className={"second-title"}>6.3 Responsabilité de la Société quant à l’exécution de la prestation de transport</h5>
                <p>L’Expéditeur reconnaît que la bonne exécution de la prestation de transport demandés par le biais de l'Application relève entièrement et uniquement de la responsabilité du Transporteur qui effectue la livraison de marchandise.</p>
                <p>La Société ne saurait donc être tenue responsable de la mauvaise exécution de la prestation de transport par le Transporteur ou du non-respect par le Transporteur des indications fournies par l’Expéditeur pour l’enlèvement ou la livraison de la marchandise et plus généralement du comportement, des actes ou de la négligence du Transporteur vis-à-vis de la marchandise transportée.</p>
                <p>La Société ne saurait être tenue pour responsable de l’atteinte à la marchandise qui pourrait être constatée par le destinataire de la marchandise.</p>
                <p>Dans une telle situation, la Société s’attachera à vérifier les informations fournies par le destinataire à en faire part à l’Expéditeur pour qu’il puisse entrer en contact avec le Transporteur. A cet égard, l’Application prévoit pour l’enlèvement et la livraison la prise de photos et la possibilité pour les Parties de fournir des commentaires sur la livraison et sur la marchandise livrée. Dans l’hypothèse d’un incident concernant la marchandise, il appartiendra à l’Expéditeur ou au Transporteur de prendre contact afin de mettre en œuvre leur assurance dans les conditions des RP pour obtenir un dédommagement le cas échéant.</p>
                <p>Ainsi, la Société ne pourra donc se contenter que d’intervenir dans la mise en contact entre les deux parties, dans la fourniture d’informations dont elle dispose, sans jamais prendre parti ni pour l’une ni pour l’autre des Parties.</p>
                <p>Toute réclamation au sujet d'une livraison de marchandise devra être directement adressée au Transporteur sous 24h suivant la date d’enlèvement de la demande, la Société s’engageant uniquement à fournir les coordonnées du Transporteur le cas échéant et toutes les informations utiles à l’une ou l’autre des Parties.</p>
                <h4>ARTICLE 7 - ACCES ET DISPONIBILITE DE L’APPLICATION ET DES SERVICES</h4>
                <p>Les services sont librement et exclusivement accessibles en ligne sur l'Application.</p>
                <p>La Société met tout en œuvre afin de rendre l'application et ses services disponibles 24 heures sur 24 et 7 jours sur 7, indépendamment des opérations de maintenance de ladite application et/ou desdits services. A ce titre, la Société est tenue d'une obligation de moyens.</p>
                <p>La Société se réserve la possibilité de modifier, interrompre, à tout moment, temporairement ou de manière permanente tout ou partie des services sans information préalable des Parties et sans droit à indemnités.<br /> La Société met tout en œuvre pour mettre en relation un Expéditeur avec un Transporteur disponible dans la zone géographique de l’adresse d’enlèvement de la marchandise, et enclin à accepter une livraison de marchandise. Toutefois, elle ne peut garantir une disponibilité d'un nombre minimum de Transporteurs ou disposant du procédé de transport choisi par l’Application suivant les informations renseignées par l’Expéditeur, pas plus qu'elle ne peut garantir que les Transporteurs les cas échéants disponibles accepteront les demandes de livraison de marchandise.</p>
                <h4>ARTICLE 8 - TRIBUNAL COMPETENT</h4>
                <p>Les présentes CGU sont soumises au droit français.</p>
            </div>
        </div>
    </div>
    <div>

        <div >
            <div >
                <p>En cas de litige ou de contestation de quelconque nature, avec l’expéditeur ou le Transporteur, seuls les Tribunaux de Nanterre sont compétents, même en cas de pluralité de défendeurs ou d’appels en garantie.</p>
            </div>
        </div>
        <div >
            <div >
                <p>En cas de difficultés, notamment dans l'interprétation, l'exécution, la cessation ou la résiliation des relations résultant de l'application des présentes CGU, les parties et/ou la Société rechercheront avant tout une solution amiable (sauf cas d'urgence ou de référé), et à défaut d'y être parvenu dans un délai de 30 jours après la première réclamation, elles acceptent expressément, dans la limite de la législation, de soumettre tout litige relatif aux CGU à la compétence exclusive des tribunaux de Nanterre.</p>
                <p>Si l’Expéditeur est un consommateur, il est informé qu'il a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable du litige qui l'oppose à la Société ou au Transporteur, en application des articles L.612-1 et suivants, L.616-1 et suivants, et R.612-1 et suivants du code de la consommation.</p>
            </div>
        </div>
    </div>
    <div>
        <div >
            <div >
                <h4>REGLES PARTICULIERES APPLICABLES AUX TRANSPORTEURS</h4>
                <p>Les dispositions ci-après constituent les règles particulières qui viennent compléter et préciser les CGU à l’égard des Transporteurs.</p>
                <p>Pour les Transporteurs uniquement, les CGU inclut donc des RP, le tout formant un bloc indivisible ne pouvant être qu’accepter ensemble ou refuser ensemble.</p>
                <p>Au moment de la finalisation de la création de son compte, le transporteur doit s’engager à accepter sans réserve les CGU étant entendu que cette acceptation couvre les CGU ainsi que les RP.</p>
                <h4>ARTICLE 1 - INFORMATIONS GENERALES</h4>
                    <h5 className="second-title">1.1 Devenir Transporteur</h5>
                <p>La Société est libre d'accepter ou non la demande d'une personne physique ou morale d'accéder aux services et à l'application pour exercer une activité de Transporteur.</p>
                <p>Personne physique</p>
                <p>Pour devenir Transporteur, toute personne physique doit s'enregistrer sur l'application selon le procédé accessible depuis le site www.pickdeli.fr ou en téléchargeant l’application depuis l'App Store d'Apple ou le Google Play Store d’Android.<br /> Le Transporteur est ensuite invité à suivre les étapes décrites par l’Application impliquant l'acceptation des présentes CGU (impliquant les RP), avant de pouvoir accepter et réaliser les livraisons de marchandise proposés par l'Application</p>
                <p>Pour finaliser son inscription, le Transporteur doit communiquer à la Société les documents suivants :</p>
                <ul>
                    <li>
                        <p> Une copie recto/verso d'une pièce d'identité en cours de validité,</p>
                    </li>
                    <li>
                        <p> Une copie recto/verso du permis de conduire en cours de validité,</p>
                    </li>
                    <li>
                        <p> Un extrait de son inscription au registre du commerce et des sociétés (Kbis) de moins de trois (3) mois,
                        ou une carte d'identification justifiant de l'inscription au répertoire des métiers de moins de trois (3) mois</p>
                    </li>
                    <li>
                        <p> Un RIB/IBAN au nom et adresse du travailleur indépendant</p>
                    </li>
                    <li>
                        <p> Carte grise du ou des véhicules enregistrés sur l’Application</p>
                    </li>
                    <li>
                        Assurance du ou des véhicules enregistrés sur l’application en cours de validité
                        </li>
                    <li>Personne morale</li>
                        <li>Une entreprise de transport peut utiliser l'Application afin de proposer ses chauffeurs salariés comme Transporteur.</li>
                        <li>L’entreprise de transport est donc considérée comme un Transporteur qui accepte de se conformer aux présentes CGU et RP et par conséquent qui engage sa propre responsabilité à l’égard des chauffeurs salariés qui sont enregistrés sous son nom d’utilisateur et qui effectuent les livraisons de marchandise pour son compte.</li>
                        <li>La personne morale doit s'enregistrer sur l'application selon le procédé accessible depuis le site www.pickdeli.fr ou en téléchargeant l’application depuis l'App Store d'Apple ou le Google Play Store d’Android.</li>
                        <li>La personne morale doit, dès la création de son compte, communiquer à la Société les documents suivants :</li>

                </ul>
                <ul>
                    <li>
                        <p> Outre les documents obligatoires réclamés ci-dessus aux personnes physiques,</p>
                    </li>
                    <li>
                        <p> La copie de la pièce d'identité en cours de validité du représentant de la personne morale,</p>
                    </li>
                    <li>
                        <p> La photocopie de la licence définitive de transport de marchandise requis par les textes en vigueur en
                        cours de validité, que la personne morale doit faire renouveler tant qu'elle détient un compte,</p>
                    </li>
                    <li>
                        <p> La photocopie de l'attestation de capacité professionnelle en transport (- et + de 3,5 tonnes) de
                        marchandise en cours de validité afférent à ses activités de transport requis par les textes en vigueur,</p>
                    </li>
                    <li>
                        <p> Une assurance Responsabilité Civile Professionnelle et Dommages aux biens et couvrant son activité et celles de ses salariés impliqués et enregistrés résultant des présentes CGU, et qu'elle est à jour de ses
                        cotisations.</p>
                    </li>
                    <li>
                        <p> Une assurance de transport de marchandise couvrant les biens transportés, celle-ci devant être à jour de
                        ses cotisations.</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div>
        <div >
            <div >
                <p>En cas d'expiration, de suspension ou de retrait de sa licence et/ou capacité et/ou de son inscription au registre précité, le Transporteur personne morale doit en informer sans délai la Société qui se réserve le droit de suspendre son compte jusqu’à la régularisation de sa situation.</p>
                <p>La personne morale s'engage expressément pour ses salariés à respecter les dispositions prévues par le Code du travail, notamment par les articles L.8221-1 et suivants et L.8251-1 et suivants relatifs à la lutte contre le travail dissimulé et le travail des étrangers en situation irrégulière.</p>
                <p>La Société créera ensuite des sous comptes pour chaque chauffeur salarié ajouté sur son interface par la personne morale. Ces salariés seront alors tous considérés comme des Transporteurs et pourront effectuer des livraisons de marchandise et communiquer avec l’Expéditeur. Toutefois, ces livraisons de marchandises seront effectués sous le contrôle de la personne morale qui reste seule autorisée à accepter à distance pour ses chauffeurs salariés les demandes de livraison.</p>
                <p>L'ensemble des éléments de facturation sera alloué uniquement au compte administrateur de la personne morale qui sera seule en mesure de visualiser et de télécharger les factures.</p>
                <h4>ARTICLE 2 - VEHICULE DE TRANSPORT </h4>
                    <h5 className={"second-title"}>2.1 Choix du véhicule de transport</h5>
                <p>Le Transporteur doit mentionner lors de la création de son compte utilisateur la catégorie du véhicule utilisé pour transporter les marchandises.</p>
                <p>Cette catégorie et le nom du véhicule sera ensuite utilisé par l’Application pour proposer des demandes de livraison au Transporteur dont le véhicule correspond aux caractéristiques de la marchandise qui doit être transportée.</p>
                <p>Le véhicule de transport sera en tout état de cause la propriété du Transporteur et ne sera pas fourni par la Société.</p>
                <p>Le véhicule de transport devra être conforme aux exigences de sécurité et d'exercice de la profession de Transporteur, c'est-à-dire notamment être en bon état de fonctionnement.</p>
                <p>Dans ce sens, le véhicule de transport devra aussi présenter un aspect visuel et un état de fonctionnement qui n'est pas susceptible de porter atteinte aux exigences des Expéditeurs.</p>
                <p>Les opérations d'entretien sur le véhicule de transport, et ce y compris la fourniture de carburant ou, le cas échéant la recharge de la batterie, sont à la charge du Transporteur qui en est le propriétaire ou usager exclusif et ne donneront lieu à aucun dédommagement ou participation financière de la part de la Société.</p>
                <p>Si le Transporteur souhaite changer de véhicule de transport, il devra modifier et enregistrer le changement sur l'Application par la Société après communication des justificatifs nécessaires.</p>
                <p>A défaut de répondre aux exigences susmentionnées, le Transporteur pourra voir son compte suspendu et ne pourra plus accepter de demandes de livraisons de marchandise depuis l'application tant qu'il n'aura pas régularisé sa situation.</p>
                <h5 className={"second-title"}>2.2 Mise à jour des informations et renouvellement des justificatifs</h5>
                <p>Dès qu'il ne remplit plus une ou plusieurs des conditions pour réaliser les livraisons de marchandise, notamment en cas de suspension/retrait d'au moins un des documents obligatoires susmentionnés, et ce, dans un délai de vingt-quatre heures suivant la survenance de l'évènement, le Transporteur devra en informer immédiatement la Société à l'adresse : contact@pickdeli.fr.</p>
                <p>La Société se réserve alors le droit de suspendre son accès aux services tant qu'il n'aura pas régularisé sa situation.</p>
                <p>Le Transporteur s'engage par ailleurs, sans intervention de la Société, à mettre à jour, tous les six mois, les documents mentionnés dans les RP pour les personnes physiques et pour les personnes morales, et de répondre, sans délai, à toute demande de communication faite la Société.</p>
                <p>En cas d'absence de communication d’un des documents dans les délais requis, la Société pourra suspendre de plein droit l'accès aux services par le Transporteur.</p>
            </div>
        </div>
    </div>
    <div>
        <div >
            <div >
                <h4>ARTICLE 3 - DISPONIBILITE DU TRANSPORTEUR</h4>
                <p>Pour pouvoir accepter une prestation de livraison de marchandise, le Transporteur s’engage à respecter la procédure suivante :</p>
                <ul>
                    <li>
                        <p> Utiliser l'application depuis son Smartphone et s'y connecter avec son compte via ses éléments d'identification,</p>
                    </li>
                    <li>
                        <p> Déclarer sa disponibilité en appuyant sur le bouton prévu à cet effet sur l'application,</p>
                    </li>
                    <li>
                        <p> Autoriser l'Application à utiliser la fonction de géolocalisation de son Smartphone pour lui proposer des
                        demandes de livraison</p>
                </li>
            </ul>
                        <p>Laissez l’Application allumée et ne pas se déconnecter</p>
                        <p>Lorsque le Transporteur se connecte à l'Application, le Transporteur doit disposer du véhicule qu’il a renseigné lors de son inscription ou des informations du véhicule récemment mis à jour lui permettant d’exécuter la réalisation de la livraison des marchandises.</p>
                        <p>Le Transporteur reconnaît avoir été informé du fait que l'Application peut le localiser, en temps réel, et l'accepte dès lors que son Smartphone est allumé.</p>
                        <p>Le Transporteur recevra une notification sur l'Application indiquant qu’une demande de livraison est faite par un Expéditeur sur la base de cette géolocalisation, le Transporteur restant libre d’accepter ou de refuser cette demande sans conséquence.</p>
                        <h5 className={"second-title"}>6.1 Réception d’une notification de demande de livraison</h5>
                        <p>Le Transporteur est libre d'accepter ou de refuser une demande de livraisons de marchandise. Il dispose d'un délai d’une minute pour ce faire. Les informations suivantes lui sont communiquées :</p>
                        <ul>
                            <li>
                                <p> Lieu d’enlèvement et lieu de livraison de marchandise,</p>
                            </li>
                            <li>
                                <p> Des commentaires faits par l’Expéditeur ayant une incidence sur la livraison</p>
                            </li>
                            <li>
                                <p> La plaque d’immatriculation du véhicule de transport géolocalisé.</p>
                            </li>

                                <li>Le Tarif de la livraison</li>
                        </ul>
                                <p>Si la demande de livraison de marchandise nécessite, à la demande de l’Expéditeur, des conditions de transport spécifiques, (par exemple, besoin d’un diable), le Transporteur devra s'assurer, préalablement à son acceptation, être en conformité à ces exigences.</p>
                                <p>L'acceptation par le Transporteur, conformément aux CGU, crée une rencontre des volontés entre l’Expéditeur et le Transporteur, de telle sorte que le Transporteur s'engage irrévocablement à réaliser la livraison de la marchandise demandée et acceptée, et que l’Expéditeur s'engage à payer le Tarif dû.</p>
                                <p>Le Transporteur s'engage à ne pas refuser des demandes de livraison de marchandise d'une manière qui traduirait en réalité une indisponibilité manifestement contraire au statut qu'il affiche, afin de ne pas compromettre le bon fonctionnement ou la qualité des services au détriment des Expéditeur et du Transporteur.</p>
                                <p>Les conditions d'annulation par un Expéditeur et ses effets sont détaillés dans les CGU.</p>
                                <h4>ARTICLE 4 - ACCEPTATION ET REALISATION D’UNE DEMANDE DE LIVRAISON DE MARCHANDISE</h4>
                                <h5 className={"second-title"}>4.1 Dispositions communes</h5>
                                <p>La Société ne s'engage en aucun cas à fournir au Transporteur un nombre minimum de livraisons de marchandise à réaliser. La Société ne s'oblige à l'égard du Transporteur que dans le cadre d'une obligation de moyens à le mettre en relation avec des Expéditeurs désireux de procéder à une livraison de marchandise, et ne peut lui donner de garantie d'un nombre minimum d’Expéditeurs.</p>
                                <h5 className={"second-title"}>4.2 Acceptation d’une demande de livraison de marchandise</h5>
                                <p>Dès que le Transporteur a accepté la demande de livraison de marchandise, via l’Application, il s'engage à se rendre à l'adresse d’enlèvement de la marchandise indiquée par l’Expéditeur afin de récupérer ladite marchandise puis à remettre la marchandise au destinataire sans rupture de charge, dans un délai raisonnable en fonction notamment de son véhicule de transport, de la nature de la marchandise (température à conserver par exemple), des conditions de circulation, météorologiques, etc., et également en fonction des demandes de l’Expéditeur.</p>


            </div>
        </div>
    </div>
    <div>
        <div >
            <div >
                <p>Il doit effectuer la livraison de la marchandise jusqu’au pied de l’immeuble sauf demande expresse de l’Expéditeur</p>
                <p>à laquelle le Transporteur reste libre de refuser ou d’accepter au moment de la livraison.</p>
                <p>Le Transporteur n'est pas tenu à une obligation de vérification de la marchandise confiée, mais s'il s'aperçoit qu'elle fait partie des cas d’interdictions prévues par les présentes CGU et/ou est illicite, il doit refuser d'effectuer la livraison.</p>
                <p>Le Transporteur s'engage à réaliser les livraisons de marchandise avec diligence et professionnalisme, et en faisant preuve de la plus grande prudence.</p>
                <p>Le Transporteur s'engage notamment à se comporter professionnellement avec la marchandise, c'est-à-dire veiller à ce qu'elle ne soit ni détériorée, ni détruite, ni volée, ni même perdue, comme dans la conduite de son véhicule de transport, et à respecter scrupuleusement les conditions d'hygiène et de sécurité les concernant tant dans ses actions envers elle que dans sa propre présentation.</p>
                <p>Le Transporteur devra entrer en contact avec le destinataire de la livraison de marchandise afin de :</p>
                <ul>
                    <li>
                        <p> lui remettre la marchandise à l'adresse de livraison, conformément aux informations mises à sa disposition via l'application par l’Expéditeur,</p>
                    </li>
                    <li>
                        <p> confirmer l'accomplissement de la livraison de la marchandise par le biais de l'application, en demandant au destinataire de la livraison de marchandise d'apposer sa signature dans l'emplacement prévu à cet effet dans l'application puis d'appuyer sur le bouton « signature client », et de prendre une photo de la marchandise dans l'emplacement prévu à cet effet dans l'application</p>
                    </li>
                <li>l'informer que s'il souhaite former des réserves, il doit le faire lors de la remise de la marchandise, sur l'application et de manière détaillée, et recueillir de telles réserves ou laisser le destinataire les formuler.</li>
                </ul>
                <p>Tout refus de marchandise, pour une raison autre que la destruction, la détérioration, l'avarie, la perte ou le vol de la marchandise sera considérée comme une annulation de la livraison de marchandise par l’Expéditeur entrainant les conséquences décrites dans les CGU.</p>
                        <p>Si la marchandise venait à être détruite, détériorée, perdue ou volée, ou si le destinataire était absent et injoignable, ou si le destinataire refusait de signer auprès du Transporteur pour attester de la bonne réception de la marchandise (sauf cas de refus insurmontable spécifié ci-dessus), et/ou si le Transporteur est dans l'incapacité de réaliser la livraison de marchandise pour une quelconque raison, alors le Transporteur s'engage à en avertir promptement l’Expéditeur en précisant l'état de la marchandise, puis, le cas échéant selon l'état de la marchandise et les demandes de l’Expéditeur, rapporter la marchandise au lieu d’enlèvement de la marchandise dans les meilleurs délais.</p>
                        <p>En l'absence de réponse de l’Expéditeur et de la personne a contacter au lieu d’enlèvement malgré des tentatives réitérées de sa part, le Transporteur pourra alors, via l'application, avertir en dernier recours la Société qui tentera de contacter à son tour l’Expéditeur et/ou la personne à contacter au lieu d’enlèvement au nom et pour le compte du Transporteur.</p>
                        <p>Le Transporteur prend contact avec l’Expéditeur ou la personne à contacter au lieu d’enlèvement ou le destinataire dès que cela est nécessaire pour la bonne réalisation de la livraison des marchandises ou pour signaler tout évènement devant l'être.</p>
                        <p>L'acceptation d'une livraison de marchandise implique que le Transporteur puisse être amené à rapporter la marchandise au lieu d’enlèvement dans plusieurs hypothèses décrites dans les présentes CGU telles que, et sans que cette liste ne soit limitative, l'annulation par l’Expéditeur, le refus de signature par le destinataire, ou encore la détérioration de la marchandise.</p>
                        <p>En conséquence, le Transporteur, par l'acceptation de la livraison de marchandise accepte qu'il soit susceptible de réaliser une telle course retour et ce, dans les conditions tarifaires définies selon la grille tarifaire (voir les présentes CGU).</p>
                        <p>Le Transporteur est conscient que cette course retour peut lui allonger son temps de livraison de marchandise. Il s'engage à faire tout ce qui est dans son possible pour rapporter la marchandise dans un délai raisonnable.</p>
            </div>
        </div>
    </div>

                <h4>ARTICLE 5 - GARANTIE ET ENGAGEMENT DU TRANSPORTEUR</h4>
                <p>Outre ses obligations résultant des autres dispositions des présentes CGU et en particulier des RP, le Transporteur prend les engagements suivants dans l'intérêt des Expéditeurs et du bon fonctionnement des Services, et donc dans son intérêt.</p>
                <h5 className={"second-title"}>5.1 Garanties et engagements généraux du Transporteur</h5>
                <p>Le Transporteur s'engage à être en parfaite conformité avec la réglementation en vigueur applicable à l'utilisation de son véhicule de transport et au transport, notamment de marchandise.</p>
                <p>Le Transporteur s'engage plus généralement à respecter la réglementation française en vigueur, en ce compris pour les personnes morales, la législation sociale (respect de la durée maximale du temps de travail de ses salariés par exemple), et notamment à réaliser l'intégralité des déclarations (fiscales ou sociales par exemple) qui sont exigées de lui au regard de son activité de Transporteur, sans que la réalisation ou l'absence de celles-ci ne puissent être imputable à la Société.</p>
                <p>Le Transporteur déclare et garantit disposer d'une maîtrise suffisante de la langue française afin de pouvoir utiliser correctement l'Application et fournir ses prestations de livraison de marchandise sur celle-ci.</p>
                <p>Le Transporteur s'engage à prendre toutes les précautions possibles pour être dans les meilleures conditions possibles dès qu'il se connecte à l’Application, de manière à être physiquement et psychologiquement apte à effectuer la livraison de marchandise, à ne pas compromettre sa santé, ni les relations professionnelles et courtoises qu'il doit avoir avec toute personne pendant la réalisation d'une livraison de marchandise.</p>
                <p>Le Transporteur s'engage également à ne pas être sous l'emprise d'un état alcoolique et ou sous l’emprise de substance illicite tel que sanctionné par la règlementation en vigueur.</p>
                <p>Le Transporteur s'engage au strict respect du code de la route. Il est précisé que toute infraction dressée à son encontre en cas de violation du code de la route restera à sa seule charge et qu'il assumera seul les conséquences d'éventuelles poursuites, de même qu'il assumera tout dommage causé aux tiers ou aux biens pendant une livraison de marchandises. Il ne doit pas effectuer de transport de personne(s) pendant la livraison de marchandise à titre onéreux.</p>
                <p>Dès lors qu'il entre en contact avec une personne pendant la livraison de la marchandise, le Transporteur doit faire preuve de courtoisie et de calme, éviter toute altercation verbale ou physique, s'abstenir de tout acte ou propos intimidant, dénigrant, agressif ou malveillant comme de tout acte et propos sanctionné par la loi (type injure, diffamation, apologie d'actes illicites), en particulier envers l’Expéditeur, la personne à contacter au lieu d’enlèvement, le destinataire et la Société.</p>
                <p>Dans l'intérêt de tous les Expéditeurs et le sien, chaque Transporteur doit veiller à préserver l'image et la réputation de l’Expéditeur, de la personne à contacter au lieu d’enlèvement, du destinataire, de la Société et des services, et à avoir des relations apaisées et de confiance avec eux.</p>
                <p>Le Transporteur est invité à signaler à la Société tout accident, difficulté ou incident survenu dans le cadre de la réalisation de livraison de marchandise, et à lui indiquer par écrit les circonstances de cet accident, difficulté ou incident. Le fait d'informer la Société de l'accident, difficulté ou incident ne peut en aucun cas entraîner la mise en cause de la responsabilité de la Société.</p>
                <p>Au regard de tout ce qui précède, le Transporteur reconnaît qu'il est à tout moment seul et entier responsable de ses actes ou omissions envers lui-même ou vis-à-vis des Expéditeurs et des tiers, y compris le destinataire de la livraison de marchandise.</p>
            <h5 className={"second-title"}>5.2 Garanties et engagements spécifiques du Transporteur</h5>
                <p>Le Transporteur est l'unique responsable de tout élément remis par l’Expéditeur ou la personne à contacter au lieu d’enlèvement et il en est le seul gardien. Le Transporteur utilisera et restituera lesdits éléments dans le respect desdites CGU et des consignes données par l’Expéditeur, et assumera toute responsabilité en cas de dégradation ou casse de ces éléments ou de dommages causés par ces éléments, les frais de réparation ou de remplacement et les indemnités étant à sa charge exclusive.</p>

    <div>
        <h5 className={"second-title"}>5.3 Manquements</h5>
        <div >
            <div >
                <p>Dans l'intérêt des parties, et en vue d'assurer la qualité et le bon fonctionnement des services, toute violation par un Transporteur des CGU (et/ou donc des présentes RP), ou tout manquement de sa part à une de ses obligations prévues par les présentes CGU envers un Expéditeur et/ou la personne à contacter au lieu d’enlèvement et/ou un destinataire et/ou la Société, ou toute déclaration inexacte de sa part, ou tout défaut de communication ou de mise à jour des informations et documents requis, pourra conduire sans préavis, à une suspension par la Société de l'accès aux services du Transporteur, ou à une privation définitive de cet accès et cessation de toute relation, en fonction de la gravité du manquement ou de ses conséquences.</p>
                <h4>ARTICLE 6 - ASSURANCES</h4>
                <p>Dans le strict respect de l'indépendance des Transporteurs et dans le souhait que chaque Transporteur assume sa responsabilité sociale à l'égard des Expéditeurs qui utilisent la plateforme de mise en relation, le Transporteur doit être titulaire d'une assurance marchandise.</p>
                <h5 className={"second-title"}>6.1 Responsabilité Civile Professionnelle</h5>
                <p>Le Transporteur s’engage à être titulaire d'une assurance Responsabilité Civile Professionnelle couvrant les conséquences pécuniaires de la Responsabilité Civile Professionnelle pouvant lui incomber du fait de livraisons de marchandises par le biais de l'application.</p>
            </div>
        </div>
        <div >
            <div >
                <p>Le Transporteur :</p>
            </div>
        </div>
        <div >
            <div >
                <ul>
                    <li>
                        <p>devra présenter à la Société, au moment de son enregistrement sur l'application, une attestation d'assurance, certifiée en cours de validité et la preuve qu'il est à jour de ses cotisations,</p>
                    </li>
                    <li>
                        <p>prendra entièrement à sa charge la cotisation afférente,</p>
                    </li>
                    <li>
                        <p>devra renouveler cette police et être à jour de ses cotisations tant qu'il est Transporteur, et
                        devra en justifier à tout moment sur première demande de la Société.</p>
                    </li>
                </ul>
            </div>
        </div>
        <div >
            <div >
                <p>Toute déclaration de sinistre dans la réalisation d'une livraison de marchandise doit être signalée dans les meilleurs délais par le Transporteur sur l'Application ou par email.</p>
                <p>Il devra également alerter son assureur, dans les délais requis par sa police, afin de permettre le dédommagement de l’Expéditeur, puis tenir informé directement l’Expéditeur (ou si besoin via la Société), de façon régulière de l'état d'avancement du dossier.</p>
                <h5 className={"second-title"}>6.2 Accident</h5>
                <p>Le Transporteur doit souscrire un contrat d'assurance individuelle protégeant en cas d'accident pendant l'exercice de son activité.</p>
                <h4>ARTICLE 7 - LIMITATION DE LA RESPONSABILITE DU TRANSPORTEUR</h4>
                <p>La responsabilité du Transporteur peut être engagée en cas de dommages immatériels et/ou matériels causés par la livraison, notamment de perte, vol, dommage matériel, avarie ou destruction causé(e) à la marchandise en cours de livraison de marchandise, sauf faute de l'Expéditeur, de la personne à contacter au lieu d’enlèvement ou du destinataire, cas de force majeure, vice ou défaut propre de la marchandise, emballage insuffisant ou inapproprié, qui constituent une liste non limitative des cas d'exonération.</p>
                <p>Si elle est établie, la responsabilité du Transporteur est engagée selon les termes prévues par l’assurance du Transporteur qui définira le plafond approprié.</p>
                <p>Le Transporteur n'est pas responsable de tout préjudice indirect né de la livraison de la marchandise ou né d'une défaillance dans la livraison de la marchandise.</p>
                <h4>ARTICLE 8 - PAIEMENT</h4>
                <p>Afin de remplir les reçus ou factures émises par la Société au nom et pour le compte du Transporteur
                    et encaisser le paiement, le Transporteur fournira toutes les informations nécessaires, notamment
                    en vue de son identification, et confie un mandat de facturation et d'encaissement la Société à
                    dans les conditions déterminées dans les CGU.</p>
            </div>
        </div>
    </div>
    <div>
        <div >
            <div >
                <p>Le Transporteur est payé pour chaque livraison effectuée selon une grille tarifaire accessible sur demande en</p>
                <p>envoyant un message à l'adresse email suivante : service.client@pickdeli.fr. Elle est susceptible d'évolution.</p>
                <p>Il reçoit de la Société un versement, tous les 5 du mois correspondant aux factures de livraisons effectuées le mois précédent (du 1er au 30 du mois précédent), les annulations éventuelles étant prises en compte dans les conditions des présentes CGU, sous déduction des frais de services dus par le Transporteur à la Société et calculés au titre de chaque livraison.</p>
                <p>Il appartient donc au Transporteur de s'assurer que ses informations de facturation sont à jour, complètes et correctes.</p>
                <p>Le Transporteur s'engage à déclarer les revenus ainsi générés auprès de l'administration fiscale.</p>
                <p>Le Transporteur accepte que toute livraison de marchandise n'ayant pas été réalisée pour une quelconque raison provoquera le gel de toute somme due au Transporteur à la date du défaut de réalisation et tant que le dédommagement le cas échéant dû par le Transporteur n'a pas été versé :</p>
                <ul>
                    <li>
                        <p> dans l'hypothèse où l'assurance dédommagerait l’Expéditeur pour la livraison de la marchandise litigieuse, alors le Transporteur se verra reverser les sommes ainsi gelées selon les conditions de la police d'assurance ;</p>
                    </li>
                    <li>
                        <p> dans l'hypothèse où l'assurance ne dédommagerait pas l’Expéditeur, alors un montant égal à la valeur d'origine de la marchandise augmenté du montant de sa réparation ou de sa reconstitution, sur présentation de justificatifs de l’Expéditeur, ainsi que la commission due à la Société, seront versés à l’Expéditeur ou à la Société à partir des sommes gelées (le solde éventuel étant ensuite versé au Transporteur), ou si son montant n'est pas suffisant, devront être payés directement par le Transporteur.</p>
                        <p>Le Transporteur accepte que toute livraison de marchandise n'ayant pas été réalisée de l’enlèvement à la livraison pour une quelconque raison entrainera automatiquement le non-paiement de la somme due au Transporteur à la date du défaut de réalisation, dans cette hypothèse le Transporteur s’engage à remettre la marchandise à un autre transporteur désigné par la société dans les modalités qui seront décrite par la société au moment des faits.</p>
                    </li>
                    </ul>
                        <h4>ARTICLE 9 - MANDAT DE FACTURATION </h4>
                        <h5 className={"second-title"}>9.1 Mandat de facturation</h5>

                <p>La Société met en relation des Expéditeurs avec des Transporteurs en donnant à ces derniers accès à l'Application de la Société.</p>
                <p>C'est dans ce contexte que le Transporteur, mandant, souhaite confier, dans le respect de la réglementation applicable, à la Société, mandataire, l'établissement et l'émission de ses factures en son nom et pour son compte.</p>
                
                
                    <h5 className={"second-title"}>9.2 Objet du mandat de facturation</h5>
                
                    <p>Le Transporteur donne expressément mandat à la Société, qui accepte d'établir en son nom et pour son compte des factures originales (initiales et/ou rectificatives) afférentes aux livraisons de marchandise réalisées auprès des Expéditeurs, et ce conformément à la réglementation fiscale et économique en vigueur, et en particulier les dispositions des articles 289, I-2 et 242 nonies, I du Code général des impôts.</p>
                    <p>Il est précisé que la Société sera en charge de l'acheminement desdites factures aux Expéditeurs, les factures étant mise à la disposition sur l’Application et téléchargeables par les Utilisateurs.</p>
                
                <h5 className={"second-title"}>9.3 Durée de la convention</h5>
                
                        <p>Le présent mandat de facturation, qui prend effet à compter de l'Acceptation des CGU et des RP, est conclu pour une durée indéterminée.
                        </p><p>Il pourra y être mis à tout moment par le mandant et le mandataire et sans motif particulier, par lettre recommandée avec demande d'avis de réception.
                        </p><p>La révocation prendra effet à réception de cette lettre recommandée à la date indiquée sur celle-ci.</p>
                        <h5 className={"second-title"}>9.4 Obligationsdumandataire</h5>
                        <p>Le mandataire s'oblige à établir les factures de livraisons de marchandise conformément aux informations données
                par le mandant, au nom et pour le compte du mandant.</p>
                <p>Le mandataire s'oblige à ce que les factures originales, émises par ses soins au nom et pour le compte du mandant, présentent les mentions obligatoires requises par la réglementation applicable.</p>
                <p>Le mandataire s'engage également à ce que les factures originales émises par ses soins portent la mention « Facture émise au nom et pour le compte de [nom du Transporteur] par [la Société] ».</p>
                <p>La Société s'engage enfin à adresser au Transporteur un relevé édité annuellement par la Société qui indiquera notamment :</p>
                
                    
                        <ul><li>le nombre de transactions réalisées par le Transporteur,</li>
                        <li>le montant total du Tarif des livraisons de marchandise versées au Transporteur.</li>
                        </ul>
                    
                        <h5 className={"second-title"}>9.5 Obligations du mandant</h5>
                            
                            <p>Le Transporteur conserve l'entière responsabilité de ses obligations légales et fiscales en matière de facturation au titre des factures originales émises en son nom et pour son compte par le mandataire en application des présentes, notamment en ce qui concerne ses obligations en matière de TVA.
                            </p><p>Dans le cadre de l'exécution de ses services d'intermédiation, la responsabilité de la Société ne peut pas être engagée pour des manquements aux obligations fiscales du Transporteur, étant précisé que la Société ne peut pas être tenue au paiement solidaire de la TVA et des éventuelles pénalités ou amendes dues par le Transporteur.
                            </p><p>Plus particulièrement, le Transporteur conserve la responsabilité pleine et entière, le cas échéant, des mentions afférentes à son statut de bénéficiaire de la franchise en base de TVA.
                            </p><p>Dans ce cadre, il prend expressément l'engagement de :</p>
                    
                    
                        <ul>
                            <li>déclarer auprès de l'administration fiscale, au moment de son éligibilité, la TVA collectée, le cas échéant, au titre des livraisons de marchandise réalisées auprès des Expéditeurs,</li>
                        
                        
                            <li>verser au Trésor publique la TVA mentionnée, le cas échéant, sur les factures émises par le mandataire au titre des présentes,</li>
                        
                        
                            <li>conserver le double de la facture telle qu'elle aura été établie par le mandataire,</li>
                        
                        
                            <li>réclamer immédiatement le double de la facture si ce dernier n'a pas été mis à sa disposition par le mandataire,</li>
                        
                        
                            <li>signaler par écrit sans délai au mandataire toute modification à apporter :</li>
                        
                        
                            <li>au régime de TVA qui lui est applicable (par exemple, en cas de dépassement du seuil d'éligibilité à la franchise en base, communication sans délai de l'information et du numéro d'identification à la TVA afin que la TVA applicable soit correctement mentionnée sur les factures...) ;</li>
                        
                        
                            <li>sur les mentions obligatoires apposées sur les factures (par exemple changement d'adresse, de forme sociale...).</li>
                        
                        
                            <li>d'informer l'administration fiscale que mandat a été donné à la société pour l'émission des factures et de ses obligations en matière de facturation, notamment s'agissant du contenu de la facture, et de ses conséquences au regard de la TVA.</li>
                        </ul>
                        <h5 className={"second-title"}>9.6 Contestation des factures émises pour le compte du mandant</h5>
                                
                            <p>Les factures émises dans le cadre des présentes n'auront pas besoin d'être authentifiées de manière formelle par le mandant.
                            </p><p>Le Transporteur dispose d'un délai de 7 jours, à compte de sa date d'émission, pour contester le contenu de la facture émise en son nom et pour son compte par la Société. A défaut de contestation dans ce délai, le Transporteur sera présumé avoir accepté la facture émise en son nom et pour son compte.
                            </p><p>Dans l'hypothèse d'une contestation, le mandataire émettra une facture rectificative.</p>
                                
                                <h5 className={"second-title"}>9.7 Mandat d'encaissement</h5>
                                
                                <p>C'est également dans ce contexte que le Transporteur, mandant, donne mandat à la Société, mandataire, qui l'accepte, d'encaisser en son nom et pour son compte, par l'intermédiaire de son prestataire bancaire, les sommes dues au titre des livraisons de marchandise réalisées par le Transporteur pour l’Expéditeur.
                    </p>
            </div>
        </div>
    </div>
    <div  >
        <div >
            <div >
                <p>Le Tarif dû par l’Expéditeur au Transporteur au titre de la livraison de marchandise sera versé sur un compte bancaire distinct déduction faite de sa commission, le Transporteur donnant à la Société le droit de prélever directement sur ce compte toute somme qu'il lui devrait.</p>
                <p>La Société reversera les sommes dues au Transporteur sur le compte bancaire qu'il aura renseigné, tous les 5 du mois.</p>
                <p>Le Transporteur autorise expressément la Société à pouvoir geler, le cas échéant, le versement des sommes dues au Transporteur.</p>
                <p>Le Transporteur autorise expressément la Société à pouvoir prélever sur les sommes gelées et, le cas échéant, si nécessaire, sur les autres sommes dues par l’Expéditeur au Transporteur et transitant sur le compte bancaire distinct, les sommes dues au titre des présentes CGU.</p>
                <h4>10 DONNEES PERSONNELLES</h4>
                <p>Le Transporteur s'engage à respecter la vie privée des Expéditeurs et, le cas échéant, des tiers tel que la personne à contacter au lieu d’enlèvement et/ou le destinataire. Dans ce sens, le Transporteur s'engage à respecter les dispositions du Règlement européen n°2016/679 du 27 avril 2016 sur la protection des données personnelles (« RGPD »), de tout texte national de transposition du RGPD et de la version consolidée à jour de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dite Loi Informatique modifiée par la loi n°2018- 493 du 20 juin 2018, ainsi qu'à respecter scrupuleusement la Politique de confidentialité.</p>
            </div>
        </div>
            
    </div>

                
                        
                        </Col>
                    </Row>
                </Container>
        );
    }



export default Cgu;
