import TirageNumberReducer from "./TirageNumber";
import {combineReducers} from "redux";
import VoteReducer from "./VoteReducer";
import PlayersNumberReducer from "./PlayersNumber";

const allReducers = combineReducers({
    TirageNumber : TirageNumberReducer,
    VoteState : VoteReducer,

})

export default allReducers
