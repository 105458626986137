export const montant_participation = 3;
export const UserType = {
    CLIENT_ENTREPRISE: {
        CODE: "CLEN",
        LIBELLE: "Client entrepise"
    },
    CLIENT_PARTICULIER: {
        CODE: "CLPA",
        LIBELLE: "Client particulier"
    },
    ENTREPRISE_TRANSPORT: {
        CODE: "ENTR",
        LIBELLE: "Entreprise de transport"
    },
    TRANSPORTEUR_INDEPENDANT: {
        CODE: "TRIN",
        LIBELLE: "Transporteur indépendant"
    },
    TRANSPORTEUR_DEPENDANT: {
        CODE: "TRDE",
        LIBELLE: "Transporteur dépendant (Livreur d'entreprise)"
    }
}